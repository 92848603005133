import React, { useEffect, useState } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import useMasterList from '../../../hooks/useMasterList'
import BranchLocationForm from '../components/BranchLocationForm';
import branchLocationServices from '../branchLocationServices';

function BranchLocationPage({AppAlert}) {

    const [masters,getMasterLabel] = useMasterList(["branch"]);

    const [branLocationList,setBranchLoactionList] = useState(null);
    const getBranchLocationList = () =>{
        branchLocationServices.getList().then(res=>{
            setBranchLoactionList(res.data);
        })
    }

    useEffect(()=>{
        getBranchLocationList();
    },[])

    return (
        <AppLayout>
            <div className='container-fluid mt-3'>
                <div className='row'>
                    <div className='col-md-9'>
                        <h3>Branch Location / Attendance Location</h3>
                    </div>
                    <div className='col-md-3'>
                    <span className="float-end"><BranchLocationForm masters={masters} AppAlert={AppAlert} refreshList={getBranchLocationList} /></span>
                    </div>
                </div>
                <div className='row'>
                    {masters && branLocationList && branLocationList.map(branchLocation=>
                        <div className='col-md-4' key={branchLocation.id} >
                            <div className='card mb-2'>
                                <div className='card-header'>
                                    { getMasterLabel("branch", branchLocation.branch)}
                                    <span className='float-end'>
                                        <BranchLocationForm AppAlert={AppAlert} masters={masters} refreshList={getBranchLocationList} data={branchLocation} />
                                    </span>
                                </div>
                                <div className='card-body'>
                                    <table className='table'>
                                        <thead>
                                            
                                            <tr>
                                                <th>Latitude</th>
                                                <td>
                                                    {branchLocation.latitude}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Longitude</th>
                                                <td>
                                                    {branchLocation.longitude}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Radius</th>
                                                <td>
                                                    {branchLocation.radius}
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                    
                </div>
            </div>
        </AppLayout>
        
    )
}

export default withAlert( BranchLocationPage)