import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import useMasterList from '../../../hooks/useMasterList'
import AppLayout from '../../../layout/components/AppLayout';
import reportServices from '../reportServices';


function ClassStrengthReport() {
    

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [masters,getMasterLabel] = useMasterList(['class',"section","subject","salutation","department","designation"])

    const [classTeacherList,setClassTeacherList]    =   useState(null)
    const getClassStrengthReport = () =>{
        reportServices.getClassStrengthReport().then(res=>{
            setClassTeacherList(res.data);
        })
    }
    useEffect(()=>{
        getClassStrengthReport();
    },[])

    const doTotal = (column) =>{
        let total = 0;
        //total = total + studentReport.map(student=>student[column]);

        total = classTeacherList.reduce((total,num)=>{
            return total+num[column];
        },0)

        return  total;
    }

    return (
            <AppLayout>
                <div className="container-fluid">
                    {classTeacherList && 
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <h3>Class Strength Report </h3>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="btn btn-warning float-end" onClick={handlePrint} >Print</button>
                            </div>
                            <div className="col-md-12 table-responsive" ref={componentRef}>
                                <table className="table table-bordered table-sm table-striped" style={{fontSize:12}} >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Class</th>
                                            <th>Class Teacher Name</th>
                                            <th>Teacher Id</th>
                                            <th>Total Student</th>
                                            <th>Boys</th>
                                            <th>Girls</th>
                                            <th>134A</th>
                                            <th>New</th>
                                            <th>Old</th>
                                            <th>Inactive</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {classTeacherList.map((classTeacher,i)=>
                                            <tr key={classTeacher.id}>
                                                <td>{i+1}</td>
                                                <td><strong>{getMasterLabel("class",classTeacher.class)}-{getMasterLabel("section",classTeacher.section)}</strong></td>
                                                <td><strong>{classTeacher.firstName} {classTeacher.lastName}</strong></td>
                                                <td>{classTeacher.teacherUID}</td>
                                                <td>{classTeacher.totalCount}</td>
                                                <td>{classTeacher.maleCount}</td>
                                                <td>{classTeacher.femaleCount}</td>
                                                <td>{classTeacher.total134ACount}</td>
                                                <td>{classTeacher.newAdmission}</td>
                                                <td>{classTeacher.totalCount - classTeacher.newAdmission}</td>
                                                <td>{classTeacher.inActiveCount}</td>
                                            </tr>
                                        )}


                                        <tr>
                                                <td className="table-dark border-light"  colSpan={4}>Total</td>
                                                <td className="table-dark border-light" >{doTotal("totalCount")}</td>
                                                <td className="table-dark border-light" >{doTotal("maleCount")}</td>
                                                <td className="table-dark border-light" >{doTotal("femaleCount")}</td>
                                                <td className="table-dark border-light" >{doTotal("total134ACount")}</td>
                                                <td className="table-dark border-light" >{doTotal("newAdmission")}</td>
                                                <td className="table-dark border-light" >{doTotal("newAdmission")}</td>
                                                <td className="table-dark border-light" >{doTotal("inActiveCount")}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </AppLayout>


    )
}

export default ClassStrengthReport