import React, { useEffect, useState } from 'react'

import AppLayout from '../../../layout/components/AppLayout'
import useMasterList from '../../../hooks/useMasterList'
import BranchClassFilter from '../components/BranchClassFilter';
import reportServices from '../reportServices';
import { ScreenSearchDesktop } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import withAlert from '../../../hoc/withAlert';
import StudentMessageComponent from '../../Communication/components/StudentMessageComponent';

function TermWiseFeeCollectionList({AppAlert}) {


    const [masters] = useMasterList(['branch','class'],"all");
    const [searchingInProgress, setSearchingStatus] = useState(false);

    const [studentReport,setStudentReport] = useState(null);
    const [paymentMonths,setPaymentMonths] = useState(null);
    const getList = (values) =>{
        console.log("values",values);
        setSearchingStatus(true);
        reportServices.termWiseCollectionReport(values).then(res=>{
            setStudentReport(res.report);
            setPaymentMonths(res.paymentMonths);
        }).finally(()=>
            setSearchingStatus(false)
        )
    }

    const doTotal = (column) =>{
        let total = 0;
        //total = total + studentReport.map(student=>student[column]);

        total = studentReport.reduce((total,num)=>{
            return total+num[column];
        },0)

        return  total;
    }

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-8">
                        <BranchClassFilter masters={masters} searchingInProgress={searchingInProgress} filterData={getList} />
                    </div>
                    <div className="col-md-4">
                        <Link to={"/report/term-wise-collection-grid"} className="btn btn-warning float-end mt-3" >Click Here to Export</Link>
                    </div>
                </div>
                {paymentMonths && studentReport &&
                    <div className="row">
                        <div className="col-md-12 table-responsive">
                            <table className="table table-striped table-sm table-bordered border-dark" style={{fontSize:10}}>
                                <thead>
                                    
                                    <tr>
                                        <th rowSpan="2" className="border-dark" >#</th>
                                        <th rowSpan="2" className="border-dark table-info" >Student Name</th>
                                        <th rowSpan="2" className="border-dark table-info" >Father Name</th>
                                        <th rowSpan="2" className="border-dark table-info">Admission No</th>
                                        <th rowSpan="2" className="border-dark table-info">Class</th>
                                        
                                        {paymentMonths.map(month=>
                                            <>
                                                <th colSpan="5" className={month.month % 2 == 1 ? "table-warning text-center border-dark":"table-primary text-center border-dark" }>{month.monthTitle}</th>
                                            </>
                                        )}
                                        <th colSpan="5" className="text-center bg-dark text-white">Total</th>
                                    </tr>
                                    <tr>
                                        {paymentMonths.map(month=>
                                            <>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Actual Amount"}</th>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Concession"}</th>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Net Receivable"}</th>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Collected"}</th>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Dues"}</th>
                                            </>
                                        )}
                                        <th className="table-success border-dark">{"Total Actual Amount"}</th>
                                        <th className="table-success border-dark">{"Total Concession"}</th>
                                        <th className="table-success border-dark">{"Total Net Receivable"}</th>
                                        <th className="table-success border-dark">{"Total Collected"}</th>
                                        <th className="table-success border-dark">{"Total Dues"}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {studentReport.map((student,i)=>
                                        <tr key={student.id}>
                                            <td>{i+1}</td>
                                            <td className="table-info border-dark"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "100px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                <StudentMessageComponent studentId={student.id} AppAlert={AppAlert} />{student.studentName}
                                            </td>
                                            <td className="table-info border-dark"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "80px",
                                                    overflow:"hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                <strong>{student.fatherName}</strong> <br/> {student.fatherPhone}
                                            </td>
                                            <td className="table-info border-dark">
                                                <Link className="text-dark border-dark" target="_blank" to={"/student/details/"+student.id}>{student.admissionNo}/{ moment(student.admissionYear).format("YY")}</Link>
                                            </td>
                                            <td className="table-info border-dark">
                                                {student.classTitle}-{student.sectionTitle}
                                            </td>
                                            {paymentMonths.map(month=>
                                            <>
                                                <td className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }> <span className={student["Total"+"_PaidAmount"] != student["Total"+"_PaidAmount_TermWise"] ?"text-danger" :""}> {student[month.monthTitle+"_Orignal"]}</span></td>
                                                <td className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }> <span className={student["Total"+"_PaidAmount"] != student["Total"+"_PaidAmount_TermWise"] ?"text-danger" :""}> {student[month.monthTitle+"_Concession"]}</span></td>
                                                <td className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }> <span className={student["Total"+"_PaidAmount"] != student["Total"+"_PaidAmount_TermWise"] ?"text-danger" :""}> {student[month.monthTitle+"_Payable"]}</span></td>
                                                <td className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }> <span className={student["Total"+"_PaidAmount"] != student["Total"+"_PaidAmount_TermWise"] ?"text-danger" :""}> {student[month.monthTitle+"_PaidAmount"]}</span></td>
                                                <td className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }> <span className={student["Total"+"_PaidAmount"] != student["Total"+"_PaidAmount_TermWise"] ?"text-danger" :""}> {student[month.monthTitle+"_Dues"]}</span></td>
                                            </>
                                            )}
                                            <td className="table-success border-dark">{student["Total"+"_Orignal"]}</td>
                                            <td className="table-success border-dark">{student["Total"+"_Concession"]}</td>
                                            <td className="table-success border-dark"><Link className="text-dark" target="_blank" to={"/student/fees-structure/"+student.id}>{student["Total"+"_Payable"]}</Link></td>
                                            <td className="table-success border-dark"><Link className="text-dark" target="_blank" to={"/invoice/receipt/student/"+student.id}>{student["Total"+"_PaidAmount"]}</Link></td>
                                            <td className="table-success border-dark">{student["Total"+"_Dues"]}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td className="table-dark border-dark" colSpan="5">Grand Total</td>

                                        {paymentMonths.map(month=>
                                            <>
                                                <td className="table-dark border-light">{doTotal(month.monthTitle+"_Orignal")}</td>
                                                <td className="table-dark border-light">{doTotal(month.monthTitle+"_Concession")}</td>
                                                <td className="table-dark border-light">{doTotal(month.monthTitle+"_Payable")}</td>
                                                <td className="table-dark border-light">{doTotal(month.monthTitle+"_PaidAmount")}</td>
                                                <td className="table-dark border-light">{doTotal(month.monthTitle+"_Dues")}</td>
                                            </>
                                        )}

                                        <td>{doTotal("Total"+"_Orignal")}</td>
                                        <td>{doTotal("Total"+"_Concession")}</td>
                                        <td>{doTotal("Total"+"_Payable")}</td>
                                        <td>{doTotal("Total"+"_PaidAmount")}</td>
                                        <td>{doTotal("Total"+"_Dues")}</td>

                                    </tr>
                                </tbody>

                                <tfoot>
                                    
                                    
                                    <tr>
                                        <th colSpan="5" rowSpan="2"></th>
                                        {paymentMonths.map(month=>
                                            <>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Actual Amount"}</th>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Concession"}</th>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Net Receivable"}</th>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Collected"}</th>
                                                <th className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>{"Dues"}</th>
                                            </>
                                        )}
                                        <th className="table-success border-dark">{"Total Actual Amount"}</th>
                                        <th className="table-success border-dark">{"Total Concession"}</th>
                                        <th className="table-success border-dark">{"Total Net Receivable"}</th>
                                        <th className="table-success border-dark">{"Total Collected"}</th>
                                        <th className="table-success border-dark">{"Total Dues"}</th>

                                    </tr>
                                    <tr>
                                        {paymentMonths.map(month=>
                                            <>
                                                <th colSpan="5" className={month.month % 2 == 1 ? "table-warning text-center border-dark":"table-primary text-center border-dark" }>{month.monthTitle}</th>
                                            </>
                                        )}
                                        <th colSpan="5" className="text-center bg-dark text-white">Total</th>
                                    </tr>
                                </tfoot>
                            </table>
                            
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default withAlert(TermWiseFeeCollectionList);