import { Close, PercentRounded } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import React, { useState,useEffect } from 'react'
import useMasterList from '../../../hooks/useMasterList';
import concessionServices from '../../FeeStructure/concessionServices';

function StudentAssignConcession({studentId, refreshList}) {

    const [masters,getMasterLabel] = useMasterList(['fees-head']);
    
    const [concessionList,setConcessionList] = useState(null);
    const getConcessionList = () =>{
        concessionServices.getList().then(res=>{
            setConcessionList(res.data);
        }).catch(error=>{
            
        })
    }
    useEffect(()=>{
        getConcessionList()
    },[]);
    const [dialogStatus,setDialogStatus] = useState(false);

    const assignConcession = (concession) =>{
        let payload = {
            concession:concession.concession,
            concessionId:concession.id,
            feesHead:concession.feesHead,
            studentId:studentId,
        }
        concessionServices.assignConcession(payload).then(res=>{
            console.log(res);
            refreshList();
            setDialogStatus(false);
        }).catch(error=>{
            console.log(error);
        })
    }

    const unassignConcession = () =>{
        let payload = {
            studentId:studentId,
        }
        concessionServices.unassignConcession(payload).then(res=>{
            console.log(res);
            refreshList();
            setDialogStatus(false);
        }).catch(error=>{
            console.log(error);
        })
    }
    

    return (
        <>
            <button type="button" className="list-group-item list-group-item-action bg-danger text-white" onClick={()=>setDialogStatus(true)}> <PercentRounded/> Manage Concession</button>

            
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullWidth={true}
                maxWidth="xs"
                aria-describedby="alert-dialog-slide-description"
            >
                
                {masters && concessionList &&
                    <div className="card">
                        <div className="card-header bg-primary text-white">Choose Concession </div>
                        <div className="card-body">
                            <div className="list-group">
                                {concessionList.map(concession=>
                                    <button className="list-group-item list-group-item-action d-flex justify-content-between"
                                        onClick={()=>assignConcession(concession)}
                                        key={concession.id}
                                    >
                                        <span>{concession.title}</span>
                                        <span>Apply on {getMasterLabel("fees-head",concession.feesHead)}</span>
                                        <span>{concession.concession}%</span>
                                    </button>                                    
                                )}
                                <button className="list-group-item bg-danger text-white list-group-item-action d-flex justify-content-center"
                                        onClick={unassignConcession}
                                    >
                                    <Close className=""/>
                                    Remove All Concession
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </Dialog>
        </>
    )
}

export default StudentAssignConcession