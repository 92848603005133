import { Forward } from '@mui/icons-material';
import moment from 'moment';
import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router'
import withAlert from '../../../hoc/withAlert';
import useMasterList from '../../../hooks/useMasterList';
import useSessionList from '../../../hooks/useSessionList';
import AppLayout from '../../../layout/components/AppLayout';
import feesSettingServices from '../../settings/feesSettingServices';
import studentFeesStructureService from '../studentFeesStructureServices';
import studentServices from '../studentServices';

function StudentAssignFeesStructurePage({AppAlert}) {
    let { id } = useParams();
    const navigate = useNavigate();

    const [sessionYearList] =  useSessionList();
    const [masters, getMasterLabel] = useMasterList(["branch","class","section", "month","fees-head","fees-term","fees-class-group","gender"]);
    

    /* Get Student Details Start */
    const [studentDetails,setStudentDetails] = useState(null);
    const getStudentDetails =  () =>{
        if(masters != null){
            studentServices.getById(id).then(res=>{
                setStudentDetails(res.data);
            })
        }
    }
    useEffect(()=>{
        getStudentDetails();
    },[masters])
    /* Get Student Details End */

    /* Setup filter When Student Ready start */
    const [filters,setFilters] = useState(null);
    useEffect(()=>{
        if(studentDetails){
            setFilters({
                sessionYear:studentDetails.admissionYear,
                branch:studentDetails.branch,
            })
        }
    },[studentDetails]);
    /* Setup filter When Student Ready End */

    /* Get Fee Structure List When filter ready  start*/
    const [feeStructureList,setFeeStructureList] = useState(null);
    const [searchInProgress,setSearchStatus] = useState(false);
    const getFeeStructureList = () =>{
        if(filters){
            setSearchStatus(true);
            feesSettingServices.filter(filters).then(res =>{
                setFeeStructureList(res.data);
            }).catch(error=>{
            }).finally(()=>{
                setSearchStatus(false);
            })
        }
    }
    useEffect(()=>{
        getFeeStructureList();
    },[filters])
    /* Get Fee Structure List When filter ready  end */



    /*
    const [feeStructureTypeList,setFeeStructureTypeList] = useState(null);
    const prepairFeesTypeObject = () =>{
        if(masters && feeStructureList){
            let feesTypeList = [...masters['fees-type']];
            feesTypeList.forEach((feesType,i)=>{
                feesTypeList[i]['feeStructureList'] = feeStructureList.filter(feeStructure => feeStructure.feesType == feesType.value)
            })
            setFeeStructureTypeList(feesTypeList);
        }
    }
    */

    const [feeStructureClassGroupList,setFeeStructureClassGroupList] = useState(null);
    const prepairClassGroupObject = () =>{
        if(masters && feeStructureList){
            let feesClassGroupList = [...masters['fees-class-group']];
            feesClassGroupList.forEach((feesClassGroup,i)=>{
                feesClassGroupList[i]['feeStructureList'] = feeStructureList.filter(feeStructure => feeStructure.feesClassGroup == feesClassGroup.value)
            })
            setFeeStructureClassGroupList(feesClassGroupList);
        }
    }


    useEffect(()=>{
        //prepairFeesTypeObject();
        prepairClassGroupObject();
    },[feeStructureList])

    const assignFees = (feesClassGroup) =>{
        filters.feesClassGroup = feesClassGroup;
        console.log(filters);

        let payload = {
            studentId : studentDetails.id,
            studentClass : studentDetails.class,
            filters : filters
        }
        
        studentFeesStructureService.assignFeesStructure(payload).then(res=>{
            AppAlert("Fees Structure Assigned");
        }).catch(error=>{
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setTimeout(()=>{
                //navigate("/student/fees-structure/"+studentDetails.id);
                navigate("/student/assign/transport/"+studentDetails.id)
            },2000)
        })
    }

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-3 text-danger">
                    <div className="col-md-12">
                        <h3>Choose Fee Structure</h3>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-md-12">
                        { studentDetails && 
                            <div className="border shadow p-2"> 
                                <div className="row">
                                    <div className="col-md-4">
                                        <span className="">
                                            <strong>{studentDetails.firstName} {studentDetails.lastName}</strong>
                                        </span>
                                        <span className="float-end">
                                            <strong>Admission No : </strong><span>{studentDetails.admissionNo} / {moment(studentDetails.admissionYear).format("YY")}</span>
                                        </span>
                                    </div>
                                    <div className="col-md-4">
                                        <strong>Class : </strong> 
                                        <span>{getMasterLabel("class",studentDetails.class)}</span>
                                        <span>-{getMasterLabel("section",studentDetails.section)}</span>
                                        <span>({getMasterLabel("branch",studentDetails.branch)})</span>
                                    </div>
                                    <div className="col-md-4">
                                        <strong>Admission Year : </strong>
                                        <span>{studentDetails.admissionYear}</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* 
                    <div className="col-md-8">
                        <div className="border shadow p-2">
                            <FeeStructureFilterComponent masters={masters} setFiltersValues={setFilters} sessionYearList={sessionYearList} />
                        </div>
                    </div>
                    */}
                </div>
                <div className="row">
                    {feeStructureClassGroupList && feeStructureClassGroupList.map((feeStructureClassGroup,i)=>
                        <>
                        {feeStructureClassGroup.feeStructureList.length > 0 &&
                            <div className="col-md-4" >
                                <div className="card shadow">
                                    <div className="card-header bg-dark text-white">{feeStructureClassGroup.label} (Session : {studentDetails.admissionYear}) </div>
                                    <div className="card-body table-responsive p-0">
                                            {feeStructureClassGroup.feeStructureList && masters && 
                                                <table className="table table-striped mb-0">                
                                                    <tbody>
                                                    {
                                                        feeStructureClassGroup.feeStructureList.map(feesStructure=>
                                                            <tr key={feesStructure.id}>
                                                                <td>{getMasterLabel("fees-head",feesStructure.feesHead)}</td>
                                                                <td>{getMasterLabel("fees-term",feesStructure.feesTerm)}</td>
                                                                <td>{getMasterLabel("month",feesStructure.month)}</td>
                                                                <td>
                                                                    <span className="text-primary">
                                                                    {feesStructure.amount}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            }
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <button type="button" className="btn btn-primary" onClick={()=>assignFees(feeStructureClassGroup.value)} >Continue with <strong>( {feeStructureClassGroup.label} )</strong> <Forward/> </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        }
                        </>
                    )}
                    
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(StudentAssignFeesStructurePage)