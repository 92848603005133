import React, { useEffect, useState } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';


import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { CircularProgress } from '@mui/material';
import noticeServices from '../noticeServices';
import NoticeFormComponent from '../components/NoticeFormComponent';

import AwsDocumentInline from '../../../components/InlineEdit/AwsDocumentInline'

import useMasterList from '../../../hooks/useMasterList';


function NoticeListPage({AppAlert}) {
    
    
    const [searchingInProgress, setSearchingStatus] = useState(false);
    const [masters,getMasterLabel] = useMasterList(["branch","class","section"],"all")

    
    /* Search Form Work  */
    const [noticeList,setNoticeList]    = useState(null);
    const initialValues = {
        branch:"",
        class:"",
        section:"",
    }

    const [filterValues,setFilterValues] = useState(null);

    const onSubmit = (values, onSubmitProps) => {
        setFilterValues(values)
    }

    const getNotificationList = () =>{
        setSearchingStatus(true)
        noticeServices.filter(filterValues).then(res =>{
            setNoticeList(res.data);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSearchingStatus(false);
        })
    }

    useEffect(()=>{
        if(filterValues != null){
            getNotificationList();
        }
    },[filterValues])

    

    const validationRule = Yup.object({
        //branch: Yup.string().required("Required"),
        //class: Yup.string().required("Required"),
        //section: Yup.string().required("Required"),
        //subject: Yup.string().required("Required"),
    });

    /* Search Form Work  */



    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-md-6">
                        <h3>Notice List </h3>
                    </div>
                    <div className="col-md-6">
                    <span className="float-end">{masters &&  <NoticeFormComponent masters={masters} AppAlert={AppAlert} refreshList={onSubmit} /> }</span>
                    </div>
                    <div className="col-md-12">
                    <Formik
                            initialValues={initialValues}
                            validationSchema={validationRule}
                            onSubmit={onSubmit}
                            enableReinitialize validateOnMount
                        >
                            {
                                (formik) => {
                                    //console.log(formik.values);
                                    return (
                                        <Form>
                                            <div className="row p-2">
                                                {masters && 
                                                    <>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Branch" name="branch"  
                                                                options={masters['branch']} multiple={false} 
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Class" name="class"  
                                                                options={masters['class']} multiple={false} 
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Section" name="section"  
                                                                options={masters['section']} multiple={false} 
                                                            />
                                                        </div>

                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <span className="float-end">
                                                                {searchingInProgress ? 
                                                                    <CircularProgress/> 
                                                                    :
                                                                    <button className="btn btn-success" type="submit">Search</button>
                                                                }
                                                            </span>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                                
                                            
                                        </Form>
                                    )
                                }
                            }
                        </Formik>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {noticeList && noticeList.length > 0 ? 
                            noticeList.map(noticeDetails=>
                            <div className="row mt-2" key={noticeDetails.id}>
                                <div className="co-md-12">
                                    <div className="card">
                                        <div className="card-header bg-primary ">
                                            <strong className="text-white">{noticeDetails.title}</strong>
                                            <span className="float-end">
                                                {masters &&  <NoticeFormComponent masters={masters} AppAlert={AppAlert} refreshList={onSubmit} data={noticeDetails} /> }
                                            </span>
                                            <span className="float-end">
                                                {noticeDetails.publish === 0 ? 
                                                    <span className="badge bg-warning text-dark">Drafted</span>
                                                    :
                                                    <span className="badge bg-success text-white">Published</span>
                                                    }
                                            </span>
                                        </div>
                                        <div className="card-body mb-0 pb-0">
                                            <p>{noticeDetails.details}</p>
                                        </div>

                                        <div className="card-body mb-0 pb-0">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="me-2">Attachment:</h4>
                                                <AwsDocumentInline
                                                    label="attachment"
                                                    name="attachmentUrl"
                                                    value={noticeDetails.attachmentUrl}
                                                    editId={noticeDetails.id}
                                                    updateInline={noticeServices.updateInline}
                                                    AppAlert={AppAlert}
                                                    module="notice"
                                                    fileId={noticeDetails.title}
                                                    onSuccess={getNotificationList}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            Branch : <strong>{ getMasterLabel("branch",noticeDetails.branch)}</strong>|
                                            Class : <strong>{ getMasterLabel("class",noticeDetails.class)} - { getMasterLabel("section",noticeDetails.section)} </strong> | 
                                            Notice Date  : <strong>{ noticeDetails.noticeDate}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                            :
                            <div className="row">
                                <div className="co-md-12 ">
                                    {Array.isArray(noticeList) && <h3>No Record Found</h3> }
                                </div>
                            </div>
                            
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(NoticeListPage)