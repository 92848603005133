import React, { useEffect, useState } from 'react'

function StudentReportAdditionalSubject({masters,getMasterLabel,reportCardDetails,gradeList}) {
    
    const [additionalSubjectList,setAdditionalSubjectList] = useState([]);

    const prepareAdditionalSubjectList = () =>{
        setAdditionalSubjectList( JSON.parse(reportCardDetails.additionalSubjectList));
    }

    useEffect(()=>{
        prepareAdditionalSubjectList();
    },[reportCardDetails])
    
    return (
        <>
            <div className='row'>
                <div className='col-md-12 text-center'>
                <h5 className='fw-bold'><u>Additional Subjects</u></h5>
                </div>
                <div className='col-md-12 '>    
                    <table className='table table-bordered' style={{borderColor:"#000"}}>
                        <tbody>
                            {additionalSubjectList && additionalSubjectList.map(additionalSubject => 
                                <tr key={additionalSubject.subjectId}>
                                    <th className='align-middle text-info'>{getMasterLabel("subject",additionalSubject.subjectId)}</th>
                                    {additionalSubject.isGrade &&
                                        <th className='text-center align-middle'>
                                            
                                            {additionalSubject.grades.internal == ""  &&
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        
                                                        <div>{additionalSubject.grades.theory}</div>
                                                    
                                                        
                                                    </div>
                                                </div>
                                            
                                            }

                                            {additionalSubject.grades.internal != "" &&
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div>Theory</div>
                                                        <div>{additionalSubject.grades.theory}</div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div>Internal</div>
                                                        <div>{additionalSubject.grades.internal}</div>
                                                    </div>
                                                </div>
                                            }
                                            
                                            
                                        </th>
                                    }
                                    {!additionalSubject.isGrade &&
                                        <th className='text-center align-middle'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div>Theory</div>
                                                    <div>{additionalSubject.marks.obtainedTheory} / {additionalSubject.marks.maxTheory}</div>
                                                </div>
                                                <div className='col-6'>
                                                    <div>Internal</div>
                                                    <div>{additionalSubject.marks.obtainedInternal} / {additionalSubject.marks.maxInternal}</div>
                                                </div>
                                            </div>
                                            
                                        </th>
                                    }
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default StudentReportAdditionalSubject