import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ImageInline from '../../../components/InlineEdit/ImageInline';
import ProfileImageInlineAws from '../../../components/InlineEdit/ProfileImageInlineAws';
import ImageInlineAws from '../../../components/InlineEdit/ProfileImageInlineAws';
import withAlert from '../../../hoc/withAlert';
import useMasterList from '../../../hooks/useMasterList';
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';
import StudentAppPassword from '../components/StudentAppPassword';
import StudentInformation from '../components/StudentInformation';
import StudentNavigationComponent from '../components/StudentNavigationComponent';
import studentServices from '../studentServices';
import StudentAchievement from '../components/StudentAchievement';

function StudentDetailsPage({AppAlert}) {
    let { id } = useParams();

    const [masters,getMasterLabel] = useMasterList(["area", "category", "special-category", "medium", "branch","gender","class","section", "house","city", "state","blood-group","religion","caste","nationality","occupation","qualification"]);



    const [studentDetails,setStudentDetails]    = useState(null);
    const [siblings,setSiblings] = useState(null);
    const [loading, setLoadingStatus]           = useState(false);

    const getStudentDetails = () =>{
        setLoadingStatus(true);
        studentServices.getById(id).then(res=>{
            setStudentDetails(res.data);
            setSiblings(res.siblings);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getStudentDetails();
    },[])

    
    

    return (
        <AppLayout>
            <div className="container-fluid mt-3">
                {studentDetails && 
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <ProfileImageInlineAws 
                                    editId={studentDetails.id} 
                                    label={"Photo"}  
                                    name="photo" 
                                    value={studentDetails.photo}
                                    fileId={studentDetails.admissionNo}
                                    updateInline={studentServices.updateInline}  
                                    module="student"
                                />
                            </div>
                            <StudentNavigationComponent studentId={studentDetails.id} />
                            <StudentAppPassword studentId={studentDetails.id} studentPassword={studentDetails.password} admissionNo={studentDetails.admissionNo} />
                            <StudentAchievement studentDetails={studentDetails} AppAlert={AppAlert} />
                            {siblings &&
                            
                                <div className="card ">
                                    <div className="card-header">Siblings</div>
                                    <div className="card-body">
                                        <ul>
                                        {siblings.map(sibling=>
                                            <li key={sibling.id}> 
                                                <Link to={"/student/details/"+sibling.id} target="_blank" >
                                                {sibling.photo?
                                                    <img src={sibling.photo} style={{height:40,width:40, borderRadius:20, marginRight:10}}  /> 
                                                    :
                                                    <span className="bg-primary badge rounded-circle" style={{marginRight:10,fontSize:20}}>{sibling.firstName.charAt(0)}</span>
                                                }
                                                
                                                {sibling.firstName} {sibling.lastName} {getMasterLabel("class",sibling.class)}-{getMasterLabel("section",sibling.section)}
                                                </Link>
                                            </li>
                                        )}
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-md-8">
                            <StudentInformation studentDetails={studentDetails} masters={masters} getMasterLabel={getMasterLabel} AppAlert={AppAlert} refreshData={getStudentDetails} />
                        </div>
                    </div>
                }
                
            </div>
        </AppLayout>
    )
}

export default withAlert(StudentDetailsPage)