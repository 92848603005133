import React from 'react'
import { useParams } from 'react-router-dom';
import AppLayout from '../../../layout/components/AppLayout';
import withAlert from '../../../hoc/withAlert';
import AddStudentResultForm from '../components/AddStudentResultForm';
import useMasterList from '../../../hooks/useMasterList';

function StudentResultsPage({AppAlert}) {
    let { studentId }   = useParams();

    const [masters,getMasterLabel]  = useMasterList(['exam-type']);

    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-md-4'>
                        <div className="list-group">
                            <a href="#" className="list-group-item list-group-item-action">Cras justo odio</a>
                            <a href="#" className="list-group-item list-group-item-action">Dapibus ac facilisis in</a>
                            <a href="#" className="list-group-item list-group-item-action">Morbi leo risus</a>
                            <a href="#" className="list-group-item list-group-item-action">Porta ac consectetur ac</a>
                            <a href="#" className="list-group-item list-group-item-action">Vestibulum at eros</a>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='card p-2'>
                            <AddStudentResultForm studentId={studentId} masters={masters} />
                        </div>
                    </div>
                </div>
            </div>

        </AppLayout>        
    )
}

export default withAlert(StudentResultsPage)