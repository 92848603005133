import React, { useEffect, useState } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';


import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { CircularProgress } from '@mui/material';
import announcementServices from '../announcementServices';
import AnnouncementFormComponent from '../components/AnnouncementFormComponent';

import AwsDocumentInline from '../../../components/InlineEdit/AwsDocumentInline'


function AnnouncementListPage({AppAlert}) {
    
    /* Start Masters Setting  */
    const [masters,setMasters] = useState(null)
    const [searchingInProgress, setSearchingStatus] = useState(false);

    const getMasterList = () => {
        systemMasterServices.getWithAllOptions([
            "branch","class","section","subject"
        ]).then(res=>{
            setMasters(res);
        })
    }
    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }
    useEffect(()=>{
        getMasterList();
    },[])
    /* End Masters Setting  */


    /* Search Form Work  */
    const [announcementList,setAnnouncementList]    = useState(null);
    const initialValues = {
        branch:"",
        class:"",
        section:"",
    }



    const [filterValues,setFilterValues] = useState(null);
    const onSubmit = (values, onSubmitProps) => {
        setFilterValues(values);
    }


    

    const getAnnouncementList = () =>{
        setSearchingStatus(true)
        announcementServices.filter(filterValues).then(res =>{
            setAnnouncementList(res.data);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSearchingStatus(false);
        })
    }

    useEffect(()=>{
        if(filterValues != null){
            getAnnouncementList();
        }
    },[filterValues])

    const validationRule = Yup.object({
        //branch: Yup.string().required("Required"),
        //class: Yup.string().required("Required"),
        //section: Yup.string().required("Required"),
        //subject: Yup.string().required("Required"),
    });

    /* Search Form Work  */



    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-md-6">
                        <h3>Announcement List </h3>
                    </div>
                    <div className="col-md-6">
                    <span className="float-end">{masters &&  <AnnouncementFormComponent masters={masters} AppAlert={AppAlert} refreshList={onSubmit} /> }</span>
                    </div>
                    <div className="col-md-12">
                    <Formik
                            initialValues={initialValues}
                            validationSchema={validationRule}
                            onSubmit={onSubmit}
                            enableReinitialize validateOnMount
                        >
                            {
                                (formik) => {
                                    //console.log(formik.values);
                                    return (
                                        <Form>
                                            <div className="row p-2">
                                                {masters && 
                                                    <>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Branch" name="branch"  
                                                                options={masters['branch']} multiple={false} 
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Class" name="class"  
                                                                options={masters['class']} multiple={false} 
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Section" name="section"  
                                                                options={masters['section']} multiple={false} 
                                                            />
                                                        </div>

                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <span className="float-end">
                                                                {searchingInProgress ? 
                                                                    <CircularProgress/> 
                                                                    :
                                                                    <button className="btn btn-success" type="submit">Search</button>
                                                                }
                                                            </span>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </Form>
                                    )
                                }
                            }
                        </Formik>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {announcementList && announcementList.length > 0 ? 
                            announcementList.map(announcementDetails=>
                            <div className="row mt-2" key={announcementDetails.id}>
                                <div className="co-md-12">
                                    <div className="card">
                                        <div className="card-header bg-primary ">
                                            <strong className="text-white">{announcementDetails.title}</strong>
                                            <span className="float-end">
                                                {masters &&  <AnnouncementFormComponent masters={masters} AppAlert={AppAlert} refreshList={onSubmit} data={announcementDetails} /> }
                                            </span>
                                            <span className="float-end">
                                                {announcementDetails.publish === 0 ? 
                                                    <span className="badge bg-warning text-dark">Drafted</span>
                                                    :
                                                    <span className="badge bg-success text-white">Published</span>
                                                    }
                                            </span>
                                        </div>
                                        <div className="card-body mb-0 pb-0">
                                            <p>{announcementDetails.details}</p>
                                        </div>
                                        <div className="card-body mb-0 pb-0">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="me-2">Attachment:</h4>
                                                <AwsDocumentInline
                                                    label="attachment"
                                                    name="attachmentUrl"
                                                    value={announcementDetails.attachmentUrl}
                                                    editId={announcementDetails.id}
                                                    updateInline={announcementServices.updateInline}
                                                    AppAlert={AppAlert}
                                                    module="announcement"
                                                    fileId={announcementDetails.title}
                                                    onSuccess={getAnnouncementList}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            Branch : <strong>{ getMasterLabel("branch",announcementDetails.branch)}</strong>|
                                            Class : <strong>{ getMasterLabel("class",announcementDetails.class)} - { getMasterLabel("section",announcementDetails.section)} </strong> | 
                                            Announcement Date  : <strong>{ announcementDetails.announcementDate}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                            :
                            <div className="row">
                                <div className="co-md-12 ">
                                    {Array.isArray(announcementList) && <h3>No Record Found</h3> }
                                </div>
                            </div>
                            
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(AnnouncementListPage)