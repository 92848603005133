import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';
import receiptService from '../receiptServices';
import { useReactToPrint } from 'react-to-print';
import { BorderStyle, Edit, Print } from '@mui/icons-material';
import moment from 'moment';
import { Link } from '@mui/material';
import useMasterList from '../../../hooks/useMasterList';
import ReceiptDetailsComponent from '../components/ReceiptDetailsComponent';
import EditReceipt from '../components/EditReceipt';

function ReceiptPage({AppAlert}) {
    let { id } = useParams();

    const [masters,getMasterLabel] = useMasterList(["branch","gender","class","section", "blood-group", "fees-head","fees-term","month","payment-status", "payment-type"]);

    const [receiptDetails,setReceiptDetails] = useState(null);
    const [feesDetails,setFeesDetails] = useState(null);
    const [studentDetails,setStudentDetails] = useState(null);
    const [schoolDetails,setSchoolDetails] = useState(null);

    const getReceiptDetails = () => {
        receiptService.get(id).then(res=>{
            setReceiptDetails(res.receiptDetails);
            setFeesDetails(res.feesDetails);
            setStudentDetails(res.studentDetails);
            setSchoolDetails(res.schoolDetails);
        }).catch(error=>{
            AppAlert(error.msg);
        })
    }

    useEffect(()=>{
        getReceiptDetails();
    },[]);

    /* Print Work Start */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    /* Print Work End */

    const doTotal = () =>{
        let total = 0;
        feesDetails.forEach(fees => {
            total = total + parseFloat(fees.feesAmount)
        });
        return total;
    }

    return (
        <AppLayout>
            <div className="container-fluid mt-3">
                {receiptDetails && feesDetails && studentDetails && schoolDetails &&
                    <div className="row">
                        <div className="col-md-6">
                            <Link to ={"/student/admission"} className="btn btn-danger text-white me-2" >New Admission</Link>
                            <Link to={"/invoice/generate"} className="btn btn-success  text-white me-2" > Collect Fees</Link>
                        </div>
                        <div className="col-md-6">
                            <span className="float-end">
                                <button onClick={handlePrint} className="btn btn-primary" ><Print/> Print Receipt!</button>
                            </span>

                            <span className="float-end">
                                <EditReceipt receiptDetails={receiptDetails} masters={masters} onSuccess={getReceiptDetails} AppAlert={AppAlert} />
                            </span>

                        </div>
                        <div className="col-md-12">
                            
                            <div ref={componentRef} className="p-4">
                                <div className="row">
                                    <ReceiptDetailsComponent 
                                        receiptDetails={receiptDetails}
                                        feesDetails={feesDetails}
                                        studentDetails={studentDetails}
                                        schoolDetails={schoolDetails}
                                        getMasterLabel={getMasterLabel}
                                        doTotal={doTotal}
                                        title="Parent Copy"
                                    />

                                    <div style={{borderColor:"#000", borderWidth:2, borderStyle:"dashed", marginBottom:15 }}> </div>

                                    <ReceiptDetailsComponent 
                                        receiptDetails={receiptDetails}
                                        feesDetails={feesDetails}
                                        studentDetails={studentDetails}
                                        schoolDetails={schoolDetails}
                                        getMasterLabel={getMasterLabel}
                                        doTotal={doTotal}
                                        title="Office copy"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                
                }
            </div>
        </AppLayout>
    )
}

export default withAlert(ReceiptPage)