import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Edit, KeyboardArrowRight, Send } from '@mui/icons-material';
import systemMasterServices from '../../../master/systemMasterServices';
import StudentBatchClassSectionFilter from '../../Filters/components/StudentBatchClassSectionFilter';
import messageServices from '../messageServices';
import Chip from '@mui/material/Chip';



function ComposeMessage({AppAlert}) {



    /* Start Masters Setting  */
    const [masters,setMasters] = useState(null);
    

    const getMasterList = () => {
        systemMasterServices.get([
            "branch","class","section","subject"
        ]).then(res=>{
            setMasters(res);
        })
    }
    useEffect(()=>{
        getMasterList();
    },[])
    /* End Masters Setting  */

    const [studentList,setStudentList] =  useState(null);
    const [selectedStudentList,setSelectedStudentList] = useState([]);


    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const [step,setStep] = useState(0);
    

    /* Form Work  */
    const initialValues = {
        
        subject:"",
        message:"",
    }

    const [formValues, setFormValues] = useState(null);

    const onSubmit = (values, onSubmitProps) => {
        values['to'] = selectedStudentList.map(student=>student.id);
        

        setSavingStatus(true)
        messageServices.send(values).then(res =>{
            AppAlert(res.msg);
            handleClose();
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        subject: Yup.string().required("Required"),
    });

    /* Form Work  */
    const composeNew = ()=>{
        setDialogStatus(true)
    }
    const handleClose  = () =>{
        setDialogStatus(false);
    }


    const selectStudent = (student) =>{
        setSelectedStudentList(selectedStudentList=>[...selectedStudentList,student])
    }

    const checkIfSelected = (student) =>{
        for(let i=0;i<selectedStudentList.length;i++){
            if(selectedStudentList[i].id === student.id){
                return true;
            }
        }
        return false;
    }

    const handleDelete = (index) =>{
        let newList  = [...selectedStudentList];
        newList.splice(index,1);
        setSelectedStudentList(newList);
    }

    const [templateList,setTemplateList] = useState([]);
    const getTemplatList = () =>{
        if(step == 1 ){
            messageServices.getTemplateList().then(res=>{
                setTemplateList(res.data);
            })
        }
    }

    useEffect(()=>{
        getTemplatList();
    },[step])

    const selectTemplate = (template) =>{
        console.log("template",template);
        if(template !== null ){
            let autoFill = {
                subject: template.subject,
                message: template.message,
            }
            setFormValues(autoFill);
        } else {
            setFormValues(null);
        }
        setStep(2);
    }

    return (
        <>
            <Tooltip title="Compose Message">
                <button onClick={composeNew} className="btn btn-primary" >
                    <Edit sx={{ fontSize: 20 }} /> Compose Message
                </button>
            </Tooltip>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
                aria-describedby="alert-dialog-slide-description"
            >   
                {step === 0  && 
                    <>
                        <DialogTitle>{"Select Student"}</DialogTitle>
                        <DialogContent>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <StudentBatchClassSectionFilter setStudentList={setStudentList} masters={masters} />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogContent>
                            <div className="row">
                                <div className="col-md-6 border">
                                    {studentList && studentList.map(student=>{
                                        if(!checkIfSelected(student)){
                                            return(
                                                <div key={student.id} style={{"cursor":"pointer"}} className="mt-1 p-1 border rounded" onClick={()=>{selectStudent(student)}}>
                                                    Roll No:- <strong>{student.rollNo}</strong> | 
                                                    Name:- <strong>{student.firstName} {student.lastName}</strong>
                                                </div>
                                            )
                                        } else {
                                            return(
                                                <div key={student.id} className="mt-1 p-1 border bg-success text-white rounded">
                                                    Roll No:- <strong>{student.rollNo}</strong> | 
                                                    Name:- <strong>{student.firstName} {student.lastName}</strong>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="col-md-6 border">
                                    <div className="row">
                                        <div className="col-md-12 m-2">
                                            Selected Student
                                        </div>
                                        <div className="col-md-12">
                                            <div >
                                            {selectedStudentList && selectedStudentList.map((student,i)=>
                                                <Chip key={student.id} label={student.firstName+" "+student.lastName} variant="outlined" onDelete={()=>handleDelete(i)} />
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <button className="btn btn-primary" onClick={()=>setStep(1)} disabled={selectedStudentList.length < 1} >Next</button>
                        </DialogActions>
                    </>
                }


                {step === 1 && 
                    <div className='card'>
                        <div className='card-header bg-primary text-white'>Choose Template</div>
                        <div className='card-body'>
                                    
                                    <div className='card bg-primary text-white mb-1' onClick={() => selectTemplate(null)} >
                                        <div className='card-body'>
                                            <h3>Compose New</h3>
                                        </div>
                                    </div>
                            {   templateList && templateList.map(template=>
                                    <div className='card mb-1' key={template.id} onClick={() => selectTemplate(template)} >
                                        <div className='card-body'>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h4>{template.subject}</h4>
                                                <p>{template.message}</p>
                                            </div>
                                            <KeyboardArrowRight />
                                        </div>
                                            
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className='card-footer'>
                            <button className="btn btn-primary float-end" onClick={()=>setStep(0)} >Back</button>
                        </div>
                    </div>
                    
                    

                }


                {step === 2 &&
                
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"New Message"}</DialogTitle>
                                    <DialogContent>
                                    <div className="row p-2">
                                        <div className="col-md-12 border rounded p-2 m-2">
                                            <span className="float-left">To : </span>
                                            <span className="float-left">
                                                {selectedStudentList && selectedStudentList.map((student,i)=>
                                                    <Chip key={student.id} label={student.firstName+" "+student.lastName} variant="outlined" className="m-1" />
                                                )}
                                            </span>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                    <>
                                                        <div className="col-md-12 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Subject" name="subject" 
                                                            />
                                                        </div>
                                                        <div className="col-md-12 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text"
                                                                multiline rows={4}
                                                                label="Message" name="message" 
                                                            />
                                                        </div>
                                                    </>
                                                
                                            </div>
                                        
                                                <div className="row">
                                                { /*  JSON.stringify(formik.values) */}
                                                </div>
                                                {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                            </div>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-primary " onClick={()=>setStep(1)} >Back</button>
                                            <button className="btn btn-success" type="submit" > Send <Send/></button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
                }
            </Dialog>
        </>
    )
}

export default ComposeMessage