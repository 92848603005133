import moment from 'moment/moment'
import React from 'react'

function ReportCardFooterTypeTwo({reportCardDetails}) {
  return (
    <>
        <div className='row pt-5'>
            <div className='col-md-12'>
                <div className='d-flex justify-content-around fw-bold'>
                    <p>Class Teacher</p>
                    <p>Examination Incharge</p>
                    <p>Principal</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default ReportCardFooterTypeTwo