import React, { useState, useEffect } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import examGroupServices from '../examGroupServices';
import ConfirmDialog from '../../../components/ConfirmDialog';
import withAlert from '../../../hoc/withAlert';
import useMasterList from '../../../hooks/useMasterList';
import ExamGroupForm from '../components/ExamGroupForm';
import { CancelOutlined, CheckCircle } from '@mui/icons-material';

function ExamGroupPage({AppAlert}) {
    
	const [examGroupList,setExamGroupList]      = useState(null);
    const [loading,setLoadingStatus]        	= useState(false);
	const [masters,getMasterLabel]				= useMasterList(['exam-type','month','branch','class','section']);


	const getExamGroupList = () => {
        setLoadingStatus(true);
        examGroupServices.getList().then(res=>{
            setExamGroupList(res.data);
        }).catch(error=>{
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }


	const remove = (id) =>{
        examGroupServices.remove(id).then(res=>{
            AppAlert("Removed Successfully","error")
            getExamGroupList();
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    useEffect(()=>{
        getExamGroupList()
    },[])
	
	return (
      	<AppLayout>
        	<div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-12">
                        <span className="float-end">
							<ExamGroupForm AppAlert={AppAlert} refreshList={getExamGroupList} masters={masters} />
						</span>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-12">
                        {examGroupList && examGroupList.length > 0 &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Branch</th>
                                        <th>Class</th>
                                        <th>Exam Type</th>
										<th>Exam Number</th>
										<th>Exam Month</th>
										<th>Exam Max Marks</th>
                                        <th>Result Type</th>
                                        <th>Published</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {examGroupList.map(examGroup=>
                                        <tr>
                                            <td>{getMasterLabel("branch",examGroup.branch)} </td>
                                            <td><strong>{getMasterLabel("class",examGroup.class)} -{getMasterLabel("section",examGroup.section)}</strong></td>
                                            <td>{getMasterLabel("exam-type",examGroup.examType)}</td>
											<td>{examGroup.examNumber}</td>
											<td>{getMasterLabel("month",examGroup.examMonth)}</td>
											<td>{examGroup.maxMarks}</td>
                                            <td>
                                                {examGroup.resultType == 0 && "Marks" }
                                                {examGroup.resultType == 1 && "Grade" }
                                                {examGroup.resultType == 2 && "Both (Marks / Grade)" }
                                            </td>
                                            <td>{examGroup.resultStatus == 1 ? <CheckCircle color="success" /> : <CancelOutlined sx={{ color: "#F00" }}/>}</td>
                                            <td>
                                                <ExamGroupForm AppAlert={AppAlert} refreshList={getExamGroupList} data={examGroup} masters={masters} />
                                                <ConfirmDialog id={examGroup.id} confirm={remove} >Are you Sure You want to remove this examGroup</ConfirmDialog>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                        {!examGroupList || examGroupList.length == 0 &&
                            <h4 >No exam Group declared click on "Add New" button to declare new exam group</h4>
                        }
                    </div>
                </div>
            </div>
      	</AppLayout>
    )
}

export default withAlert(ExamGroupPage)