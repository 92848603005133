import api from "../../api/api"


const get = (id) => {
    return new Promise((resolve, reject)=>{
          api.get("transport/"+id).then( (res)=>{
            if(res.data){
                resolve(res.data);
            } else {
                reject({
                    message: "Somthing Went Wrong"
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getList = () => {
    return new Promise((resolve, reject)=>{
          api.get("transport").then( (res)=>{
            if(res.data){
                resolve(res.data);
            } else {
                reject({
                    message: "Somthing Went Wrong"
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getTransportDD = () => {
    return new Promise((resolve, reject)=>{
          api.get("transport/dd").then( (res)=>{
            if(res.data){
                resolve(res.data);
            } else {
                reject({
                    message: "Somthing Went Wrong"
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}



const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/transport",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const transportServices = {
    get,
    getList,
    getTransportDD,
    save,
 }
 
 export default transportServices;