import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router'
import withAlert from '../../../hoc/withAlert';
import useMasterList from '../../../hooks/useMasterList';
import AppLayout from '../../../layout/components/AppLayout'
import StudentFeeStructureComponent from '../components/StudentFeesStructureComponent';
import studentFeesStructureService from '../studentFeesStructureServices';
import studentServices from '../studentServices';

function StudentRemoveFeeStructurePage({AppAlert}) {

    let { id } = useParams();

    let [masters,getMasterLabel] = useMasterList(["branch","gender","class","section", "state","blood-group","religion","caste","nationality","occupation","qualification", "fees-head","fees-term","month","payment-status"])


    /* Student Details Start */
    const [studentDetails,setStudentDetails] = useState(null);
    const getStudentDetails =  () =>{
        studentServices.getById(id).then(res=>{
            setStudentDetails(res.data);
        })
    }

     /* Student Fees Structure Start */

     const [studentFeesStructure,setStudentFeesStructure] = useState(null);
     const [paidCount,setPaidCount]  = useState(null);
     const getStudentFeesStructure =  () =>{
         
            studentFeesStructureService.get(id).then(res=>{
                 setStudentFeesStructure(res.data);
                 setPaidCount(res.paidCount);
            })
         
     }
 
     useEffect(()=>{
         getStudentFeesStructure();
         getStudentDetails();
     },[id])
 
     /* Student Fees Structure Details End */

    const [admissionNo,setAdmissionNo] = useState("");
    const navigate = useNavigate();

    const confirm = (studentId) =>{
        studentFeesStructureService.removeByStudentId(studentId).then(res=>{
            AppAlert("Success");
            navigate("/student/fees-structure/"+studentId);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }
 

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    
                    <div className="col-md-6">
                        {paidCount !== null && paidCount == 0 &&
                            <>
                            
                            {studentDetails &&
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="text-danger">Are you Aure you want to remove fee structure</h5>
                                        </div>
                                        <div className="col-md-12">
                                            <h6> Student Name : <strong> {studentDetails.firstName} {studentDetails.lastName} </strong> </h6>
                                            <h6> Admission # : <strong> {studentDetails.admissionNo}/{studentDetails.admissionYear} </strong> </h6>
                                            <hr/>
                                            <h6> Enter Admission No in box below to remove fee structure :  </h6>
                                            <input type="text" onChange={(e)=>setAdmissionNo(e.target.value)} value={admissionNo} />
                                        </div>
                                        <div className="col-md-12">
                                            <button disabled={admissionNo !== studentDetails.admissionNo} onClick={()=>confirm(studentDetails.id)} className="btn btn-danger mt-2">Remove fee Structure</button>
                                        </div>
                                    </div>

                                    
                                    
                                </>
                            }

                            </>
                            
                        }
                        {paidCount !== null && paidCount > 0 &&
                            <h6>System already received payment againt this fees structure, Now you are not alowed to remove fee structure</h6>
                        }
                    </div>
                    <div className="col-md-6">
                        {studentFeesStructure && <StudentFeeStructureComponent studentFeesStructure={studentFeesStructure} getMasterLabel={getMasterLabel} refreshList={getStudentFeesStructure} /> }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(StudentRemoveFeeStructurePage)