import React from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout';
import MessageNavigation from '../components/MessageNavigation';


function MessageListPage({AppAlert}) {
    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-md-12">    
                        <span className="float-start"><h4>Messages</h4></span>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <MessageNavigation AppAlert={AppAlert}/>
                    </div>
                    <div className='col-md-8'></div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(MessageListPage);