import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ExpandableCard from '../../../components/ExpandableCard';
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout';
import receiptService from '../receiptServices';

function StudentReceiptList({AppAlert}) {


    let { studentId } = useParams();

    const [receiptList,setReceiptList] = useState(null)
    
    const getReceiptList = () =>{
        receiptService.getByStudentId(studentId).then(res=>{
            setReceiptList(res.data.receiptList);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    useEffect(()=>{
        getReceiptList();
    },[studentId])



    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-4">
                    
                        
                        {
                            receiptList && receiptList.map(receipt=>
                                <>
                                    <div className="col-md-4">
                                        <ExpandableCard title={"Receipt # : "+receipt.receiptDetails.id+" "+ moment(receipt.receiptDetails.paymentDate).format("ll") } defaultOpen={true}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Payable Amount</th>
                                                                <td>{receipt.receiptDetails.payableAmount}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Paid Amount</th>
                                                                <td>{receipt.receiptDetails.paidAmount}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Dues</th>
                                                                <td>{receipt.receiptDetails.dues}</td>
                                                            </tr>
                                                        </thead>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <Link to={"/invoice/receipt/"+receipt.receiptDetails.id} className="float-end btn btn-primary">Print</Link>
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </ExpandableCard>
                                    </div>
                                </>
                        )}

                        {!receiptList || receiptList.length == 0 &&
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h3>No Payment Receipt Found</h3>
                                </div>
                            </div>
                        }
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(StudentReceiptList)