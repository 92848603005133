import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';
import examReportCardExamListServices from '../examReportCardExamListServices';

const ExamReportCardExamListForm = ({data, examReport, AppAlert,refreshList,masters}) => {
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        examReportId : examReport.id,
        examType : "",
        examNumber : "",
        examMonth : "",
        ratio:100,
    };

    const validationRule = Yup.object({
        examType : Yup.string().required("Required"),
        examNumber : Yup.string().required("Required"),
        examMonth : Yup.string().required("Required"),
        ratio : Yup.number("Must be number")
                    .required("Required")
                    .positive()
                    .integer()
                    .min(1, "should be greate then 1")
                    .max(100, "Should be less then 100")
        });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            examReportId : data.examReportId,
            examType : data.examType,
            examNumber : data.examNumber,
            examMonth : data.examMonth,
            ratio: data.ratio,
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        
        setSavingStatus(true)
        examReportCardExamListServices.save(values).then(res =>{
            AppAlert(res.msg);
            refreshList();
            setDialogStatus(false);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
        
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    

    return (
        <>
            {!data &&
                <Tooltip title="Add New">
                    <button type="button" className="btn btn-primary" onClick={addNew} ><Add/> Add New</button>
                </Tooltip>
            }
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Exam Group"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Exam Type" name="examType"  
                                                                options={masters['exam-type']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="number" 
                                                            label="Exam Number" name="examNumber" 
                                                        />
                                                    </div>

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Exam Month" name="examMonth"  
                                                                options={masters['month']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="number" 
                                                            label="Ratio %" name="ratio" 
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    
    )
}

export default ExamReportCardExamListForm