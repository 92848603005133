import React,{useState,useEffect} from 'react'
import withAlert from '../../../hoc/withAlert'
import useMasterList from '../../../hooks/useMasterList';
import AppLayout from '../../../layout/components/AppLayout';
import TransportFormComponent from '../components/TransportFormComponent';
import transportServices from '../transportServices';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Link } from 'react-router-dom';



const CellAction = (props) =>{ 
    return (
        <>
            {props.data &&
                <TransportFormComponent masters={props.masters} AppAlert={props.AppAlert} refreshList={props.getTransportList} data={props.data} />
            }
        </>
    )
}

const CellLink = (props) =>{ 
    return (
        <>
            {props.data &&
                <Link to={"/transport/details/"+props.data.id} className="btn-link" >{props.data.vehicleNumber} </Link>
            }
        </>
    )
}



function TransportListPage({AppAlert}) {
    const [masters,getMasterLabel] = useMasterList(["vehicle-type"]);


    const [transportList,setTransportList] = useState(null);
    const getTransportList = () =>{
        transportServices.getList().then((res)=>{
            setTransportList(res.data);
        }).catch(error=>{
        })
    }

    useEffect(()=>{
        getTransportList();
    },[])

    
    const [defaultColDef] = useState({
        floatingFilter: true,
        width:150,
        filter:true,
        sortable: true,
    })

    const columnDefs = [
        {field:"id", width:80, },
        {field:"vehicleNumber", },
        {field:"id", headerName: 'Action', cellRenderer: CellLink,},
        {field:"vehicleType", valueGetter: params => getMasterLabel("vehicle-type",params.data.vehicleType)},
        {field:"vehicleRoute", },
        {field:"driverName",},
        {field:"driverPhone",},
        {field:"attendantName",},
        {field:"attendantPhone",},
        {field:"id", headerName: 'Action', cellRenderer: CellAction, width:150,cellRendererParams: {masters, AppAlert, getTransportList},},
    ]

    


    return (
        <AppLayout>
            <div className="container">
                <div className="row mt-2">
                    <div className="col-md-12">
                        <h5 className="float-start">Manage Transport</h5>
                        <span className="float-end">
                            <TransportFormComponent AppAlert={AppAlert} masters={masters} refreshList={getTransportList} />
                        </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        {transportList && 
                            <div className="ag-theme-alpine" style={{height: 400, width:"auto"}}>
                                <AgGridReact
                                    rowData={transportList}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    >
                                </AgGridReact>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(TransportListPage)