import React, { useEffect, useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import examGradeServices from '../examGradeServices';
import TextInline from '../../../components/InlineEdit/TextInline';

function ManageGradePage() {

    const [gradeList,setGradeList] = useState();
    const getGradeList = () =>{
        examGradeServices.getList().then(res=>{
            setGradeList(res.data);
        })
    }

    useEffect(()=>{
        getGradeList()
    },[])

    return (
        <AppLayout>
            <div className='container-fluid mt-3'>
                <div className='row'>
                    <div className='col-md-4 offset-4'>
                        <table className='table table-striped table-bordered '>
                            
                                <thead>
                                    <tr>
                                        <th>Marks</th>
                                        <th>Grade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {gradeList && gradeList.length> 0 && gradeList.map(grade=>
                                    <tr key={grade.id}>
                                        <td>{grade.label}</td>
                                        <th><TextInline name="grade" editId={grade.id} value={grade.grade} updateInline={examGradeServices.updateInline} /> </th>
                                    </tr>
                                )}
                                </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </AppLayout>
        
    )
}

export default ManageGradePage