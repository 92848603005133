import React from 'react'
import ReportCardHeader from '../components/ReportCardHeader'
import StudentReportMarksDetailsComponent from '../components/StudentReportMarksDetailsComponent'
import StudentReportAdditionalSubject from '../components/StudentReportAdditionalSubject'
import StudentReportCoScholasticArea from '../components/StudentReportCoScholasticArea'
import ReportCardFooter from '../components/ReportCardFooter'
import StudentReportGradeSection from '../components/StudentReportGradeSection'

function StudentReportPrintComponent({studentDetails,reportCardDetails,masters,getMasterLabel,gradeList,schoolDetails}) {
  return (
        
            <>
                { studentDetails && schoolDetails &&
                    <ReportCardHeader 
                        studentDeatils={studentDetails}
                        schoolDetails={schoolDetails}
                        getMasterLabel={getMasterLabel}
                    />
                }
                
                {reportCardDetails &&            
                    <StudentReportMarksDetailsComponent
                        masters={masters}
                        getMasterLabel={getMasterLabel}
                        reportCardDetails={reportCardDetails}
                        gradeList={gradeList}
                    />    
                }

                {/*
                    reportCardDetails &&
                    <StudentReportAdditionalSubject
                        masters={masters}
                        getMasterLabel={getMasterLabel}
                        reportCardDetails={reportCardDetails}
                        gradeList={gradeList}
                    />
                */}

                {/*
                    reportCardDetails &&
                    <StudentReportCoScholasticArea
                        masters={masters}
                        getMasterLabel={getMasterLabel}
                        reportCardDetails={reportCardDetails}
                        gradeList={gradeList}
                    />
                */}

                {reportCardDetails &&
                    <StudentReportGradeSection
                        masters={masters}
                        getMasterLabel={getMasterLabel}
                        reportCardDetails={reportCardDetails}
                        gradeList={gradeList}
                    />
                }

                {reportCardDetails &&
                    <ReportCardFooter
                        masters={masters}
                        getMasterLabel={getMasterLabel}
                        reportCardDetails={reportCardDetails}
                        gradeList={gradeList}
                    />
                }

                <div className='pagebreak'></div>

            </>
        
       
  )
}

export default StudentReportPrintComponent



/*


        <h4>{studentDetails.firstName}</h4>
        <h5>studentDetails</h5>
        <p>{JSON.stringify(studentDetails)}</p>
        

        <h5>reportCardDetails</h5>
        <p>{JSON.stringify(reportCardDetails)}</p>

        <h5>masters</h5>
        <p>{JSON.stringify(masters)}</p>

        <h5>gradeList</h5>
        <p>{JSON.stringify(gradeList)}</p>

        <h5>getMasterLabel</h5>
        <p>{getMasterLabel("class",studentDetails.class)}</p>
        


        <hr/>

*/