import React, { useEffect, useState } from 'react'
import { Edit } from '@mui/icons-material'

/* Fromik */
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import studentServices from '../studentServices';
import moment from 'moment';


function StudentEditFormComponent({studentDetails,masters,AppAlert,refreshData}) {


    // Form Work In Progress Start 
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {

        area:"",
        category:"",
        specialCategory:"",
        medium:"",
        familyIncome:"",
        fatherUid:"",
        fatherPan:"",
        motherUid:"",
        motherPan:"",

        totalSiblings:"",
        lastSchoolName:"",

        branch:"",
        admissionDate:"2000-01-01",
        gender:"",
        admissionNo:"",
        regNo:"",
        uid:"",
        familyId:"",
        firstName:"",
        lastName:"",
        photo:"",
        class:"",
        section:"",
        house:"",
        rollNo:"",
        dob:"2000-01-01",
        addressLine1:"",
        addressLine2:"",
        city:"",
        state:"",
        pincode:"",
        bloodGroup:"",
        height:"",
        weight:"",
        religion:"",
        caste:"",
        nationality:"",
        fatherName:"",
        fatherOccupation:"",
        fatherQualification:"",
        fatherPhone:"",
        motherName:"",
        motherOccupation:"",
        motherQualification:"",
        motherPhone:"",
    }

    const validationRule = Yup.object({
        branch:Yup.string().required("Required"),
        admissionDate:Yup.string().required("Required"),
        gender:Yup.string().required("Required"),
        admissionNo:Yup.string().required("Required"),
        //uid:Yup.string().required("Required"),
        firstName:Yup.string().required("Required"),
        //lastName:Yup.string().required("Required"),
        //photo:Yup.string().required("Required"),
        medium:Yup.string().required("Required"),
        class:Yup.string().required("Required"),
        section:Yup.string().required("Required"),
        house:Yup.string().required("Required"),
        //rollNo:Yup.string().required("Required"),
        dob:Yup.string().required("Required"),
        addressLine1:Yup.string().required("Required"),
        city:Yup.string().required("Required"),
        state:Yup.string().required("Required"),
        pincode:Yup.string().required("Required"),
        area:Yup.string().required("Required"),
        bloodGroup:Yup.string().required("Required"),
        religion:Yup.string().required("Required"),
        caste:Yup.string().required("Required"),
        nationality:Yup.string().required("Required"),
        fatherName:Yup.string().required("Required"),
        category:Yup.string().required("Required"),
        specialCategory:Yup.string().required("Required"),
        fatherOccupation:Yup.string().required("Required"),
        fatherQualification:Yup.string().required("Required"),
        //fatherPhone:Yup.string().required("Required"),
        //motherName:Yup.string().required("Required"),
        motherOccupation:Yup.string().required("Required"),
        motherQualification:Yup.string().required("Required"),
        //motherPhone:Yup.string().required("Required"),

        totalSiblings:Yup.string().required("Required"),
        
    });

    

    const updateData = ()=>{
        let data = studentDetails;
        let autoFill = {
            id:data.id,

            area:data.area,
            category:data.category,
            specialCategory:data.specialCategory,
            medium:data.medium,
            familyIncome:data.familyIncome,
            fatherUid:data.fatherUid,
            fatherPan:data.fatherPan,
            motherUid:data.motherUid,
            motherPan:data.motherPan,

            totalSiblings:data.totalSiblings,
            lastSchoolName:data.lastSchoolName,
            
            branch:data.branch,
            admissionDate:moment(data.admissionDate).format("YYYY-MM-DD"),
            gender:data.gender,
            admissionNo:data.admissionNo,
            regNo:data.regNo,
            uid:data.uid,
            familyId:data.familyId,
            firstName:data.firstName,
            lastName:data.lastName,
            photo:data.photo,
            class:data.class,
            section:data.section,
            house:data.house,
            rollNo:data.rollNo,
            dob:data.dob,
            addressLine1:data.addressLine1,
            addressLine2:data.addressLine2,
            city:data.city,
            state:data.state,
            pincode:data.pincode,
            height:data.height,
            weight:data.weight,
            bloodGroup:data.bloodGroup,
            religion:data.religion,
            caste:data.caste,
            nationality:data.nationality,
            fatherName:data.fatherName,
            fatherOccupation:data.fatherOccupation,
            fatherQualification:data.fatherQualification,
            fatherPhone:data.fatherPhone,
            motherName:data.motherName,
            motherOccupation:data.motherOccupation,
            motherQualification:data.motherQualification,
            motherPhone:data.motherPhone,
            
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    const onSubmit = (values, onSubmitProps) => {
        //console.log(values);
        values.admissionYear = moment(values.admissionDate).format("Y");
        studentServices.save(values).then(res =>{
            AppAlert(res.msg);
            refreshData();
            setDialogStatus(false);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })

    }

    // Form Work In progress End

    return (
        <>
            <Tooltip title="Update">
                <IconButton onClick={updateData}>
                    <Edit/>
                </IconButton>
            </Tooltip>


            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Edit Student Details"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                {//JSON.stringify(formik.values)
                                                }
                                                </div>
                                                <div className="row">
                                                    
                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Branch" name="branch"  
                                                                options={masters['branch']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Gender" name="gender"  
                                                                options={masters['gender']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="date" 
                                                            label="Admission Date" name="admissionDate" 
                                                        />
                                                    </div>

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Admission No" name="admissionNo" 
                                                        />
                                                    </div>

                                                    

                                                    

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Student Reg No" name="regNo" 
                                                        />
                                                    </div>

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="UID" name="uid" 
                                                        />
                                                    </div>

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Family Id" name="familyId" 
                                                        />
                                                    </div>

                                                    <h6>Student Details</h6>

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="First Name" name="firstName" 
                                                        />
                                                    </div>

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Last Name" name="lastName" 
                                                        />
                                                    </div>

                                                    

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Roll No" name="rollNo" 
                                                        />
                                                    </div>


                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="date" 
                                                            label="Date of Birth" name="dob" 
                                                        />
                                                    </div>


                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Medium" name="medium"  
                                                                options={masters['medium']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Class" name="class"  
                                                                options={masters['class']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Section" name="section"  
                                                                options={masters['section']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="House" name="house"  
                                                                options={masters['house']} multiple={false} 
                                                            />
                                                        </div>
                                                    }




                                                    <h6>Address Details</h6>

                                                    <div className="col-md-6 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Address Line 1" name="addressLine1" 
                                                        />
                                                    </div>

                                                    <div className="col-md-6 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Address Line 2" name="addressLine2" 
                                                        />
                                                    </div>



                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="City" name="city"  
                                                                options={masters['city']} multiple={false} 
                                                            />
                                                        </div>
                                                    }
                                                    
                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="State" name="state"  
                                                                options={masters['state']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Pincode" name="pincode" 
                                                        />
                                                    </div>

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Area" name="area"  
                                                                options={masters['area']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <hr/>
                                                    <h4>Previous School Name</h4>
                                                        <div className="col-md-12 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Previous School Name" name="lastSchoolName" 
                                                        />
                                                    </div>

                                                    <hr/>


                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Height" name="height" 
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Weight" name="weight" 
                                                        />
                                                    </div>

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Blood Group" name="bloodGroup"  
                                                                options={masters['blood-group']} multiple={false} 
                                                            />
                                                        </div>
                                                    }
                                                    
                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Religion" name="religion"  
                                                                options={masters['religion']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Caste" name="caste"  
                                                                options={masters['caste']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Nationality" name="nationality"  
                                                                options={masters['nationality']} multiple={false} 
                                                            />
                                                        </div>
                                                    }


                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Category" name="category"  
                                                                options={masters['category']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Special Category" name="specialCategory"  
                                                                options={masters['special-category']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Family Income" name="familyIncome" 
                                                        />
                                                    </div>

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="number" 
                                                            label="Total Siblings (Including Me)" name="totalSiblings" 
                                                        />
                                                    </div>


                                                    <h5>Gardian's Details</h5>
                                                    <h6>Father</h6>
                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Father Name" name="fatherName" 
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Father Aadhar" name="fatherUid" 
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Father Pan" name="fatherPan" 
                                                        />
                                                    </div>


                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Father Occupation" name="fatherOccupation"  
                                                                options={masters['occupation']} multiple={false} 
                                                            />
                                                        </div>
                                                    }
                                                    
                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Father Qualification" name="fatherQualification"  
                                                                options={masters['qualification']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Father Phone" name="fatherPhone" 
                                                        />
                                                    </div>

                                                    <h6>Mother</h6>

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Mother Name" name="motherName" 
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Mother Aadhar" name="motherUid" 
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Mother Pan" name="motherPan" 
                                                        />
                                                    </div>


                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Mother Occupation" name="motherOccupation"  
                                                                options={masters['occupation']} multiple={false} 
                                                            />
                                                        </div>
                                                    }
                                                    
                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Mother Qualification" name="motherQualification"
                                                                options={masters['qualification']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Mother Phone" name="motherPhone" 
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}

export default StudentEditFormComponent