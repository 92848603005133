import api from "../../api/api";


const get = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("invoice/receipt/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getByStudentId = (studentId) =>{
    return new Promise((resolve, reject)=>{
        api.get("invoice/receipt/student/"+studentId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getCollectionReport = (filters) =>{
    return new Promise((resolve, reject)=>{
        api.post("invoice/receipt/collection/report",filters).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getCollectionList = (filters) =>{
    return new Promise((resolve, reject)=>{
        api.get("invoice/receipt/collection/list").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}




const updateReceipt = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("invoice/receipt",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const receiptService = {
    get,
    getByStudentId,
    getCollectionReport,
    getCollectionList,
    updateReceipt,
}
export default receiptService;