import { LinearProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import useMasterList from '../../../hooks/useMasterList'
import AppLayout from '../../../layout/components/AppLayout';
import reportServices from '../reportServices';

function AttendanceReport() {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [masters,getMasterLabel] = useMasterList(['class',"section","subject","salutation","department","designation"])

    const [classTeacherList,setClassTeacherList]    =   useState(null)
    
    const getAttendanceReport = () =>{
        reportServices.getAttendanceReport().then(res=>{
            setClassTeacherList(res.data);
        })
    }
    useEffect(()=>{
        getAttendanceReport();
    },[])


    const normalise = (score,total) => (score/total) *100;

    const doTotal = (col) =>{
        let total =0;
        for(let i =0; i<classTeacherList.length;i++){
            total = total + classTeacherList[i][col]
        }
        return total;
    }

    return (
            <AppLayout>
                <div className="container-fluid">
                    {classTeacherList && 
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <h3>Attendance Report </h3>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="btn btn-warning float-end" onClick={handlePrint} >Print</button>
                            </div>
                            <div className="col-md-12 table-responsive" ref={componentRef}>
                                <table className="table table-bordered table-sm table-striped" style={{fontSize:12}} >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Class</th>
                                            <th>Class Teacher Name</th>
                                            <th>Teacher Id</th>
                                            <th>Total Student</th>
                                            <th className='table-danger'>A</th>
                                            <th>Boys</th>
                                            <th className='table-danger'>A</th>
                                            <th>Girls</th>
                                            <th className='table-danger'>A</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {classTeacherList.map((classTeacher,i)=>
                                            <tr key={classTeacher.id}>
                                                <td>{i+1}</td>
                                                <td>
                                                    
                                                    {classTeacher.batchId !== null ?
                                                        <Link to={"/attendance/batch/"+classTeacher.batchId} >
                                                            <strong>{getMasterLabel("class",classTeacher.class)}-{getMasterLabel("section",classTeacher.section)}</strong>
                                                        </Link>
                                                        :
                                                            <strong>{getMasterLabel("class",classTeacher.class)}-{getMasterLabel("section",classTeacher.section)}</strong>
                                                    }
                                                    
                                                    
                                                </td>
                                                <td><strong>{classTeacher.firstName} {classTeacher.lastName}</strong></td>
                                                <td>{classTeacher.teacherUID}</td>


                                                {classTeacher.batchId == null ?
                                                        <td colSpan="6" className="text-center">Attendance Not Marked</td>
                                                    :
                                                        <>
                                                        {classTeacher.totalCount > 0 ?
                                                            <>
                                                                <td>
                                                                    {classTeacher.totalPresentCount} / {classTeacher.totalCount}
                                                                    <LinearProgress color="inherit"  variant="determinate" value={normalise(classTeacher.totalPresentCount,classTeacher.totalCount)} ></LinearProgress>
                                                                </td>
                                                                <td className='table-danger'><span className='text-danger'>{classTeacher.totalAbsentCount}</span></td>
                                                                <td>
                                                                    {classTeacher.presentMaleCount} / {classTeacher.totalMaleCount}
                                                                    <LinearProgress variant="determinate" value={normalise(classTeacher.presentMaleCount,classTeacher.totalMaleCount)} ></LinearProgress>
                                                                </td>
                                                                <td className='table-danger'><span className='text-danger'>{classTeacher.absentMaleCount}</span></td>
                                                                <td>
                                                                    {classTeacher.presentFemaleCount} / {classTeacher.totalFemaleCount}
                                                                    <LinearProgress color="secondary"  variant="determinate" value={normalise(classTeacher.presentFemaleCount,classTeacher.totalFemaleCount)} ></LinearProgress>
                                                                </td>
                                                                <td className='table-danger'><span className='text-danger'>{classTeacher.absentFemaleCount}</span></td>
                                                            </>
                                                            :
                                                            <>
                                                                <td colSpan="3" className="text-center"></td>
                                                            </>
                                                        }
                                                        </>
                                                }

                                                
                                                
                                                
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan={4}>Total</th>
                                            <th>{doTotal("totalPresentCount")} / {doTotal("totalCount")}</th>
                                            <th className='table-danger'>{doTotal("totalAbsentCount")}</th>
                                            <th>{doTotal("presentMaleCount")} / {doTotal("totalMaleCount")} </th>
                                            <th className='table-danger'>{doTotal("absentMaleCount")}</th>
                                            <th>{doTotal("presentFemaleCount")} / {doTotal("totalFemaleCount")} </th>
                                            <th className='table-danger'>{doTotal("absentFemaleCount")}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </AppLayout>
    )
}

export default AttendanceReport