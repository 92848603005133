import api from "../../api/api";


const get = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/teacher-message").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/teacher-message/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getByTeacherId = (teacherId)=> {
    return new Promise((resolve, reject)=>{
        api.get("/teacher-message/teacher/"+teacherId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const send = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/teacher-message",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const  serialize = (obj) =>{
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}


const filter = (filters) =>{
    return new Promise((resolve, reject)=>{
        api.get("/teacher-message/list/filter?"+serialize(filters)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getTemplateList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/message-template").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const teacherMessageServices = {
    get,
    getById,
    getByTeacherId,
    getTemplateList,
    filter,
    send,
}
export default teacherMessageServices;