import React, { useEffect, useState } from 'react'
import useMasterList from '../../../hooks/useMasterList';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import examReportServices from '../examReportCardServices';

function ExamReportCardClassForm({examReport,AppAlert,refreshList}) {
    
    const [masters,getMasterLabel] = useMasterList(["class"]);
    

    const [reportClassList,setReportClassList] = useState([]);
    const prepareReportClassList = () =>{
        if(examReport.classList !== null){
            let report_class_list = examReport.classList.split(",");
            report_class_list = report_class_list.map(Number);
            setReportClassList(report_class_list);
        }
    }
    useEffect(()=>{
        prepareReportClassList();
    },[examReport])

    const [dialogStatus, setDialogStatus] = useState(false);
    const handleClose = () => {
        setDialogStatus(false);
    }

    const [classFormList,setClassFormList] = useState();
    const prepareClassFormList = () =>{
        
        let class_form_list=[];
        if(masters){
            for(let i = 0; i < masters["class"].length; i++){
                let item = {...masters["class"][i]};
                if(reportClassList.includes(masters["class"][i].value)){
                    item.selected = true;
                } else {
                    item.selected = false;
                }
                class_form_list.push(item);
            }
            console.log(class_form_list);
            setClassFormList(class_form_list);
        }
    }

    const selectClass = (i) => {
        let class_form_list = [...classFormList];
        class_form_list[i].selected = !class_form_list[i].selected;
        setClassFormList(class_form_list);
    }
    useEffect(prepareClassFormList,[masters,reportClassList]);

    const updateClassList  = () =>{
        
        let classList = [];

        classFormList.forEach(classForm => {
            if(classForm.selected) {
                classList.push(classForm.value);
            }
        });
        console.log(classList);
        examReportServices.save({id:examReport.id,classList:classList.toString()}).then(res=>{
            refreshList();
            AppAlert(res.msg);
            setDialogStatus(false)
        }).catch(error=>{
            console.log(error);
        })
    
        
    }

    return (
        <>
            <h4>Classes</h4>
            {reportClassList.length == 0 &&
                <button type='button' onClick={()=>setDialogStatus(true)} className='btn btn-primary m-1'>Add Class</button>
            }        
            {reportClassList && reportClassList.map(reportClass =>
                <button key={reportClass} type='button' onClick={()=>setDialogStatus(true)} className='btn btn-primary m-1'>{getMasterLabel("class",reportClass)}</button>
            )}
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Select Class</DialogTitle>
                <DialogContent>
                    <div className='row'>
                        <div className='col-md-12'>
                            
                            <table className='table'>
                                <tbody>
                                    {classFormList && classFormList.map((reportClass,i)=>
                                        <tr key={reportClass.value} onClick={()=>{selectClass(i)}}>
                                            <td className={reportClass.selected ? "table-success" : ""}>{reportClass.label}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type='button' className='btn btn-primary' onClick={updateClassList}>Save</button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default ExamReportCardClassForm