import React, { useEffect, useState } from 'react'
import withAlert from '../../../hoc/withAlert'
import useMasterList from '../../../hooks/useMasterList'
import AppLayout from '../../../layout/components/AppLayout';
import FeesConcessionForm from '../components/FeeConcessionForm';
import concessionServices from '../concessionServices';

function FeesConcessionPage({AppAlert}) {
    const [masters,getMasterLabel] = useMasterList(['fees-head']);
    
    const [concessionList,setConcessionList] = useState(null);
    const getConcessionList = () =>{
        concessionServices.getList().then(res=>{
            setConcessionList(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }
    useEffect(()=>{
        getConcessionList()
    },[]);


    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-6">
                        <h3>Manage Concession</h3>
                    </div>
                    <div className="col-md-6">
                        <span className="float-end"><FeesConcessionForm masters={masters} AppAlert={AppAlert} refreshList={getConcessionList} /></span>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        {concessionList && concessionList.length > 0 &&
                            <table className="table table-stripped">
                                <thead>
                                    <tr>
                                        <th>Concession Title</th>
                                        <th>Concession wil apply on </th>
                                        <th>Concession %</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {concessionList.map(concession=>
                                        <tr key={concession.id}>
                                            <td>{concession.title}</td>
                                            <td>{getMasterLabel("fees-head",concession.feesHead)}</td>
                                            <td>{concession.concession}%</td>
                                            <td><FeesConcessionForm masters={masters} AppAlert={AppAlert} refreshList={getConcessionList} data={concession} /></td>
                                        </tr>

                                    )}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default  withAlert(FeesConcessionPage)