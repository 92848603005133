import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import examReportServices from '../examReportCardServices';

function ExamReportCardAssessmentList({examReport,AppAlert,refreshList}) {



    const [reportAssessmentList,setReportAssessmentList] = useState([]);
    const prepareReportAssessmentList = () =>{
        if(examReport.assessmentList !== null){
            let report_assessment_list = examReport.assessmentList.split(",");
            setReportAssessmentList(report_assessment_list);
        }
    }
    useEffect(()=>{
        prepareReportAssessmentList();
    },[examReport])



    const [assessmentFormList,setAssessmentFormList] = useState(null);
    const prepareAssessmentForm = () =>{
        console.log("here in report assessment list ");
        
        let assessment_list = [
            {value: "report-co-curricular", selected:reportAssessmentList.includes("report-co-curricular")},
            {value: "report-discipline", selected:reportAssessmentList.includes("report-discipline")},
            {value: "report-linguistic", selected:reportAssessmentList.includes("report-linguistic")},
            {value: "report-numeracy",  selected:reportAssessmentList.includes("report-numeracy")},
            {value: "report-parents-involvement", selected:reportAssessmentList.includes("report-parents-involvement")},
        ]

        console.log("assessment list ",assessment_list);
        
        setAssessmentFormList(assessment_list);
    }

    useEffect(()=>{
        prepareAssessmentForm();
    },[reportAssessmentList]);

    const [dialogStatus,setDialogStatus] = useState(false);
    const handleClose = () =>{
        setDialogStatus(false);
    }


    const selectAssessment = (i) => {
        let assessment_form_list = [...assessmentFormList];
        assessment_form_list[i].selected = !assessment_form_list[i].selected;
        setAssessmentFormList(assessment_form_list);
    }


    const updateAssessmentList  = () =>{
        
        let assessmentList = [];

        assessmentFormList.forEach(assessmentForm => {
            if(assessmentForm.selected) {
                assessmentList.push(assessmentForm.value);
            }
        });
        console.log(assessmentList);
        
        examReportServices.save({id:examReport.id,assessmentList:assessmentList.toString()}).then(res=>{
            refreshList();
            AppAlert(res.msg);
            setDialogStatus(false)
        }).catch(error=>{
            console.log(error);
        })
        
    }

    const redableMaster = (masterName) =>{
        let m = masterName.replace("report-","");
        return m.toUpperCase();
    }

    return (
        <>
            
            {reportAssessmentList.length == 0 &&
                <button type='button' onClick={()=>setDialogStatus(true)} className='btn btn-primary m-1'>Add Assessment</button>
            }        
            {reportAssessmentList && reportAssessmentList.map(reportAssessment =>
                <button key={reportAssessment} type='button' onClick={()=>setDialogStatus(true)} className='btn btn-primary m-1'>{redableMaster(reportAssessment)}</button>
            )}
            

            
            <Dialog
                    open={dialogStatus}
                    keepMounted
                    onClose={handleClose}
                    fullWidth={true}
                    maxWidth="md"
                    aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Select Assessment</DialogTitle>
                <DialogContent>
                    <div className='row'>
                        <div className='col-md-12'>
                            <table className='table'>
                                <tbody>
                                    {assessmentFormList && assessmentFormList.map((assessment,i)=>
                                        <tr key={assessment.value} onClick={()=>{selectAssessment(i)}}>
                                            <td className={assessment.selected ? "table-success" : ""}>{redableMaster(assessment.value)} </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <button type='button' className='btn btn-primary' onClick={updateAssessmentList}>Save</button>
                </DialogActions>
            </Dialog>
              
    </>
  )
}

export default ExamReportCardAssessmentList