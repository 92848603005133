import React, { useState, useRef } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import { useParams } from 'react-router-dom'
import studentReportCardService from '../studentReportCardServices';

import useMasterList from '../../../hooks/useMasterList';
import examGradeServices from '../../Exam/examGradeServices';
import StudentReportMarksDetailsComponent from '../components/StudentReportMarksDetailsComponent';
import ReportCardHeader from '../components/ReportCardHeader';
import StudentReportAdditionalSubject from '../components/StudentReportAdditionalSubject';
import StudentReportCoScholasticArea from '../components/StudentReportCoScholasticArea';
import ReportCardFooter from '../components/ReportCardFooter';

import { useReactToPrint } from 'react-to-print';
import schoolServices from '../../settings/schoolServices';
import StudentReportGradeSection from '../components/StudentReportGradeSection';

function StudentReportCardPage() {
    let { studentId } = useParams();

    const [masters,getMasterLabel]  = useMasterList(['class', 'subject', 'exam-type', 'month', 'section',"report-co-curricular", "report-discipline", "report-linguistic", "report-numeracy", "report-parents-involvement", "report-remark","assessment-grade"])
    const [gradeList,setGradeList] = useState(null);
    const getGradeList = () =>{
        examGradeServices.getList().then(res=>{
            setGradeList(res.data);
        })
    }


    const [studentDetails, setStudentDetails]   =   useState(null);
    const [studentReportCardList, setStudentRepordCardList]   =   useState(null);

    const [reportCardDetails,setReportCardDetails] = useState(null);

    const getStudentReportCardList = () =>{
        studentReportCardService.getByStudentId(studentId).then(res=>{
            setStudentDetails(res.data.studentDetails);
            setStudentRepordCardList(res.data.studentReportCardList);
            
            console.log("length",res.data.studentReportCardList.length)

            if(res.data.studentReportCardList.length > 0){
                setReportCardDetails(res.data.studentReportCardList[0]);
            }
            
        })
    }

    const [schoolDetails,setSchoolDetails] = useState(null);
    const getSchoolDetails = () =>{
        schoolServices.get().then(res=>{
            setSchoolDetails(res.data);
        })
    }

    

    useState(()=>{
        getGradeList()
        getStudentReportCardList();
        getSchoolDetails();
    },[])


    /* Print Work Start */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    /* Print Work End */

    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row mt-3'>
                    <div className='col-md-3'>
                        {studentReportCardList && studentReportCardList.length > 0 &&
                            <div className="list-group">
                                {studentReportCardList.map(studentReportCard=>
                                    <a key={studentReportCard.id}  href="#" className="list-group-item list-group-item-action">{studentReportCard.title}</a>
                                )}
                            </div>
                        }
                    </div>
                    <div className='col-md-9'>
                        <button onClick={handlePrint} className="btn btn-primary" >Print</button>
                        <div ref={componentRef} className='p-2'>
                            {reportCardDetails && gradeList && masters &&
                                <>
                                {studentDetails && 
                                    <ReportCardHeader 
                                        studentDeatils={studentDetails}
                                        schoolDetails={schoolDetails} 
                                        getMasterLabel={getMasterLabel}
                                    />
                                }
                                {reportCardDetails &&
                                
                                    <StudentReportMarksDetailsComponent
                                        masters={masters}
                                        getMasterLabel={getMasterLabel}
                                        reportCardDetails={reportCardDetails}
                                        gradeList={gradeList}
                                    />
                                }

                                {/*
                                    reportCardDetails &&
                                    <StudentReportAdditionalSubject
                                        masters={masters}
                                        getMasterLabel={getMasterLabel}
                                        reportCardDetails={reportCardDetails}
                                        gradeList={gradeList}
                                    />
                                */}

                                {/*
                                    reportCardDetails &&
                                    <StudentReportCoScholasticArea
                                        masters={masters}
                                        getMasterLabel={getMasterLabel}
                                        reportCardDetails={reportCardDetails}
                                        gradeList={gradeList}
                                    />
                                */}

                                {reportCardDetails &&
                                    <StudentReportGradeSection
                                        masters={masters}
                                        getMasterLabel={getMasterLabel}
                                        reportCardDetails={reportCardDetails}
                                        gradeList={gradeList}
                                    />
                                }

                                {reportCardDetails &&
                                    <ReportCardFooter
                                        masters={masters}
                                        getMasterLabel={getMasterLabel}
                                        reportCardDetails={reportCardDetails}
                                        gradeList={gradeList}
                                    />
                                }


                                </>

                            }
                        </div>
                    </div>

                </div>
            </div>
        </AppLayout>
        
    )
}

export default StudentReportCardPage