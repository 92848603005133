import React,{useState,useEffect} from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout';
import systemMasterServices from '../../../master/systemMasterServices';
import StudentBatchClassSectionFilter from '../../Filters/components/StudentBatchClassSectionFilter';
import IDCardComponent from '../components/IDCardComponent';


function BatchIdCard({AppAlert}) {


    /* Start Masters Setting  */
    const [masters,setMasters] = useState(null);
    const [searchingInProgress, setSearchingStatus] = useState(false);

    const getMasterList = () => {
        systemMasterServices.get([
            "branch","class","section","subject"
        ]).then(res=>{
            setMasters(res);
        })
    }
    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }
    useEffect(()=>{
        getMasterList();
    },[])
    /* End Masters Setting  */

    const [studentList,setStudentList] =  useState(null);


    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-12">
                        <StudentBatchClassSectionFilter setStudentList={setStudentList} masters={masters} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {studentList && <IDCardComponent studentList={studentList} /> }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(BatchIdCard)