import React, { useEffect, useState, useRef, useCallback } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import StudentBatchClassSectionFilter from '../../Filters/components/StudentBatchClassSectionFilter'
import useMasterList from '../../../hooks/useMasterList'


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';
import { Link } from 'react-router-dom';


const CellLink = (props) =>{
    return (
        <>
            {props && <Link target="_blank" to={`/invoice/generate?admissionNo=${props.data.admissionNo}`}>{props.data.admissionNo }/{ moment(props.data.admissionYear).format("YY")}</Link> }
        </>
    )
}

const StudentImage = (props) =>{
    return (
        <>
            {props && <img style={{width:40,height:40}} className='rounded float-start' src={props.data.photo} /> }
        </>
    )
}


function StudentSearchPage() {

    const [studentList,setStudentList] = useState();
    const gridRef = useRef();
    const [masters,getMasterLabel] = useMasterList(["area", "category", "special-category", "medium","branch","gender","class","section", "house","city", "state","blood-group","religion","caste","nationality","occupation","qualification"],"all");

    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
        cellStyle: { 'textTransform': 'uppercase' },
    });


    const [columnDefs,setColumnDefs] = useState([]);


    const setupColDef = () =>{
        setColumnDefs([            
            {field:"id", cellRenderer:StudentImage, filter:false,headerName:"",width:60},
            {field:"firstName"},
            {field:"lastName"},
            {field:"fatherName"},
            {field:"Gender", valueGetter: params => getMasterLabel("gender",params.data.gender)},
            {field:"admissionNo",  valueGetter: params => params.data.admissionNo + "/" + params.data.admissionYear, cellRenderer: CellLink,},
            {field:"branch", valueGetter: params => getMasterLabel("branch",params.data.branch)},            
            {field:"class",valueGetter: params => getMasterLabel("class",params.data.class)},
            {field:"section",valueGetter: params => getMasterLabel("section",params.data.section)},
            {field:"rollNo"},
            {field:"dob", valueGetter: params => moment(params.data.dob).format("DD-MMM-YYYY")},
            
        ]);
    }

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);


    useEffect(()=>{
        setupColDef();
    },[studentList])


    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-9">
                        <StudentBatchClassSectionFilter setStudentList={setStudentList} masters={masters} />
                    </div>
                    <div className='col-md-3'>
                        <button type='button' className='btn btn-primary' onClick={onBtnExport} >Export</button>
                    </div>
                </div>

                <div className ="row">
                    {masters && 
                            <>
                                <div className="col-md-12">
                                <div className="ag-theme-alpine" style={{height: 550, width:"auto"}}>
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={studentList}
                                            columnDefs={columnDefs}
                                            defaultColDef={defaultColDef}
                                            animateRows={true}
                                            >
                                        </AgGridReact>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </AppLayout>
    )
}

export default StudentSearchPage