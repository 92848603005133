import { CheckBoxOutlineBlank, CheckBoxOutlined, Email, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Checkbox, Dialog, DialogActions, DialogContent, FormControl, IconButton, InputLabel, MenuItem, Radio, Select } from '@mui/material';
import React, { useState, useEffect } from 'react'
import systemMasterServices from '../../../master/systemMasterServices';
import attendanceServices from '../attendanceServices';
import useMasterList from '../../../hooks/useMasterList'

function UpdateAttendanceComponent({studentList,setStudentList,AppAlert,existingAttendanceList,batchId}) {


    const [saving, setSavingStatus] = useState(false);
    
    const [masters,getMasterLabel] = useMasterList(["attendance-status"])

    /* All Status Work */
    const [allStatusValue,setAllStatusValue] = useState(1);
    const [allNotifyValue,setAllNotifyValue] = useState(0);
    const handleAllStatusChange = (event) =>{
        setAllStatusValue(parseInt(event.target.value));
        markAll(event.target.value);
    }
    const handleAllNotifyChange = (event) =>{
        let value = event.target.checked ? 1:0;
        setAllNotifyValue(value);
        markNotifyAll(value);
    }

    
    const markAll = (attendanceStatus) =>{
        let attendanceList = studentAttendanceList.map(student=>{
                student.attendanceStatus = parseInt(attendanceStatus);
            return student;
        })
        setStudentAttendanceList(attendanceList);
    }

    const markNotifyAll = (notifyStatus) =>{
        let attendanceList = studentAttendanceList.map(student=>{
                student.notify = parseInt(notifyStatus);
            return student;
        })
        setStudentAttendanceList(attendanceList);

    }


    /* All Status Work */

    const getExistingStatus = (id) =>{
        let existing  = existingAttendanceList.find(attendance=>{
            if(attendance.studentId ==  id){
                return attendance;
            }
        });
        return existing.attendanceStatus;
    }
    const [studentAttendanceList,setStudentAttendanceList] = useState(null)

    const  prepairAttendanceList = () =>{
        const attendanceList = studentList.map(student=>{
            return ({
                id:student.id,
                photo:student.photo,
                admissionNo:student.admissionNo,
                rollNo:student.rollNo,
                firstName:student.firstName,
                lastName:student.lastName,
                fatherName:student.fatherName,
                attendanceStatus:getExistingStatus(student.id),
                notify:0,
            })
        })
        setStudentAttendanceList(attendanceList);
    }

    useEffect(()=>{
        prepairAttendanceList();
    },[studentList])

    

    const markAttendance = (studentId,attendanceStatus) =>{
        let attendanceList = studentAttendanceList.map(student=>{
            if(student.id === studentId){
                student.attendanceStatus = attendanceStatus;
            }
            return student;
        })
        setStudentAttendanceList(attendanceList);
    }

    const setNotify = (studentId,notifyStatus) =>{
        let attendanceList = studentAttendanceList.map(student=>{
            if(student.id === studentId){
                student.notify = notifyStatus === 0? 1:0 ;
            }
            return student;
        })
        setStudentAttendanceList(attendanceList);
    }


    const submitAttendance = () =>{

        setSavingStatus(true);
        let payload = {
            batchId : batchId,
            attendanceList : studentAttendanceList.map(student=>{
                return ({
                    studentId:student.id,
                    rollNo:student.rollNo,
                    attendanceStatus:student.attendanceStatus,
                    notify:student.notify
                })
            }),
        }


        //console.log(payload);
        attendanceServices.update(payload).then(res =>{
            AppAlert("Attendance Updated Successfully");
            //setStudentList(null);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
        

        setSavingStatus(false);
        
    }



    const getInitials = (string) =>{
        var parts = string.split(' ')
        if(parts.length>1){
            return  parts[0][0]+parts[1][0];
        } else {
            return  parts[0][0];
        }
        
    }
    const [dialogStatus,setDialogStatus] = useState(false);
    const [dialogStudent,setDialogStudent] = useState(null);
    const handleClose = () =>{
        setDialogStatus(false);
        setDialogStudent(null);
    }

    const openDialog = (student) =>{
        setDialogStudent(student);
        setDialogStatus(true);
    }

    return (
        <>
        <div className="row">
            <div className="col-md-6 bg-light">

                {/*
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Mark All</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={allStatusValue}
                            label="Mark All"
                            onChange={handleAllStatusChange}
                        >
                            {masters && masters['attendance-status'].map((attendanceStatus=>
                                <MenuItem key={attendanceStatus.value} value={attendanceStatus.value}>{attendanceStatus.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                */}

            {/*
                {masters && masters['attendance-status'].map((attendanceStatus=>
                    <span key={attendanceStatus.value}>
                    <Radio
                            checked={allStatusValue === attendanceStatus.value}
                            onChange={handleAllStatusChange}
                            value={attendanceStatus.value}
                            name="allStatusButton"
                            inputProps={{ 'aria-label': 'Present' }}
                        />
                        {attendanceStatus.label}
                    </span>
                ))}
            */}
            </div>
            <div className="col-md-3">
                {/* <Checkbox checked={allNotifyValue?true:false} onChange={handleAllNotifyChange} /> Notify All */}
            </div>
            <div className="col-md-3">
                <button type="button" className="btn btn-primary" onClick={submitAttendance} >Update Attendance</button>
            </div>
        </div>


        <div className="row">
            <div className="col-md-8">
                {masters &&
                    <>
                        <table className="table">
                            <thead>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {studentAttendanceList && studentAttendanceList.map((student,i)=>
                            
                                    <tr key={student.id}>
                                        <td><img style={{maxHeight:"50px"}} className="img-rounded"  
                                            src={   student.photo ===""?
                                                    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                                    :
                                                    student.photo
                                                } /></td>
                                        <td>
                                            <div className="float-start">
                                                <div>{student.firstName} {student.lastName}, {student.fatherName}</div>
                                                <div>Roll No :{student.rollNo} </div>
                                            </div>
                                        </td>
                                        <td className="text-end">
                                            <div>
                                                {student.attendanceStatus === 1 &&
                                                    <button className="btn btn-success" onClick={()=> openDialog(student)}>
                                                        {getInitials(getMasterLabel("attendance-status",student.attendanceStatus))}
                                                    </button>
                                                }
                                                {student.attendanceStatus === 2 &&
                                                    <button className="btn btn-danger" onClick={()=> openDialog(student)}>
                                                        {getInitials(getMasterLabel("attendance-status",student.attendanceStatus))}
                                                    </button>
                                                }

                                                {student.attendanceStatus > 2 &&
                                                    <button className="btn btn-warning" onClick={()=> openDialog(student)}>
                                                        {getInitials(getMasterLabel("attendance-status",student.attendanceStatus))}
                                                    </button>
                                                }
                                            </div>
                                            <div>
                                                {student.notify===1 && <Email fontSize="small" className="text-primary" />}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <Dialog onClose={handleClose} open={dialogStatus}>
                            
                                <div className="list-group">
                                    {dialogStudent && masters['attendance-status'].map((attendanceStatus=>
                                        <button key={attendanceStatus.value} type="button" className="list-group-item list-group-item-action" onClick={()=>markAttendance(dialogStudent.id,attendanceStatus.value)}> 
                                            {dialogStudent.attendanceStatus === attendanceStatus.value ? <RadioButtonChecked className="text-primary" />:<RadioButtonUnchecked/>}
                                            {attendanceStatus.label}
                                        </button>
                                    ))}
                                </div>
                            
                            <DialogActions>
                                <button className="btn btn-info" onClick={handleClose}>Close</button>
                                <button className="btn btn-light" onClick={()=>{setNotify(dialogStudent.id,0);handleClose();}}>Notify & Close</button>
                            </DialogActions>
                        </Dialog>
                    </>
                }
            </div>

            {
                /*
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-12">
                            {!saving &&
                                <button type="button" className="btn btn-primary" onClick={submitAttendance} >Update Attendance</button>
                            }
                        </div>
                        
                    </div>
                </div>
                */
            }
        </div>
        </>
    )
}

export default UpdateAttendanceComponent