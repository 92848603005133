import api from "../../api/api";


const get = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/message-template").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/message-template/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}






const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/message-template",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateInline = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("/message-template",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}




const messageTemplateServices = {
    get,
    getById,
    updateInline,
    save,
}
export default messageTemplateServices;