import React,{useState,useEffect} from 'react'


/* Fromik */
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';

import transportServices from '../transportServices';



function TransportFormComponent({data, AppAlert,refreshList, masters}) {

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        vehicleNumber:"",
        vehicleType:"",
        vehicleRoute:"",
        driverName:"",
        driverPhone:"",
        attendantName:"",
        attendantPhone:"",
        
    };



    const validationRule = Yup.object({
        vehicleNumber : Yup.string().required("Required"),
        vehicleType : Yup.string().required("Required"),
        
        
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            vehicleNumber:data.vehicleNumber,
            vehicleType:data.vehicleType,
            vehicleRoute:data.vehicleRoute,
            driverName:data.driverName,
            driverPhone:data.driverPhone,
            attendantName:data.attendantName,
            attendantPhone:data.attendantPhone,
            
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        transportServices.save(values).then(res =>{
            AppAlert(res.msg);
            refreshList();
            setDialogStatus(false);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    return (
        <>
            {!data &&
                <Tooltip title="Add New">
                    <IconButton onClick={addNew} style={{padding:"0px"}}>
                        <Add sx={{ fontSize: 40 }} /> Add New
                    </IconButton>
                </Tooltip>
            }
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Fees Setting"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Vehicle Number" name="vehicleNumber" 
                                                        className="col-4 p-2" 
                                                    />

                                                    {masters && 
                                                        <div className="col-4 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="vehicle-type" name="vehicleType"  
                                                                options={masters['vehicle-type']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Vehicle Route" name="vehicleRoute"  
                                                        className="col-4 p-2"  
                                                    />
                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Vehicle Driver Name" name="driverName"  
                                                        className="col-4 p-2"  
                                                    />
                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Vehicle Driver Phone" name="driverPhone"  
                                                        className="col-4 p-2"  
                                                    />
                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Attendant Name" name="attendantName"  
                                                        className="col-4 p-2"  
                                                    />
                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Attendant Phone" name="attendantPhone"  
                                                        className="col-4 p-2"  
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}

export default TransportFormComponent