import { AccountCircle, AccountTree, AirportShuttle, Article, Badge, BusAlert, CalendarMonthOutlined, CalendarViewMonth, Contacts, CurrencyRupeeTwoTone, DirectionsBus, DocumentScanner, Dvr, FilterNone, Fingerprint, ForumSharp, GridOn, Groups, Home,  HomeWorkOutlined,  Label,  LiveHelp,  ManageAccounts, ManageHistory, Money, MoreVert, Notifications, PendingActions, People, Percent, PersonAdd, PersonOff, PersonRounded, RecentActors, Rule, Settings, TableChart, TextSnippet, Toc, TransferWithinAStation, WorkOutlined} from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { Link, NavLink } from 'react-router-dom'


export default function Header() {
    
    const authUser = useSelector((state) => state.auth)
    const userId=  authUser.id;

    const navigate = useNavigate(); 
    

    useEffect(()=>{
        if(authUser.verified === 0){
          navigate("/auth/profile");
        }
    },[authUser])


    return (
        <>  { userId && 
            <nav className="navbar shadow navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src="/global-logo.png" height="50" alt="" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{fontSize:"14px"}}>
                                { authUser.role <= 3 && <>

                                    
                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="" id="employeeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                            <Badge /> Admission
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="employeeDropdown">
                                            {/*<li><NavLink className="dropdown-item" to="/"> <LiveHelp /> Enquiry</NavLink></li> */}
                                            <li><NavLink className="dropdown-item" to="/student/admission"> <Contacts /> New Admission</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/student/list">  <Groups /> Student List </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/student/class-wise/list">  <Groups /> Class Wise Student List </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/report/class-strength-report">  <RecentActors /> Class Strength Report </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/report/attendance-report">  <Rule /> Attendance Report </NavLink></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="" id="employeeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                        <PersonRounded /> Staff
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="employeeDropdown">
                                            <li><NavLink className="dropdown-item" to="/teacher/form"> <PersonAdd/> New Teacher</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/teacher/list">  <People /> Teacher List </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/report/class-teacher-report"> <CalendarViewMonth /> Class Teacher Report </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/hrms/teacher-leave-request"> <TextSnippet /> Teacher Leave Request </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/report/staff-attendance-report"> <TextSnippet /> Staff Attendance Report </NavLink></li>
                                        </ul>
                                    </li>


                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="" id="employeeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                            <TableChart /> Fee
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="employeeDropdown">
                                            
                                            <li><NavLink className="dropdown-item" to="/fee-structure">  <AccountTree /> Manage Fee Structure </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/fee-structure/list">  <GridOn /> View Fee Structure </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/fee-concession"> <Percent/> Manage Fee Concession </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/student/search"> <CurrencyRupeeTwoTone /> Collect Fees </NavLink></li>
                                            {/* <li><NavLink className="dropdown-item" to="/invoice/collection/list"> <Toc/> Collection List </NavLink></li> */ }
                                            <li><NavLink className="dropdown-item" to="/invoice/collection/report"> <Dvr /> Collection Report </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/report/term-wise-collection-report"> <CalendarViewMonth /> Term Installment Report </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/report/due-report"> <PendingActions /> Due Report </NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/report/defaulter-list"> <PersonOff /> Defaulter List </NavLink></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="" id="employeeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                            <AirportShuttle /> Transport
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="employeeDropdown">
                                            <li><NavLink className="dropdown-item" to="/system-master/list#vehicle-type"> <BusAlert /> Manage Vehicle Type</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/transport/list">  <DirectionsBus /> Manage Transport </NavLink></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="" id="employeeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                            <ForumSharp /> Communication
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="employeeDropdown">
                                            <li><NavLink className="dropdown-item" to={"/news/list"} >Manage News</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/message/list"} >Message</NavLink></li>
                                            <li><hr className="dropdown-divider"/></li>
                                            <li><NavLink className="dropdown-item" to={"/home-work/list"} >Manage Homework</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/activity/list"} >Manage Activity</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/event/list"} >Manage Event</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/announcement/list"} >Manage Announcement</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/notice/list"} >Notice / Circular</NavLink></li>
                                        </ul>
                                    </li>


                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="" id="employeeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                            <Article/> Exam
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="employeeDropdown">
                                            <li><NavLink className="dropdown-item" to={"/exam/group"} >Exam Group</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/exam/subject-combination"} >Subject Combinations</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/exam/manage-grade"} >Manage Grades</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/exam/report-card"} >Manage Report Card</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/exam/report-card/print"} >Print Report Card Type One</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/exam/report-card/print/type-two"} >Print Report Card Type Two</NavLink></li>
                                        </ul>
                                    </li>

                                    
                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="" id="employeeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                            <HomeWorkOutlined/> School
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="employeeDropdown">
                                            <li><NavLink className="dropdown-item" to={"/settings/school"} >Manage School</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/settings/branch-locations"} >Manage Branch / Attendance Location</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/settings/holiday"} >Holiday Calendar</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/system-master/list#branch"} >Manage Branch </NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/system-master/list#class"} >Manage Class </NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/system-master/list#section"} >Manage Section </NavLink></li>


                                            <li><NavLink className="dropdown-item" to={"/settings/syllabus"} >Syllabus</NavLink></li>
                                            <li><NavLink className="dropdown-item" to={"/settings/class-timetable"} >Class Timetable</NavLink></li>
                                        </ul>
                                    </li>

                                    


                                </>}
                        </ul>
                        
                        <ul className="navbar-nav">
                            

                            { authUser.role == 1 && 
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="adminControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVert/> </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="adminControllDropdown">
                                    <li><NavLink className="dropdown-item" to="/system-master/list"><Label /> Status Master</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/list"><ManageAccounts/> Manage User Accounts</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/role"><Settings/> Manage User Role</NavLink></li>
                                </ul>
                            </li>
                            }



                            <li className="nav-item dropdown">
                                <a className="btn btn-danger" style={{borderRadius:"50px"}} href="!#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                    {authUser.firstName.charAt(0)}
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                                    <li><a className="dropdown-item" href="/auth/profile">My Profile</a></li>
                                    <li><a className="dropdown-item" href="/auth/change-password">Change Password</a></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><NavLink className="dropdown-item" to="/auth/logout">Logout</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        }
        </>
    )
}
