import React, { useState,useEffect, useRef } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import PrintStudentReportFilter from '../components/PrintStudentReportFilter';
import useMasterList from '../../../hooks/useMasterList';
import examReportCardServices from '../../Exam/examReportCardServices';
import printReportCardServices from '../printReportCardServices';
import examGradeServices from '../../Exam/examGroupServices';
import StudentReportPrintComponent from '../components/StudentReportPrintComponent';
import schoolServices from '../../settings/schoolServices';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment/moment';
import StudentReportTypeTwoPrintComponent from '../components/StudentReportTypeTwoPrintComponent';



function PrintReportCardTypeTwoPage() {
    
    const [masters,getMasterLabel]  = useMasterList([ 'branch', 'class', 'subject', 'exam-type', 'month', 'section',"report-co-curricular", "report-discipline", "report-linguistic", "report-numeracy", "report-parents-involvement", "report-remark","assessment-grade"])
    const [gradeList,setGradeList] = useState(null);
    const getGradeList = () =>{
        examGradeServices.getList().then(res=>{
            setGradeList(res.data);
        })
    }


    const [examReportList,setExamReportList] = useState([]);
    const getExamReportList = () =>{
        examReportCardServices.getList().then(res=>{
            let exam_report_list = [];
            for(let i=0;i<res.data.length;i++){
                exam_report_list.push({label:res.data[i]['examLabel'],value:res.data[i]['id']})
            }
            console.log(exam_report_list);
            setExamReportList(exam_report_list);
        })
    }


    const [schoolDetails,setSchoolDetails] = useState(null);
    const getSchoolDetails = () =>{
        schoolServices.get().then(res=>{
            setSchoolDetails(res.data);
        })
    }


    useEffect(()=>{
        getExamReportList();
        getGradeList()
        getSchoolDetails();
    },[])


    /* Search And print Work Here */
    const [filters,setFiltersValues]    = useState(null);


    const [studentList,setStudentList]  = useState(null);

    const getStudentReportList = () =>{
        if(filters){
            console.log("getList for ",filters);
            printReportCardServices.getList(filters).then(res=>{
                console.log(res);
                setStudentList(res.studentList)
            })
        }
    }
    useEffect(()=>{
        getStudentReportList();
    },[filters])

    /* Print Work Start */
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    /* Print Work End */

  
    return (

    <AppLayout>
        <div className='container-fluid'>
            <div className='row mt-3'>
                <div className='col-md-3'>
                    <PrintStudentReportFilter masters={masters} setFiltersValues={setFiltersValues} examReportList={examReportList} />
                </div>

                <div className='col-md-9'>
                    {studentList && <button type='button' onClick={handlePrint} className="btn btn-primary float-end" >Print All</button> }
                    <div ref={componentRef} className='p-2'>
                        
                        <div className='row'>
                            <div className='col-md-12 text-center'>
                                {filters && <h1 >Report Card List for {getMasterLabel("class",filters.class)} {getMasterLabel("section",filters.section)}</h1> }
                            </div>

                            <div className='col-8 offset-2'>
                                <ul className='text-center'>
                                {studentList && studentList.map(student=>
                                    student.studentReportCard == null && masters &&
                                    <li className='text-danger'>Admission # : <strong> {student.studentDetails.admissionNo} / {moment(student.studentDetails.admissionYear).format("YY")}  </strong>, {student.studentDetails.firstName} {student.studentDetails.lastName} Report Not Found</li>
                                )}
                                </ul>
                            </div>
                        </div>
                        
                        <div className='pagebreak'></div>
                        
                        {studentList && studentList.map(student=>
                            student.studentReportCard &&
                            <StudentReportTypeTwoPrintComponent
                                masters={masters}
                                getMasterLabel={getMasterLabel}
                                studentDetails={student.studentDetails}
                                reportCardDetails={student.studentReportCard}
                                gradeList={gradeList}
                                schoolDetails={schoolDetails}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
        
    </AppLayout>
    
  )
}

export default PrintReportCardTypeTwoPage