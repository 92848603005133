import api from "../../api/api";


const termWiseCollectionReport = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.get("reports/term-wise-collection-report?branch="+payload.branch+"&class="+payload.class).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getClassTeacherReport = () =>{
    return new Promise((resolve, reject)=>{
        api.get("reports/class-teacher-report").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getClassStrengthReport = () =>{
    return new Promise((resolve, reject)=>{
        api.get("reports/class-strength-report").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getAttendanceReport = () =>{
    return new Promise((resolve, reject)=>{
        api.get("reports/attendance-report").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const dueReport = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.get("reports/due-report?branch="+payload.branch+"&class="+payload.class).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const staffAttendanceReport = (date) =>{
    return new Promise((resolve, reject)=>{
        api.get("reports/staff-attendance-report?date="+date).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const reportServices = {
    termWiseCollectionReport,
    getClassTeacherReport,
    getClassStrengthReport,
    getAttendanceReport,
    dueReport,
    staffAttendanceReport,
}
export default reportServices;