
import moment from 'moment'
import React from 'react'
import StudentEditFormComponent from './StudentEditFormComponent'
import { Boy,Girl } from '@mui/icons-material'
import StudentRemove from './StudentRemove'
import StudentMessageComponent from '../../Communication/components/StudentMessageComponent'
import StudentSubjects from './StudentSubjects'



const LabelValue = ({label,value}) =>{
    return (
        <div className="row">
            <div className="col-md-5">
                <strong>{label}</strong>
            </div>
            <div className="col-md-7">
                {value}
            </div>
        </div>
    )
}

const StudentInformation = ({studentDetails,getMasterLabel,masters,AppAlert,refreshData}) =>{
    return (
        <>
            <div className="row">
                <div className="col-9">
                    <h4>{studentDetails.firstName} {studentDetails.lastName} {studentDetails.gender === 1? <Boy/> : <Girl/>}  </h4>
                </div>
                <div className="col-3">
                    <StudentEditFormComponent studentDetails={studentDetails} masters={masters} AppAlert={AppAlert} refreshData={refreshData} />
                    <StudentMessageComponent studentId={studentDetails.id} AppAlert={AppAlert} />
                    <StudentRemove studentDetails={studentDetails}  AppAlert={AppAlert}  />
                </div>

            </div>

            <div className="row">
                <div className="col-md-6">
                    <LabelValue label="Branch" value={getMasterLabel("branch",studentDetails.branch)} />
                    <LabelValue label="Class" value={getMasterLabel("class",studentDetails.class)} />
                    <LabelValue label="Section" value={getMasterLabel("section",studentDetails.section)} />
                    <LabelValue label="Medium" value={getMasterLabel("medium",studentDetails.medium)} />
                    <LabelValue label="House" value={getMasterLabel("house",studentDetails.house)} />
                    <LabelValue label="Admission No" value={studentDetails.admissionNo + "/" + moment(studentDetails.admissionYear).format("YY")} />
                    
                </div>

                <div className="col-md-6">
                    
                    <LabelValue label="Roll No" value={studentDetails.rollNo} />
                    <LabelValue label="UID" value={studentDetails.uid} />
                    <LabelValue label="Family Id" value={studentDetails.familyId} />
                    <LabelValue label="Student Reg No" value={studentDetails.regNo} />
                    <LabelValue label="Admission Date" value={moment(studentDetails.admissionDate).format("ll")} />
                    <LabelValue label="Birth Date" value={moment(studentDetails.dob).format("ll")} />
                </div>
            </div>
            
            <hr/>
            <div className='row'>
                <div className='col-md-12'>
                    <h3>Subjects</h3>
                </div>
                <div className='col-md-12'>
                    <StudentSubjects studentId={studentDetails.id} AppAlert={AppAlert} />
                </div>
            </div>
            
            <hr/>
            <div className="row">
                <div className="col-md-12">
                    <strong>Address</strong>
                </div>
                <div className="col-md-6">
                    {studentDetails.addressLine1}, {studentDetails.addressLine2}
                    <br/>
                    {getMasterLabel("city",studentDetails.city) }, {getMasterLabel("state",studentDetails.state) },{studentDetails.pincode} 
                </div>
                <div className="col-md-6">
                    <span className="fw-bold">Area : </span>
                    {getMasterLabel("area",studentDetails.area) } 
                </div>
            </div>

            <hr/>
            <div className="row">
                <div className="col-md-12">
                    <LabelValue label="Previous School Name : " value={studentDetails.lastSchoolName } />
                </div>
            </div>
            <hr/>

            <div className="row">
                <div className="col-md-6">
                    <LabelValue label="Height" value={studentDetails.height} />
                    <LabelValue label="Weight" value={studentDetails.weight} />
                    <LabelValue label="B. Group" value={getMasterLabel("blood-group",studentDetails.bloodGroup)} />
                    <LabelValue label="Religion" value={getMasterLabel("religion",studentDetails.religion)} />
                    <LabelValue label="Cast" value={getMasterLabel("caste",studentDetails.caste)} />
                </div>

                <div className="col-md-6">
                    <LabelValue label="Nationality" value={getMasterLabel("nationality",studentDetails.nationality)} />
                    <LabelValue label="Category" value={getMasterLabel("category",studentDetails.category)} />
                    <LabelValue label="Special Category" value={getMasterLabel("special-category",studentDetails.specialCategory)} />
                    <LabelValue label="Family Income" value={studentDetails.familyIncome} />
                </div>
            </div>
            
            
            

            <hr />
            <div className="row">
                <div className="col-md-12"><strong>Parent Details</strong></div>
                <div className="col-md-6">
                    <LabelValue label="Father. Name" value={studentDetails.fatherName} />
                    <LabelValue label="Father Aadhar" value={studentDetails.fatherUid} />
                    <LabelValue label="Father Pan" value={studentDetails.fatherPan} />
                    <LabelValue label="Occupation" value={getMasterLabel("occupation",studentDetails.fatherOccupation)} />
                    <LabelValue label="Qualification" value={getMasterLabel("qualification",studentDetails.fatherQualification)} />
                    <LabelValue label="Father Phone" value={studentDetails.fatherPhone} />
                </div>
                <div className="col-md-6">
                    <LabelValue label="Mother. Name" value={studentDetails.motherName} />
                    <LabelValue label="Mother Aadhar" value={studentDetails.motherUid} />
                    <LabelValue label="Mother Pan" value={studentDetails.motherPan} />
                    <LabelValue label="Occupation" value={getMasterLabel("occupation",studentDetails.motherOccupation)} />
                    <LabelValue label="Qualification" value={getMasterLabel("qualification",studentDetails.motherQualification)} />
                    <LabelValue label="Mother Phone" value={studentDetails.motherPhone} />
                </div>
            </div>
            
            


        </>
    )
}

export default StudentInformation