import api from "../../api/api";


const getByStudentId = (studentId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/student/report-card/"+studentId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const getReportMarksDetails = (studentReportId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/student/report-card/marks/"+studentReportId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const studentReportCardService = {
    getByStudentId,
    getReportMarksDetails
}
export default studentReportCardService;