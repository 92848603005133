import React,{useState,useEffect} from 'react'


/* Fromik */
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';

import transportFeesStructureServices from '../transportFeesStructureServices';



function TransportFeesStructureComponent({transportId, data, AppAlert,refreshList, masters}) {

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);
    

    const initialValues = {
        transportId:transportId,
        stoppage:"",
        pickupTime:"",
        dropTime:"",
        feesHead:10,
        feesTerm:"",
        feesMonth:"",
        amount:"",
        
    };



    const validationRule = Yup.object({
        stoppage : Yup.string().required("Required"),
        amount : Yup.string().required("Required"),
        
        
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            transportId:data.transportId,
            stoppage:data.stoppage,
            pickupTime:data.pickupTime,
            dropTime:data.dropTime,
            feesHead:data.feesHead,
            feesTerm:data.feesTerm,
            feesMonth:data.feesMonth,
            amount:data.amount,

        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }


    const save = (values) =>{
        setSavingStatus(true)
        transportFeesStructureServices.save(values).then(res =>{
            AppAlert(res.msg);
            refreshList();
            setDialogStatus(false);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const onSubmit = (values, onSubmitProps) => {
        if(values.id === undefined){
            let payloadList = [];
            const prepairPayloadList = (monthList) =>{
                monthList.forEach(month => {
                    let v  = {...values};
                    v.feesMonth = month;
                    payloadList.push(v);
                });
            }
            
            switch(values.feesTerm){
                case 2:
                    prepairPayloadList([4,10]);
                    break;
                case 3:
                    prepairPayloadList([4,7,10,1]);
                    break;
                case 4:
                    prepairPayloadList([4,5,6,7,8,9,10,11,12,1,2,3]);
                    break;
                default:
                    payloadList.push(values);
            }

            payloadList.map(payload=>{
                save(payload);
            })
        } else {
            save(values);
        }
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    return (
        <>
            {!data &&
                <Tooltip title="Add New">
                    <IconButton onClick={addNew} style={{padding:"0px"}}>
                        <Add sx={{ fontSize: 40 }} /> Add New
                    </IconButton>
                </Tooltip>
            }
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Fees Setting"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    



                                                    <FormikControl control="input" formik={formik} type="text" label="Stoppage" name="stoppage" className="col-4 p-2" />
                                                    <FormikControl control="input" formik={formik} type="text" label="Pickup Time" name="pickupTime" className="col-4 p-2" />
                                                    <FormikControl control="input" formik={formik} type="text" label="Drop Time" name="dropTime" className="col-4 p-2" />

                                                    {masters && 
                                                        <div className="col-4 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Payment Term" name="feesTerm"  
                                                                options={masters['fees-term']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-4 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Start Month" name="feesMonth"  
                                                                options={masters['month']} multiple={false} 
                                                            />
                                                        </div>
                                                    }


                                                    <FormikControl control="input" formik={formik} type="text" label="amount" name="amount" className="col-4 p-2" />

                                                    

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}

export default TransportFeesStructureComponent