import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';
import eventServices from '../eventServices';
import moment from 'moment';

function EventFormComponent({data, AppAlert,refreshList, masters}) {

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    /* Form Work  */
    const initialValues = {
        branch:"",
        class:"",
        section:"",
        eventStartDate:moment().format("YYYY-MM-DD"),
        eventEndDate:moment().format("YYYY-MM-DD"),
        title:"",
        publish:"0",
        details:"",
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        eventServices.save(values).then(res =>{
            AppAlert(res.msg);
            handleClose();
            refreshList();
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const saveAndPublish = (formik) => {
        formik.setFieldValue("publish","1");
        formik.handleSubmit();
    }
    const saveAsDraft = (formik) => {
        formik.setFieldValue("publish","0");
        formik.handleSubmit();
    }

    const validationRule = Yup.object({
        branch: Yup.string().required("Required"),
        class: Yup.string().required("Required"),
        section: Yup.string().required("Required"),
        eventStartDate: Yup.string().required("Required"),
        eventEndDate: Yup.string().required("Required"),
    });

    /* Form Work  */


    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            branch : data.branch,
            class : data.class,
            section: data.section,
            eventStartDate: data.eventStartDate,
            eventEndDate: data.eventEndDate,
            title: data.title,
            details: data.details,

        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }


    const handleClose  = () =>{
        setDialogStatus(false);
    }



    return (
        <>
            {!data &&
                <Tooltip title="Add New">
                    <IconButton onClick={addNew} style={{padding:"0px"}}>
                        <Add sx={{ fontSize: 40 }} /> Add New
                    </IconButton>
                </Tooltip>
            }
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Event"}</DialogTitle>
                                    <DialogContent>
                                    <div className="row p-2">
                                                {masters && 
                                                    <>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Branch" name="branch"  
                                                                options={masters['branch']} multiple={false} 
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Class" name="class"  
                                                                options={masters['class']} multiple={false} 
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Section" name="section"  
                                                                options={masters['section']} multiple={false} 
                                                            />
                                                        </div>
                                                        

                                                        <div className="col-md-6 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="date" 
                                                                label="Event Start Date" name="eventStartDate" 
                                                            />
                                                        </div>

                                                        <div className="col-md-6 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="date" 
                                                                label="Event End Date" name="eventEndDate" 
                                                            />
                                                        </div>

                                                        <hr className="mt-4" />
                                                        <div className="col-md-12 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Title" name="title" 
                                                            />
                                                        </div>
                                                        <div className="col-md-12 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text"
                                                                multiline rows={4}
                                                                label="Details" name="details" 
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-warning" type="button" onClick={()=>{saveAsDraft(formik)}}>Save as Draft</button>
                                            <button className="btn btn-success" type="button" onClick={()=>{saveAndPublish(formik)}}>Save & Publish</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}

export default EventFormComponent