import { CircularProgress, LinearProgress } from '@mui/material';
import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import ConfirmDialog from '../../../components/ConfirmDialog';
import withAlert from '../../../hoc/withAlert'
import useMasterList from '../../../hooks/useMasterList';
import useSessionList from '../../../hooks/useSessionList';
import AppLayout from '../../../layout/components/AppLayout';
import feesSettingServices from '../../settings/feesSettingServices';
import FeeStructureFilterComponent from '../components/FeeStructureFilterComponent';
import FeeStructureForm from '../components/FeeStructureForm';

function FeeStructurePage({AppAlert}) {

    const [feeStructureList,setFeeStructureList] = useState(null);
    const [sessionYearList] =  useSessionList();

    const [masters,getMasterLabel] = useMasterList(["branch","section", "month","fees-head","fees-term","fees-class-group",]);

    const [filters,setFilters] = useState(null);
    const setFiltersValues = (values) =>{
        setFilters(values);
    }


    const [searchInProgress,setSearchStatus] = useState(false);
    const getFeeStructureList = () =>{
        if(filters){
            setSearchStatus(true);
            feesSettingServices.filter(filters).then(res =>{
                setFeeStructureList(res.data);
            }).catch(error=>{
                AppAlert(error.msg,"error")
            }).finally(()=>{
                setSearchStatus(false);
            })
        }
        
    }

    useEffect(()=>{
        getFeeStructureList();
    },[filters])

    const remove = (id) =>{
        feesSettingServices.remove(id).then(res=>{
            AppAlert("Removed Successfully","error")
            getFeeStructureList();
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-4">
                    <div className="col-md-4">
                        <div className="card shadow">
                            <div className="card-header bg-primary text-white">Fee Structure</div>
                            <div className="card-body">
                                <FeeStructureFilterComponent masters={masters} setFiltersValues={setFiltersValues} sessionYearList={sessionYearList} />
                            </div>
                            <div className="card-footer">
                                <Link to="/settings/fees" className="btn btn-default float-end"  >List All </Link>
                                <Link to="/fee-structure/list" className="btn btn-default float-end"  >Detailed View </Link>
                            </div>
                        </div>
                    </div>
                    {filters && feeStructureList && 
                    <div className="col-md-8">
                        <div className="card shadow">
                            <div className="card-header bg-dark text-white d-flex justify-content-between"> 

                                    <span >
                                        Fee Structure for <span className="text-warning"> (Session : {filters.sessionYear}) </span>
                                                <span className="fw-bold"> {getMasterLabel("branch",filters.branch)}</span>
                                            <h5>
                                                <span className="fw-bold"> {getMasterLabel("fees-class-group",filters.feesClassGroup)} </span>
                                            </h5>
                                        
                                    </span>

                                    <span >
                                        {masters && filters && <FeeStructureForm masters={masters} AppAlert={AppAlert} refreshList={getFeeStructureList} filters={filters} /> }
                                    </span>
                            </div>
                            <div className="card-body">
                                {searchInProgress ? <LinearProgress />
                                    :
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <td>Month</td>
                                                <td>Fees Head</td>
                                                <td>Fees Term</td>
                                                <td>Fees Amount</td>
                                                <td>Action</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {feeStructureList.map(feeStructure =>
                                                <tr key={feeStructure.id}>
                                                    <td>{getMasterLabel("month",feeStructure.month)}</td>
                                                    <td>{getMasterLabel("fees-head",feeStructure.feesHead)}</td>
                                                    <td>{getMasterLabel("fees-term",feeStructure.feesTerm)}</td>
                                                    <td>{feeStructure.amount}</td>
                                                    <td>
                                                        <FeeStructureForm masters={masters} AppAlert={AppAlert} refreshList={getFeeStructureList} data={feeStructure} filters={filters} />
                                                        <ConfirmDialog id={feeStructure.id} confirm={remove} >Are you Sure You want to remove this fees</ConfirmDialog>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                }
                                
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(FeeStructurePage);