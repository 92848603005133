import React,{useState} from 'react'
import { ArrowBackIos, ArrowRight,Circle,CropDin,PanoramaFishEye,PhoneAndroid,Warning } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent,  IconButton, Tooltip } from "@mui/material";
import TextInline from '../../../components/InlineEdit/TextInline';
import teacherServices from '../teacherServices';

function TeacherAppPassword({teacherId,teacherPassword,teacherUID,AppAlert}) {

    const [dialogStatus, setDialogStatus] = useState(false);
    const [passwordShowStatus,setPasswordShowStatu] = useState(false);
    const handleClose = () =>{
        setDialogStatus(false)
    }

    return (
        <>
            <div className="list-group">
                <button type="button" className="list-group-item list-group-item-action d-flex justify-content-between align-items-center" onClick={()=>{setDialogStatus(true)}}>
                    App Details
                    <span className=""><ArrowRight/></span>
                </button>
            </div>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
            <DialogContent> 
                <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <div className="card bg-dark px-2" style={{minHeight:450}}>
                            <div className="card-header text-center bg-dark text-white">
                                <PhoneAndroid/> App Details
                            </div>
                            <div className="card-body bg-light">
                                <div className="card p-2" style={{marginTop:150}}>
                                    <p><strong>Username : </strong>T{teacherUID}</p>
                                    <p>
                                            {passwordShowStatus ? 
                                                    <>
                                                    <strong>Password : </strong>
                                                    <TextInline name="password" editId={teacherId} value={teacherPassword} updateInline={teacherServices.updateInline} />
                                                    </>
                                                :
                                                    <button type="button" className="btn btn-primary" onClick={()=>setPasswordShowStatu(true)} >Show Password</button>
                                            }
                                            
                                    </p>
                                </div>
                            </div>
                            <div className="card-footer bg-dark text-white">
                                <div className="d-flex justify-content-around">
                                    <CropDin/>
                                    <PanoramaFishEye/>
                                    <ArrowBackIos />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
                <DialogActions>
                    <button type="button" onClick={()=>{handleClose()} } className="btn btn-danger">Close</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TeacherAppPassword