import { LinearProgress, TextField } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import invoiceService from '../invoiceServices';


import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { useNavigate } from 'react-router';



const FeeSlip = ({studentId, masters, studentFeesStructure,getMasterLabel,AppAlert}) => {

    const [feesPerticular,setFeesPerticular] = useState([]);

    const navigate = useNavigate()

    const [total,setTotel] = useState(0);
    const [grandTotal,setGrandTotel] = useState(0);

    const [concession,setConcession] = useState(0);
    const [lateFees,setLateFees] = useState(0);
    const [previousDues,setPreviousDues] = useState(0);

    const [paidAmount,setPaidAmount] = useState(0);
    const [dues,setDues] = useState(0);

    const [seletedStudentFees,setSeletedStudentFees] = useState([]);


    const doTotal = () =>{
        let t = 0;
        let selected = [];
        studentFeesStructure.forEach(fees => {
            if(fees.selected ){
                t = t+fees.feesAmount;
                /*
                selected.push({
                    id : fees.id,
                    studentId:fees.studentId,
                })
                */
                selected.push(fees.id);
            }
        });
        setSeletedStudentFees(selected);
        setTotel(t);
    }

    const doGrandTotal = () =>{
        let t = 0;
        
        t = t + parseFloat(total) - parseFloat(concession) + parseFloat(previousDues) + parseFloat(lateFees);
        t = t.toFixed(2);
        setGrandTotel(t);
    }



    useEffect(()=>{
        doGrandTotal();
    },[total,concession,lateFees,previousDues])


    useEffect(()=>{
        let d = 0;
        d = parseFloat(grandTotal) - parseFloat(paidAmount);
        d = d.toFixed(2);
        setDues(d);
    },[grandTotal,paidAmount])


    useEffect(()=>{
        doTotal();
    },[studentFeesStructure])


    const getPreviousDues = () =>{
        invoiceService.getPreviousDues(studentId).then(res=>{
            setPreviousDues(res.dues);
        }).catch(error=>{
            AppAlert("No Previous Dues");
        })
    }

    useEffect(()=>{
        getPreviousDues();
    },[studentId])
    


    const handelNumberInputChange = (e,setValue) =>{
        if(e.target.value == ""){
            setValue(0)
        } else {
            
            let val = parseFloat(e.target.value); 
            let v  = val + 1;
            setValue(v)
            setTimeout(()=>{
                v =v-1;
                setValue(v)
            },0)
        }
    }


    const [saving,setSavingStatus] = useState(false);


    {/* Form Related Work */}

    const [formValues, setFormValues] = useState(null);
    
    

    const initialValues = {
        paymentType : 1,
        paymentDate : moment().format("YYYY-MM-DD"),
        transectionNo: "",
        remark:""
        
    };



    const validationRule = Yup.object({
        paymentType: Yup.string().required("Required"),
        paymentDate : Yup.string().required("Required"),
    });

    

    

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        setSavingStatus(true);
        let payload = {
            seletedStudentFees : seletedStudentFees,
            studentId:studentId,
            lateFees:lateFees,
            previousDues:previousDues,
            concession:concession,
            payableAmount:grandTotal,
            paidAmount:paidAmount,
            paymentType:values.paymentType,
            paymentDate:values.paymentDate,
            transectionNo:values.transectionNo,
            remark:values.remark,


        }
        console.log(payload);

        invoiceService.save(payload).then(res=>{
            AppAlert("Ready To Print")
            setTimeout(()=>{
                navigate("/invoice/receipt/"+res.id);
            },1000)
        }).catch(error=>{
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    

    {/*  FOrm Relared Work End */ }


    return (
        <>
            <div className="row">
                <div className="col-md-12 text-center fw-bold">Pay Slip </div>
                <div className="col-md-12 table-responsive"  >
                    {studentFeesStructure && 
                        <table className="table table-sm table-striped table-hover" style={{fontSize:14}}>                
                            <tbody>
                            {
                                studentFeesStructure.map(feesStructure=> {
                                    
                                    if(feesStructure.selected){
                                        return (
                                            <tr key={feesStructure.id}>
                                                <td>{getMasterLabel("fees-head",feesStructure.feesHead)}</td>
                                                <td>{getMasterLabel("month",feesStructure.paymentMonth)}</td>
                                                <th>{feesStructure.feesAmount}</th>
                                            </tr>
                                        )
                                    }
                                }
                                )
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2}>Total</td>
                                <th>{total}</th>
                            </tr>
                            <tr>
                                <td colSpan={2}>Previous Dues</td>
                                <th>{previousDues}</th>
                            </tr>
                            <tr>
                                <td colSpan={2}>Late Fees</td>
                                <th><input type="number" onChange={(e)=>handelNumberInputChange(e,setLateFees)} value={lateFees} /> </th>
                            </tr>
                            <tr>
                                <td colSpan={2}>Concession</td>
                                <th><input type="number" onChange={(e)=>handelNumberInputChange(e,setConcession)}  value={concession} /> </th>
                            </tr>
                            <tr>
                                <td colSpan={2}>Grand Total</td>
                                <th>{grandTotal}</th>
                            </tr>
                            <tr>
                                <td colSpan={2}>Amount Received</td>
                                <th><input type="number" onChange={(e)=>handelNumberInputChange(e,setPaidAmount)}  value={paidAmount} /></th>
                            </tr>
                            <tr>
                                <td colSpan={2}>Dues</td>
                                <th>{dues}</th>
                            </tr>

                        </tfoot>
                    </table>
                    }

                </div>
            </div>
            
                
            <Formik
                initialValues={initialValues}
                validationSchema={validationRule}
                onSubmit={onSubmit}
                enableReinitialize validateOnMount
                >
                {
                    (formik) => {
                        //console.log(formik.values);
                        return (
                            <Form>
                                <div className="row">
                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Payment Method" name="paymentType"  
                                                                options={masters['payment-type']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="date" 
                                                            label="Payment Date" name="paymentDate" 
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Transection #" name="transectionNo" 
                                                        />
                                                    </div>
                                </div>

                                <div className="row">
                                    
                                    <div className="col-md-6 col-xs-12 p-2" >
                                        <FormikControl control="input" formik={formik} type="text" 
                                            label="Remarks" name="remark" 
                                        />
                                    </div>
                                    <div className="col-md-6 col-xs-12 p-2" >
                                        {!saving &&
                                            <button disabled={paidAmount < 0 || paidAmount == ""} type="submit" className="btn btn-success">Generate Receipt </button>
                                        }
                                        {saving &&
                                            <LinearProgress/>
                                        }
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
            
            
        </>
    )
}

export default FeeSlip