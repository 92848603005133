import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import useMasterList from '../../hooks/useMasterList';
import AppLayout from '../../layout/components/AppLayout'
import ClassStudentChart from '../components/ClassStudentChart'
import DashboardCount from '../components/DashboardCount'
import dashboardServices from '../dashboardServices';
import StudentMessageComponent from '../../app/Communication/components/StudentMessageComponent'
import withAlert from '../../hoc/withAlert';

function Dashboard({AppAlert}) {

    const [masters,getMasterLabel] = useMasterList(['class','section', "salutation"])

    const [dashboardCounts,setDashboardCounts] = useState(null);
    const [classList,setClassList] = useState(null);
    const [birthdayList,setBirthdayList] = useState(null);
    const [communications,setCommunications] = useState(null);
    const getDashboardStats = () =>{
        dashboardServices.getStats().then(res=>{

            setDashboardCounts(res.counts);
            setClassList(res.classList);
            setBirthdayList(res.birthdays);
            setCommunications(res.communications)
        })
    }

    useEffect(()=>{
        getDashboardStats();
    },[])

    return (
        <AppLayout>
            <div className="container-fluid pt-2">
                <div className="row">
                    <div className="col-md-8">
                        {dashboardCounts &&  <DashboardCount counts={dashboardCounts} />} 
                        {classList &&  <ClassStudentChart classList={classList} />} 
                    </div>
                    <div className="col-md-4">

                        <div className="card mb-2">
                            <div className="card-header bg-success text-white">Today's Birthday</div>
                            <div className="card-body">
                                <h5>Students</h5>
                                <ul className="list-group">
                                    {masters && birthdayList && birthdayList.students.map(student=>
                                        <li className="list-group-item">
                                            <div className='d-flex justify-content-between'>

                                                <div>
                                                    <img style={{width:40,height:40}} className='rounded float-start' src={student.photo} />
                                                    <Link to={"/student/details/"+student.id} className='ms-2 mt-1 fs-5 text-dark fw-bold text-decoration-none float-start'>
                                                        {student.firstName} {student.lastName} ({getMasterLabel("class",student.class)}-{getMasterLabel("section",student.section)})
                                                    </Link>
                                                </div>
                                                <StudentMessageComponent studentId={student.id} AppAlert={AppAlert} />
                                            </div>
                                        </li>
                                    )}

                                    {!birthdayList || birthdayList.students.length === 0 &&
                                        <li>No Birthdays </li>
                                    }
                                </ul>
                                <h5>Teachers</h5>
                                <ul>
                                    {masters && birthdayList && birthdayList.teachers.map(teacher=>
                                        <li><Link to={"/teacher/details/"+teacher.id}>{getMasterLabel("salutation",teacher.salutation)} {teacher.firstName} {teacher.lastName}</Link></li>
                                    )}
                                    {!birthdayList || birthdayList.teachers.length === 0 &&
                                        <li>No Birthdays</li>
                                    }
                                </ul>
                            </div>
                        </div>

                        
                        <div className="card mb-2">
                            <div className="card-header ">Announcements</div>
                            <div className="card-body">
                                <ul>
                                    {communications && communications.announcements.map(announcement=>
                                        <li key={announcement.id}>
                                        {announcement.title} ({moment(announcement.updated_at).format("ll")})
                                        </li>
                                    )}
                                    {!communications || communications.announcements.length === 0 &&
                                        <li>No Announcements</li>
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className="card mb-2">
                            <div className="card-header ">News</div>
                            <div className="card-body">
                                <ul>
                                    {communications && communications.news.map(n=>
                                        <li key={n.id}>
                                            {n.title}  ({moment(n.updated_at).format("ll")})
                                        </li>
                                    )}
                                    {!communications || communications.news.length === 0 &&
                                        <li>No News</li>
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className="card mb-2">
                            <div className="card-header ">Events</div>
                            <div className="card-body">
                                <ul>
                                    {communications && communications.events.map(event=>
                                        <li key={event.id}>
                                            {event.title} ({moment(event.eventStartDate).format("ll")})
                                        </li>
                                    )}
                                    {!communications || communications.events.length === 0 &&
                                        <li>No News</li>
                                    }
                                </ul>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="row">



                    <div className="col-md-4">
                        <div className="card mt-2">
                            <div className="card-header bg-success text-white">Students</div>
                            <div className="card-body">
                                    <ul>
                                        <li><Link to={"/student/admission"} >Student Admission </Link></li>
                                        <li><Link to={"/student/list"} >Student List </Link></li>
                                    </ul>
                                    <hr/>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mt-2">
                            <div className="card-header bg-danger text-white">Teachers</div>
                            <div className="card-body">
                                    <ul>
                                        <li><Link to={"/teacher/form"} >New Teacher </Link></li>
                                        <li><Link to={"/teacher/list"} >Teacher List </Link></li>
                                    </ul>
                                    <hr/>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mt-2">
                            <div className="card-header bg-warning ">Transport</div>
                            <div className="card-body">
                                    <ul>
                                        <li><Link to={"/transport/list"} >Manage Transport </Link></li>
                                        <li><Link to={"/system-master/list#vehicle-type"} >Manage Vehicle Type </Link></li>
                                        
                                    </ul>
                                    <hr/>
                            </div>
                        </div>
                    </div>

                    

                    <div className="col-md-3">
                        <div className="card mt-2">
                            <div className="card-header bg-primary text-white">Attendance</div>
                            <div className="card-body">
                                    <ul>
                                        <li><Link to={"/attendance/student"} >Mark Attendance </Link></li>
                                        {/* <li><Link to={"/attendance/report/class-wise"} >Today's Attendance </Link></li> */}
                                    </ul>
                                    <hr/>
                            </div>
                        </div>
                    </div>

                    

                    <div className="col-md-4">
                        <div className="card mt-2">
                            <div className="card-header bg-dark text-white">Settings</div>
                            <div className="card-body">
                                    <ul>
                                        <li><Link to={"/settings/school"} >Manage School </Link></li>
                                        <li><Link to={"/system-master/list#class"} >Manage Class </Link></li>
                                        {/*<li><Link to={"/"} >Manage Financial Year </Link></li> */}
                                        <li><Link to={"/user/list"} >Manage User </Link></li>
                                    </ul>
                                    <hr/>
                                    <ul>
                                        <li><Link to={"/system-master/list#fees-head"} >Manage Fees Head </Link></li>
                                        <li><Link to={"/system-master/list#fees-term"} >Manage Fees Terms </Link></li>
                                        <li><Link to={"/fee-structure"} >Manage Fee Structure </Link></li>
                                        <li><Link to={"/fee-concession"} >Manage Concession </Link></li>
                                    </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mt-2">
                            <div className="card-header bg-info">Communication</div>
                            <div className="card-body">
                                    <ul>
                                        <li><Link to={"/news/list"} >Manage News</Link></li>
                                        <li><Link to={"/Message/list"} >Message</Link></li>
                                    </ul>
                                    <hr/>
                                    <ul>
                                        <li><Link to={"/home-work/list"} >Manage Homework</Link></li>
                                        <li><Link to={"/activity/list"} >Manage Activity</Link></li>
                                        <li><Link to={"/event/list"} >Manage Event</Link></li>
                                        <li><Link to={"/announcement/list"} >Manage Announcement</Link></li>
                                    </ul>
                                    <hr/>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="card mt-2">
                            <div className="card-header bg-secondary text-white">Manage Receipt </div>
                            <div className="card-body">
                                    <ul>
                                        <li><Link to={"/invoice/generate"} >Get Receipt </Link></li>
                                        <li><Link to={"/id-card/batch"} >ID Card Class wise </Link></li>
                                    </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </AppLayout>
    )
}

export default withAlert(Dashboard)