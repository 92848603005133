import { Cancel, CheckCircle, Pending, Print } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React,{useRef, useState} from 'react'
import { Link } from 'react-router-dom';
import useMasterList from '../../../hooks/useMasterList';
import AppLayout from '../../../layout/components/AppLayout'
import BranchClassFilter from '../components/BranchClassFilter';
import reportServices from '../reportServices';
import withAlert from '../../../hoc/withAlert';
import StudentMessageComponent from '../../Communication/components/StudentMessageComponent';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

function DefaulterList(AppAlert) {
    const [masters] = useMasterList(['branch','class',"feeshead"],"all");
    const [searchingInProgress, setSearchingStatus] = useState(false);
    

    const [studentReport,setStudentReport] = useState(null);
    const [paymentMonths,setPaymentMonths] = useState(null);

    const [defaulterMonth, setDefaulterMonth] = useState(null);

    const getList = (values) =>{
        console.log("values",values);
        setSearchingStatus(true);
        reportServices.dueReport(values).then(res=>{
            setStudentReport(res.report);
            setPaymentMonths(res.paymentMonths);
        }).finally(()=>
            setSearchingStatus(false)
        )
    }

    const onDefaulyerMonthChange = (month) =>{
        setDefaulterMonth(month)
    }

    /* Print Work Start */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    /* Print Work End */

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="card mt-3">
                    <div className="card-header">Defaulter List</div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-8">
                                <BranchClassFilter masters={masters} searchingInProgress={searchingInProgress} filterData={getList} />
                            </div>
                            <div className="col-md-4">
                                {studentReport && studentReport.length > 0 && 
                                    <button onClick={handlePrint} className="btn btn-primary mt-3 float-end" ><Print/> Print</button>
                                }
                            </div>
                        </div>
                        <div className="row-mt-3">
                            {paymentMonths && paymentMonths.length > 0 && paymentMonths.map((month=>
                                    <button key={month.monthTitle} type="button" className={ month.monthTitle === defaulterMonth ? "btn btn-dark m-2" : "btn btn-secondary m-2"} onClick={()=>setDefaulterMonth(month.monthTitle)}>{month.monthTitle}</button>
                                ))
                            }
                        </div>
                        <div className="row-mt-3">
                            <div className="col-md-12">
                                {defaulterMonth && studentReport && studentReport.length > 0 && 
                                    <table className="table table-striped table-sm table-bordered border-dark" ref={componentRef} style={{fontSize:10}}>
                                        <thead>
                                            <tr>
                                                <th className="border-dark" >#</th>
                                                <th className="border-dark table-info" >Student Name</th>
                                                <th className="border-dark table-info" >Father Name</th>
                                                <th className="border-dark table-info">Admission No</th>
                                                <th className="border-dark table-info">Class</th>
                                                
                                                <th colSpan="2" className={"table-primary text-center border-dark" }>{defaulterMonth}</th>
                                                
                                                <th colSpan="5" className="text-center bg-dark text-white">Collected</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {studentReport.map((student,i)=> 
                                            
                                            <>
                                                {
                                                    student["Total"+"_PaidAmount"] < student["Payable_Till_"+defaulterMonth] &&
                                            

                                                        <tr key={student.id}>
                                                            <td>{i+1}</td>
                                                            <td className="table-info border-dark"
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                    maxWidth: "80px",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                            >
                                                                <div className='d-flex justify-content-between'>
                                                                    <StudentMessageComponent studentId={student.id} AppAlert={AppAlert} />
                                                                    {student.studentName}
                                                                </div>
                                                            </td>
                                                            <td className="table-info border-dark"
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                    maxWidth: "80px",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                            >
                                                                {student.fatherName} <strong>({student.fatherPhone}) </strong>
                                                            </td>
                                                            <td className="table-info border-dark">
                                                                <Link className="text-dark border-dark" target="_blank" to={"/student/details/"+student.id}>{student.admissionNo}/{moment(student.admissionYear).format("YY")}</Link>
                                                            </td>
                                                            <td className="table-info border-dark">
                                                                {student.classTitle}-{student.sectionTitle}
                                                            </td>
                                                            
                                                                
                                                                <td className={"table-primary border-dark" }>
                                                                    <Link className="text-dark" target="_blank" to={"/student/fees-structure/"+student.id}>
                                                                        {/* student[defaulterMonth+"_Score"]} / {student[defaulterMonth+"_Total"] */} 
                                                                        {student[defaulterMonth+"_Payable"]} 
                                                                    </Link>
                                                                </td>


                                                                {/*
                                                                <td>
                                                                    {student[defaulterMonth+"_Score"] === 0 && <Cancel style={{ color: "#900" }} /> }
                                                                    {student[defaulterMonth+"_Score"] > 0 && student[defaulterMonth+"_Score"] < student[defaulterMonth+"_Total"] && <Pending style={{color:"#ffa500"}} /> }
                                                                    {student[defaulterMonth+"_Score"] === student[defaulterMonth+"_Total"] && <CheckCircle style={{ color: "#090" }} /> }
                                                                </td>
                                                                */}

                                                                <td>
                                                                    { /* student["Payable_Till_"+defaulterMonth] */}
                                                                    {student["Total"+"_PaidAmount"] >= student["Payable_Till_"+defaulterMonth] && <CheckCircle style={{ color: "#090" }} />}
                                                                    {student["Total"+"_PaidAmount"] < student["Payable_Till_"+defaulterMonth] &&
                                                                        <span className="text-danger">
                                                                            { student["Payable_Till_"+defaulterMonth] - student["Total"+"_PaidAmount"] }
                                                                        </span>
                                                                    }
                                                                </td>
                                                            
                                                            <td className="table-success border-dark"><Link className="text-dark" target="_blank" to={"/invoice/receipt/student/"+student.id}>{student["Total"+"_PaidAmount"]}</Link></td>
                                                        </tr>
                                                }
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(DefaulterList)