import React from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout';
import ComposeMessage from '../components/ComposeMessage';
import { ArrowRight } from '@mui/icons-material';
import { Link } from 'react-router-dom';

function MessageNavigation({AppAlert}) {
    return (
        <div className="list-group">
            <div className="list-group-item list-group-item-action d-flex justify-content-center align-items-center">
                <ComposeMessage AppAlert={AppAlert} />
            </div>
            <Link to={"/message/list"} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                Sent Messages
                <span className=""><ArrowRight/></span>
            </Link>
            <Link to={"/message/template"} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                Message Template
                <span className=""><ArrowRight/></span>
            </Link>
        </div>        
    )
}

export default MessageNavigation