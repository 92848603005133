import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router';
import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';
import StudentInformation from '../../student/components/StudentInformation';
import studentFeesStructureService from '../../student/studentFeesStructureServices';
import studentServices from '../../student/studentServices';
import FeeSlip from '../components/FeeSlip';
import StudentPendingList from '../components/StudentPendingList';

import useMasterList from '../../../hooks/useMasterList';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function GenerateInvoice({AppAlert}) {
    let query = useQuery();

    /* Master Details Start */
    const [masters,getMasterLabel] = useMasterList(["branch","gender","class","section", "blood-group", "fees-head","fees-term","month","payment-status", "payment-type"]);
            


    
    /* Student Details End */
    const [admissionNo,setAdmissionNo] = useState("");
    const [loading,setLoadingStatus] = useState(false);
    const [studentDetails,setStudentDetails] = useState(null);

    useEffect(()=>{
        if(query.get("admissionNo")){
            setAdmissionNo(query.get("admissionNo"));
            search(query.get("admissionNo"));
            
        }
    },[masters])

    const search = (admissionNumber) =>{
        setLoadingStatus(true)
        studentServices.getByAdmissionNo(admissionNumber).then(res=>{
            setStudentDetails(res.data);
            if(res.data===null){
                AppAlert("No Student Details Found","error");
            }
        }).catch(error=>{
            AppAlert(error.message,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }
    /* Student Details End */

    /* Student Fees Structure Start */
    const [studentFeesStructure,setStudentFeesStructure] = useState(null);
    const getStudentFeesStructure =  () =>{
        if(masters != null){
            studentFeesStructureService.get(studentDetails.id).then(res=>{
                let feesStructure = res.data.map(fees => {
                    fees['selected'] = false;
                    return fees;
                })
                setStudentFeesStructure(feesStructure);
            })
        }
    }

    useEffect(()=>{
        getStudentFeesStructure();
    },[studentDetails])

    const selectFees = (id) => {
        
        let feesStructure = studentFeesStructure.map(fees=>{
            if(fees.id === id && fees.paymentStatus === 1){
                fees.selected = !fees.selected;
            }
            return fees;
        })
        setStudentFeesStructure(feesStructure);
        
    }

    /* Student Fees Structure Details End */
    return (
        <AppLayout>
            <div className="container-fluid mt-3">

                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-group input-group mb-3">
                                <span className="input-group-text">Admission No.</span>
                                <input type="text" className="form-control" onChange={(e)=>setAdmissionNo(e.target.value)} value={admissionNo} />
                                <button type="button" className="btn btn-outline-secondary" onClick={ ()=>search(admissionNo)} >Search</button>
                            </div>
                        </div>
                        <div className="col-md-6 p-2">
                        {masters && studentDetails &&
                            <>
                            <span className="border p-2" ><span className="fw-bold text-primary">{studentDetails.firstName} {studentDetails.lastName} </span> </span>
                            <span className="border p-2" ><span className="fw-bold">F.Name </span> : <span>{studentDetails.fatherName}</span></span>
                            <span className="border p-2" ><span className="fw-bold">Class </span> : <span>{ getMasterLabel("class", studentDetails.class)} , { getMasterLabel("section", studentDetails.section)}, { getMasterLabel("branch", studentDetails.branch)}</span></span>
                            </>
                        }
                        </div>
                    </div>
                    <div className="row">
                        {/*
                        <div className="col-md-3 border shadow p-2">
                            {studentDetails &&<StudentInformation studentDetails={studentDetails} getMasterLabel={getMasterLabel} /> }
                        </div>
                        */
                        }
                        {masters &&
                        <>
                            <div className="col-md-4">
                                {studentFeesStructure && <StudentPendingList studentFeesStructure={studentFeesStructure} getMasterLabel={getMasterLabel} refreshList={getStudentFeesStructure} selectFees={selectFees} /> }
                            </div>
                            <div className="col-md-8">
                                {studentFeesStructure &&
                                    <FeeSlip masters={masters} studentId={studentDetails.id} studentFeesStructure={studentFeesStructure} getMasterLabel={getMasterLabel} refreshList={getStudentFeesStructure} selectFees={selectFees} AppAlert={AppAlert} />
                                }
                            </div>
                        </>
                        }
                    </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(GenerateInvoice);