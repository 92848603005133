import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';
import examReportCardExamListServices from '../examReportCardExamListServices';
import ExamReportCardExamListForm from './ExamReportCardExamListForm';

function ExamReportCardExamList({examReport,masters, getMasterLabel, AppAlert}) {
    
    const [examList,setExamList] = useState(null);
    const getExamList = () =>{
        examReportCardExamListServices.getByExamReportId(examReport.id).then(res=>{
            setExamList(res.data);
        })
    }

    useEffect(()=>{
        getExamList();
    },[]);

    return (
        <div className='row'>
            <div className='col-mad-12'>
            <ExamReportCardExamListForm AppAlert={AppAlert} examReport={examReport} refreshList={getExamList} masters={masters} />
            </div>
            <div className='col-md-12'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Exam</th>
                            <th>Ratio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {examList && examList.map(exam=>
                            <tr key={exam.id}>
                                <td>
                                { getMasterLabel("exam-type",exam.examType) }-{ exam.examNumber }-{ getMasterLabel("month",exam.examMonth) }
                                </td>
                                <td>{exam.ratio}</td>
                                <td><ExamReportCardExamListForm AppAlert={AppAlert} examReport={examReport} refreshList={getExamList} masters={masters} data={exam} /></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ExamReportCardExamList