import api from "../../api/api";

const getCombinationList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/exam/subject-combination/list/all").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getSubjectByStudentId = (studentId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/student/subjects/"+studentId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const assignSubjects = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/student/subjects/assign",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const assignSingleSubject = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/student/subjects/assign-single",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const remove = (id) => {
    return new Promise((resolve, reject)=>{
        api.delete("/student/subjects/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const studentSubjectServices = {
    getCombinationList,
    getSubjectByStudentId,
    assignSubjects,
    assignSingleSubject,
    remove,
}

export default studentSubjectServices;