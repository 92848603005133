import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';
import moment from 'moment';
import studentFeesStructureService from '../studentFeesStructureServices';

const StudentAddSingleFeestructure = ({studentDetails, masters, AppAlert,refreshList}) => {
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        studentId:studentDetails.id,
        branch:studentDetails.branch,
        class:studentDetails.class,
        feesHead : moment().format("YYYY-MM-DD"),
        feesTerm : "",
        amount:"",
        paymentMonth :"",
    };



    const validationRule = Yup.object({
        feesHead : Yup.string().required("Required"),
        feesTerm : Yup.string().required("Required"),
        paymentMonth:Yup.string().required("Required"),
        amount : Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        studentFeesStructureService.assignSingleFeesStructure(values).then(res =>{
            AppAlert(res.msg);
            refreshList();
            setDialogStatus(false);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    return (
        <>
            
            <Tooltip title="Add New">
                <button type="button" className="btn btn-primary" onClick={addNew} ><Add/> Assign Missing fees</button>
            </Tooltip>
            
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Holiday List"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            <div className="col-md-12">
                                                <div className="row">

                                                {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Fees Head" name="feesHead"  
                                                                options={masters['fees-head']} multiple={false} 
                                                            />
                                                        </div>
                                                    }


                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Fees terms" name="feesTerm"  
                                                                options={masters['fees-term']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Payment Month" name="paymentMonth"  
                                                                options={masters['month']} multiple={false} 
                                                            />
                                                        </div>
                                                    }


                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Amount" name="amount" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    
    )
}

export default StudentAddSingleFeestructure