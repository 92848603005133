import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import studentServices from '../studentServices';

function StudentAdmissionNoSuggestion({getMasterLabel}) {

    const [lastAdmissionDetails,setLastAdmissionDetails] = useState(null);
    const getLastAdmissionDetails = () =>{
        studentServices.getLastAdmissionDetails().then(res=>{
            setLastAdmissionDetails(res.data);
        })
    }

    useEffect(()=>{
        getLastAdmissionDetails()
    },[])

    return (
        lastAdmissionDetails &&
        <>
            <div className="d-flex justify-content-between">
                <strong>Last ADM No : </strong> <Link to={"/student/details/"+lastAdmissionDetails.id}>{lastAdmissionDetails.admissionNo}</Link>
            </div>
            <div className="d-flex justify-content-between">
                <Link to={"/student/details/"+lastAdmissionDetails.id}>{lastAdmissionDetails.firstName} {lastAdmissionDetails.lastName} ({getMasterLabel("class",lastAdmissionDetails.class)}-{getMasterLabel("section",lastAdmissionDetails.section)})</Link>
                {lastAdmissionDetails.createdByFirstName == null?
                    <span className=""> By System</span>
                    :
                    <span>By {lastAdmissionDetails.createdBy} {lastAdmissionDetails.createdByFirstName} {lastAdmissionDetails.createdByLastName}</span>
                }
            </div>
        </>
    )
}

export default StudentAdmissionNoSuggestion