import {useState,useEffect} from 'react'

function useSessionList() {
    const [sessionYearList,setSessionYearList] = useState([]);
    const prepareSessionYearList = ()=>{
        let currentYear = new Date().getFullYear();
        let yearList=[];
        for(let i=currentYear;i>=currentYear-15;i--){
            yearList.push({value:i,label:i.toString()})
        }
        setSessionYearList(yearList);
    }
    useEffect(()=>{
        prepareSessionYearList();
    },[])
    return [sessionYearList];
}

export default useSessionList