import React, { useEffect, useState } from 'react'

function StudentReportCoScholasticArea({masters,getMasterLabel,reportCardDetails,gradeList}) {


    const [studentAssessmentList,setStudentAssessmentList] = useState(null);
    const [selectedAssessmentList,setSelecetdAssessmentList] = useState(null);
    const getAssessmentList = () => {
        setStudentAssessmentList( JSON.parse(reportCardDetails.assessment));
        console.log("assessmentlist",reportCardDetails);
        setSelecetdAssessmentList(reportCardDetails.assessmentList?.split(","))
    }
    useEffect(()=>{
        getAssessmentList();
    },[reportCardDetails])

    const redableMaster = (masterName) =>{
        let m = masterName.replace("report-","");
        if(m === "discipline"){
            m = "Personal and social traits"
        }
        return m.toUpperCase();
    }

    const isObjectEmpty = (objectName) => {
        return Object.keys(objectName).length === 0
    }

    return (
        <div className='row'>
            {selectedAssessmentList && 
                <div className='col-12 text-center'>
                    <h4 className='fw-bold'><u>CO-SCHOLASTIC AREAS</u></h4>
                </div>
            }
            {selectedAssessmentList && selectedAssessmentList.map(selectedAssessment => 
                <div className='col-6' key={selectedAssessment}>
                    <table className='table table-bordered' style={{borderColor:"#000"}}>
                        <thead>
                            <tr>
                                <th className='text-center text-danger' colSpan={2}>{redableMaster(selectedAssessment)}</th>
                            </tr>
                        </thead>
                        
                        <tbody>                            
                            {
                                !isObjectEmpty(studentAssessmentList) 
                                && studentAssessmentList[selectedAssessment]?.length > 0 
                                && studentAssessmentList[selectedAssessment].map(assessment=>
                                    <tr key={assessment.assestmentId}>
                                        <td className='text-primary'>{getMasterLabel(selectedAssessment, assessment.assestmentId)}</td>
                                        <td className='fw-bold'>{getMasterLabel("assessment-grade", assessment.assessmentGrade)}</td>
                                    </tr>
                                )

                            
                            
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default StudentReportCoScholasticArea
