import React, { useState,useEffect } from 'react'
import ConfirmDialog from '../../../components/ConfirmDialog'
import AwsDocumentInline from '../../../components/InlineEdit/AwsDocumentInline'
import withAlert from '../../../hoc/withAlert'
import useMasterList from '../../../hooks/useMasterList'
import AppLayout from '../../../layout/components/AppLayout'
import SyllabusFilterComponent from '../components/SyllabusFilterComponent'
import SyllabusFormComponent from '../components/SyllabusFormComponent'
import syllabusServices from '../syllabusServices'

function SyllabusPage({AppAlert}) {

    const [masters,getMasterLabel] = useMasterList(["class","subject","medium"]);
    const [searchingInProgress, setSearchingStatus] = useState(false)

    const [filterValues,setFilterValues] = useState(null);

    const [syllabusList,setSyllabusList] = useState(null);

    const getSyllabusList = () => {
        if(filterValues){
            setSearchingStatus(true)
            syllabusServices.filter(filterValues).then(res=>{
                setSyllabusList(res.data);
            }).catch(e=>{
                AppAlert(e.msg,"error")
            }).finally(()=>{
                setSearchingStatus(false);
            })
        }
    }

    useEffect(()=>{
        getSyllabusList();
    },[filterValues])

    const remove = (id) =>{
        syllabusServices.remove(id).then(res=>{
            AppAlert("Removed Successfully","error")
            getSyllabusList()
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-4">
                    <div className="col-md-12">
                        <h3 className="ms-2"> Manage Syllabus</h3>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">
                                Syllabus
                            </div>
                            <div className="card-body">
                                {masters &&  <SyllabusFilterComponent masters={masters} onSearch={setFilterValues} searchingInProgress={searchingInProgress} /> }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">

                        <div className="row">
                            <div className="col-md-12">
                                
                                    {masters && syllabusList && 
                                        <div className ="card">
                                            <div className="card-header bg-dark text-white">
                                                <span className="float-start">
                                                    <div><strong>Medium : </strong>{getMasterLabel("medium",filterValues.medium)} </div>
                                                    <div><strong>Class : </strong>{getMasterLabel("class",filterValues.class)}</div>
                                                </span>
                                                <span className="float-end">
                                                    {masters && filterValues && <SyllabusFormComponent masters={masters} filterValues={filterValues} onSuccess={getSyllabusList} AppAlert={AppAlert} /> }
                                                </span>
                                            </div>
                                            <div className="card-body">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Subject</th>
                                                            <th>Title</th>
                                                            <th>Document</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {syllabusList.map(syllabus=>
                                                            <tr key={syllabus.id}>
                                                                <td>{getMasterLabel("subject",syllabus.subject)}</td>
                                                                <td>{syllabus.title}</td>
                                                                <td>
                                                                    <AwsDocumentInline
                                                                        label="Sylabus"
                                                                        name="syllabusUrl"
                                                                        value={syllabus.syllabusUrl}
                                                                        editId={syllabus.id}
                                                                        updateInline={syllabusServices.updateInline}
                                                                        AppAlert={AppAlert}
                                                                        module="syllabus"
                                                                        fileId={syllabus.title}
                                                                        onSuccess={getSyllabusList} 
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {masters && filterValues && 
                                                                        <SyllabusFormComponent 
                                                                            masters={masters} 
                                                                            filterValues={filterValues} 
                                                                            onSuccess={getSyllabusList} 
                                                                            AppAlert={AppAlert}
                                                                            data={syllabus}
                                                                        />
                                                                    }
                                                                    <ConfirmDialog id={syllabus.id} confirm={remove} >Are you Sure You want to remove this Syllabus</ConfirmDialog>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(SyllabusPage)