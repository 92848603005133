import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import examReportServices from '../examReportCardServices';

function ExamReportCardJuniorAssessmentList({examReport,AppAlert,refreshList}) {



    const [reportJuniorAssessmentList,setReportJuniorAssessmentList] = useState([]);
    const prepareReportAssessmentList = () =>{
        if(examReport.juniorAssessmentList !== null){
            let report_junior_assessment_list = examReport.juniorAssessmentList.split(",");
            setReportJuniorAssessmentList(report_junior_assessment_list);
        }
    }
    useEffect(()=>{
        prepareReportAssessmentList();
    },[examReport])



    const [assessmentFormList,setAssessmentFormList] = useState(null);
    const prepareAssessmentForm = () =>{
        
        let assessment_list = [
            {value: "report-junior-main", selected:reportJuniorAssessmentList.includes("report-junior-main")},
            {value: "report-junior-art", selected:reportJuniorAssessmentList.includes("report-junior-art")},
            {value: "report-junior-communication", selected:reportJuniorAssessmentList.includes("report-junior-communication")},
            {value: "report-junior-discipline",  selected:reportJuniorAssessmentList.includes("report-junior-discipline")},
        ]

        console.log("assessment list ",assessment_list);
        
        setAssessmentFormList(assessment_list);
    }

    useEffect(()=>{
        prepareAssessmentForm();
    },[reportJuniorAssessmentList]);

    const [dialogStatus,setDialogStatus] = useState(false);
    const handleClose = () =>{
        setDialogStatus(false);
    }


    const selectAssessment = (i) => {
        let assessment_form_list = [...assessmentFormList];
        assessment_form_list[i].selected = !assessment_form_list[i].selected;
        setAssessmentFormList(assessment_form_list);
    }


    const updateAssessmentList  = () =>{
        
        let juniorAssessmentList = [];

        assessmentFormList.forEach(assessmentForm => {
            if(assessmentForm.selected) {
                juniorAssessmentList.push(assessmentForm.value);
            }
        });
        console.log(juniorAssessmentList);
        
        examReportServices.save({id:examReport.id,juniorAssessmentList:juniorAssessmentList.toString()}).then(res=>{
            refreshList();
            AppAlert(res.msg);
            setDialogStatus(false)
        }).catch(error=>{
            console.log(error);
        })
        
    }

    const redableMaster = (masterName) =>{
        let m = masterName.replace("report-","");
        return m.toUpperCase();
    }

    return (
        <>
            
            {reportJuniorAssessmentList.length == 0 &&
                <button type='button' onClick={()=>setDialogStatus(true)} className='btn btn-primary m-1'>Add Assessment</button>
            }        
            {reportJuniorAssessmentList && reportJuniorAssessmentList.map(reportAssessment =>
                <button key={reportAssessment} type='button' onClick={()=>setDialogStatus(true)} className='btn btn-primary m-1'>{redableMaster(reportAssessment)}</button>
            )}
            

            
            <Dialog
                    open={dialogStatus}
                    keepMounted
                    onClose={handleClose}
                    fullWidth={true}
                    maxWidth="md"
                    aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Select Assessment</DialogTitle>
                <DialogContent>
                    <div className='row'>
                        <div className='col-md-12'>
                            <table className='table'>
                                <tbody>
                                    {assessmentFormList && assessmentFormList.map((assessment,i)=>
                                        <tr key={assessment.value} onClick={()=>{selectAssessment(i)}}>
                                            <td className={assessment.selected ? "table-success" : ""}>{redableMaster(assessment.value)} </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <button type='button' className='btn btn-primary' onClick={updateAssessmentList}>Save</button>
                </DialogActions>
            </Dialog>
              
    </>
  )
}

export default ExamReportCardJuniorAssessmentList