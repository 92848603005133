import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';
import feesSettingServices from '../../settings/feesSettingServices';



const FeeStructureForm = ({data, AppAlert,refreshList, masters, filters}) => {
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        sessionYear : filters.sessionYear,
        branch : filters.branch,
        feesClassGroup	 : filters.feesClassGroup,
        
        feesHead : "",
        feesTerm : "",
        month:"",
        amount : "",
    };



    const validationRule = Yup.object({
        branch: Yup.string().required("Required"),
        feesClassGroup: Yup.string().required("Required"),
        feesHead : Yup.string().required("Required"),
        feesTerm : Yup.string().required("Required"),
        month:Yup.string().required("Required"),
        amount : Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            sessionYear : filters.sessionYear,
            branch : filters.branch,
            feesClassGroup	 : filters.feesClassGroup,
            feesHead : data.feesHead,
            feesTerm : data.feesTerm,
            month:data.month,
            amount : data.amount,
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }


    const save = (values) =>{
        setSavingStatus(true)
        feesSettingServices.save(values).then(res =>{
            AppAlert(res.msg);
            refreshList();
            setDialogStatus(false);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const save1 = (values) =>{
        console.log(values);
    }
    

    const onSubmit = (values, onSubmitProps) => {
        if(values.id === undefined){
            let payloadList = [];
            const prepairPayloadList = (monthList) =>{
                monthList.forEach(month => {
                    let v  = {...values};
                    v.month = month;
                    payloadList.push(v);
                });
            }
            
            switch(values.feesTerm){
                case 2:
                    prepairPayloadList([4,10]);
                    break;
                case 3:
                    prepairPayloadList([4,7,10,1]);
                    break;
                case 4:
                    prepairPayloadList([4,5,6,7,8,9,10,11,12,1,2,3]);
                    break;
                default:
                    payloadList.push(values);
            }
    
            payloadList.map(payload=>{
                save(payload);
            })
        } else {
            save(values);
        }
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    return (
        <>
            {!data &&
                <Tooltip title="Add New">
                    <button className="btn btn-primary text-white" onClick={addNew} >
                        <Add /> Add New
                    </button>
                </Tooltip>
            }
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Fees Structure"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    
                                                    

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Fees Head" name="feesHead"  
                                                                options={masters['fees-head']} multiple={false} 
                                                            />
                                                        </div>
                                                    }


                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Fees terms" name="feesTerm"  
                                                                options={masters['fees-term']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Payment Month" name="month"  
                                                                options={masters['month']} multiple={false} 
                                                            />
                                                        </div>
                                                    }


                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Amount" name="amount" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    
    )
}

export default FeeStructureForm