import React,{useEffect, useState} from 'react'
import withAlert from '../../../hoc/withAlert'
import { useParams } from 'react-router';
import AppLayout from '../../../layout/components/AppLayout'

import systemMasterServices from '../../../master/systemMasterServices';
import StudentAttendanceFilter from '../components/StudentAttendanceFilter';
import AttendanceComponent from '../components/AttendanceComponent';
import moment from 'moment';
import { Link } from 'react-router-dom';



function StudentAttendance({AppAlert}) {
    let { id } = useParams();


    /* Start Masters Setting  */
    const [masters,setMasters] = useState(null);
    const [searchingInProgress, setSearchingStatus] = useState(false);

    const getMasterList = () => {
        systemMasterServices.get([
            "branch","class","section","subject"
        ]).then(res=>{
            setMasters(res);
        })
    }
    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }
    useEffect(()=>{
        getMasterList();
    },[])
    /* End Masters Setting  */

    const [studentList,setStudentList] =  useState(null);
    const [existing,setExisting] =  useState(null);
    const [filterValues,setFilterValues] = useState(null);

    return (
        <AppLayout>
            <div className="container-fluid mt-3">
                <StudentAttendanceFilter  setStudentList={setStudentList} masters={masters} setExisting={setExisting} setFilterValues={setFilterValues} />
                {studentList &&
                    <AttendanceComponent studentList={studentList} setStudentList={setStudentList} AppAlert={AppAlert} filterValues={filterValues} />
                }

                {existing &&
                    <div className="row">
                        <div className="col-12">
                            Attendance already marked for 
                        </div>
                        <div className="col-md-6">
                            <strong>Branch: </strong> {getMasterLabel("branch",existing.branch)},
                            <strong>Classs: </strong> {getMasterLabel("class",existing.class)},
                            <strong>Section:</strong> {getMasterLabel("section",existing.section)}
                        </div>
                        <div className="col-md-6"><strong>AT:</strong> { moment(existing.attendanceDate).format("llll")}</div>
                        <div className="col-md-12">Cick <Link to={"/attendance/batch/"+existing.id}> here </Link> to edit attendance </div>
                    </div>
                    

                    
                }
            </div>
        </AppLayout>
    )
}

export default withAlert(StudentAttendance);