import { AirportShuttle, AirportShuttleTwoTone, Groups, Man, Portrait, School, Woman } from '@mui/icons-material'
import React from 'react'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


function DashboardCount({counts}) {

    return (
        <div className="row">
            <div className="col-md-4 p-2">
                <div className="card">
                    <div className="card-body ">
                        <div className="d-flex justify-content-between">
                            <Groups style={{fontSize:70}}  />
                            <span>
                                <h2>{counts.totalStudent}</h2>
                                <h5>Students</h5>
                            </span>
                        </div>
                        <div className="row bg-white rounded">
                            <Pie data={{
                                    labels: ['Male', 'Female'],
                                    datasets: [
                                        {
                                            label: counts.totalStudent +" Students",
                                            data: [counts.totalMaleStudent, counts.totalFemaleStudent],
                                            backgroundColor: [
                                                "#0d6efd",
                                                "#d63384",
                                            ],
                                            borderWidth: 1,
                                        },
                                    ],
                                }} 
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-md-4 p-2">
                <div className="card">
                    <div className="card-body ">
                        <div className="d-flex justify-content-between ">
                            <School style={{fontSize:70}} />
                            <span>
                                <h2>{counts.totalTeacher}</h2>
                                <h5>Teachers</h5>
                            </span>
                        </div>
                        <div className="row bg-white rounded">
                            <Pie data={{
                                    labels: ['Male', 'Female'],
                                    datasets: [
                                        {
                                            label: counts.totalTeacher +" Teachers",
                                            data: [counts.totalMaleTeacher, counts.totalFemaleTeacher],
                                            backgroundColor: [
                                                "#0d6efd",
                                                "#d63384",
                                            ],
                                            borderWidth: 1,
                                        },
                                    ],
                                }} 
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-md-4 p-2">
                <div className="card">
                    <div className="card-body ">
                        <div className="d-flex justify-content-between ">
                            <AirportShuttleTwoTone style={{fontSize:70}} />
                            <span>
                                <h2>{counts.totalTransport}</h2>
                                <h5>Transport</h5>
                            </span>
                        </div>
                    </div>
                </div>
            </div>



            


        </div>
    )
}

export default DashboardCount