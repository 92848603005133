import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import useMasterList from '../../../hooks/useMasterList'
import AppLayout from '../../../layout/components/AppLayout';
import reportServices from '../reportServices';

function ClassTeacherReport() {


    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [masters,getMasterLabel] = useMasterList(['class',"section","subject","salutation","department","designation"])
    const getSubjectLabel = (subject) => subject == 0 ? "Class Teacher" : getMasterLabel("subject",subject);

    const [classTeacherList,setClassTeacherList]    =   useState(null)
    const getClassTeacherReport = () =>{
        reportServices.getClassTeacherReport().then(res=>{
            setClassTeacherList(res.data);
        })
    }
    useEffect(()=>{
        getClassTeacherReport();
    },[])

    return (
        <div>
            <AppLayout>
                <div className="container-fluid">
                    {classTeacherList && 
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <h3>Class Teacher List </h3>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="btn btn-warning float-end" onClick={handlePrint} >Print</button>
                            </div>
                            <div className="col-md-12 table-responsive" ref={componentRef}>
                                <table className="table table-bordered table-sm table-striped" style={{fontSize:12}} >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Teacher Name</th>
                                            <th>Designation</th>
                                            <th>Teacher Id</th>
                                            <th>Class Teacher</th>
                                            <th>Assigned Subject</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {classTeacherList.map((teacher,i)=>
                                            <tr key={teacher.id}>
                                                <td>{i+1}</td>
                                                <td><strong>{teacher.firstName} {teacher.lastName}</strong></td>
                                                <td><strong>{getMasterLabel("designation",teacher.designation)}</strong></td>
                                                <td>{teacher.teacherUID}</td>
                                                <td>
                                                    {teacher.classTeacher.length > 0 && teacher.classTeacher.map(assignedClass=>
                                                        <span className="badge rounded-pill bg-success me-2" style={{fontSize:12}}>{getMasterLabel("class",assignedClass.class)}-{getMasterLabel("section",assignedClass.section)}</span>
                                                    )}
                                                </td>
                                                <td>
                                                    {teacher.subjectTeacher.length > 0 && teacher.subjectTeacher.map(assignedClass=>
                                                        <span className="badge bg-primary m-1" style={{fontSize:12}}>{getMasterLabel("class",assignedClass.class)} {getMasterLabel("section",assignedClass.section)} {getSubjectLabel(assignedClass.subject)}</span>
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </AppLayout>

            

        </div>
    )
}

export default ClassTeacherReport