import React, { useState,useEffect } from 'react'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { CircularProgress } from '@mui/material';


function FeeStructureFilterComponent({setFiltersValues, masters, sessionYearList}) {
    const [searchingInProgress, setSearchingStatus] = useState(false);

    const initialValues = {
        sessionYear:"",
        branch:"",
        feesClassGroup:"",
    }

    const onSubmit = (values, onSubmitProps) => {
        setFiltersValues(values);
    }

    const validationRule = Yup.object({
        sessionYear: Yup.string().required("Required"),
        branch: Yup.string().required("Required"),
        feesClassGroup: Yup.string().required("Required"),
        
    });


  return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationRule}
            onSubmit={onSubmit}
            enableReinitialize validateOnMount
        >
            {
                (formik) => {
                    //console.log(formik.values);
                    return (
                        <Form>
                            <div className="row p-2">
                                {masters && 
                                        <>

                                            <div className="col-12 p-2" >
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Session" name="sessionYear"  
                                                    options={sessionYearList} multiple={false} 
                                                />
                                            </div>

                                            <div className="col-12 p-2" >
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Branch" name="branch"  
                                                    options={masters['branch']} multiple={false} 
                                                />
                                            </div>

                                            <div className="col-12 p-2" >
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Fees Class Group" name="feesClassGroup"  
                                                    options={masters['fees-class-group']} multiple={false} 
                                                />
                                            </div>

                                        </>
                                }
                                <div className="col-12 p-2" >
                                {searchingInProgress ? 
                                    <CircularProgress/> 
                                    :
                                    <button className="btn btn-success float-end" type="submit">Search</button>
                                }
                                </div>
                            </div>
                        </Form>
                    )
                }
            }
        </Formik>
    )
}

export default FeeStructureFilterComponent