import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  } from "chart.js";
import { Bar } from "react-chartjs-2";
import { ChartBackGroundColors } from "../../services/ChartColors.";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);



const options = {
    responsive: true,
    //indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
        legend: {
        position: "left",
        display: false
        },
    },
    scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
};




function ClassStudentChart({classList}) {
    
    let dataLabels = [];
    let datasets = [];
    let groupLabels = ["male","female"];

    classList.forEach((classDetails,i) => {
        dataLabels.push(classDetails.title);
    });

    groupLabels.forEach((label,i)=>{
        
        datasets = [
            {
                label:"Boys",
                data: classList.map(classDetails=>classDetails.report["male"]),
                backgroundColor:ChartBackGroundColors[0],
            },
            {
                label:"Girls",
                data: classList.map(classDetails=>classDetails.report["female"]),
                backgroundColor:ChartBackGroundColors[8],
            },
        ]
    })

    console.log("datasets",datasets);

    const data = {
        labels:dataLabels,
        datasets:datasets
    }


    return (
        <div className="card">
            <div className="card-header">Student Class Wise Report</div>
            <div className="card-body" style={{minHeight:400}}><Bar  options={options} data={data} /></div>
        </div>
        
    )
}

export default ClassStudentChart