import React,{useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import receiptService from '../receiptServices';

function CollectionFilter({masters,userDD,setFilters,setCollectionReport}) {

    const [searchingInProgress, setSearchingStatus] = useState(false);

    const initialValues = {
        startDate:moment().format("YYYY-MM-DD"),
        endDate:moment().format("YYYY-MM-DD"),
        receivedBy:0,
        paymentType:0,
        class:0,
        //section:""
    }

    const onSubmit = (values, onSubmitProps) => {
        setFilters(values);
        setCollectionReport([]);
        receiptService.getCollectionReport(values).then(res=>{
            setCollectionReport(res.data);
        })

    }

    const validationRule = Yup.object({
        //startDate: Yup.string().required("Required"),
        //endDate:Yup.string().required("Required"),
        //receiverId:Yup.string().required("Required"),
        //paymentType:Yup.string().required("Required"),
        //class:Yup.string().required("Required"),
        //section:Yup.string().required("Required"),
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationRule}
            onSubmit={onSubmit}
            enableReinitialize validateOnMount
        >
            {
                (formik) => {
                    //console.log(formik.values);
                    return (
                        <Form>
                            <div className="row p-2">
                                {masters &&  userDD && 
                                        <>
                                            
                                            <div className="col-md-6 col-xs-12 p-2" >
                                                <FormikControl control="input" type="date" formik={formik} 
                                                    label="Start Date" name="startDate"  
                                                />
                                            </div>

                                            <div className="col-md-6 col-xs-12 p-2" >
                                                <FormikControl control="input" type="date" formik={formik} 
                                                    label="End Date" name="endDate"  
                                                />
                                            </div>

                                            
                                            <div className="col-md-3 col-xs-12 p-2" >
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Receiver" name="receivedBy"  
                                                    options={userDD} multiple={false} 
                                                />
                                            </div>
                                            
                                            <div className="col-md-3 col-xs-12 p-2" >
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Payment Type" name="paymentType"  
                                                    options={masters['payment-type']} multiple={false} 
                                                />
                                            </div>
                                            
                                            <div className="col-md-3 col-xs-12 p-2" >
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Class" name="class"  
                                                    options={masters['class']} multiple={false} 
                                                />
                                            </div>
                                            {/*
                                            <div className="col-md-3 col-xs-12 p-2" >
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Section" name="section"  
                                                    options={masters['section']} multiple={false} 
                                                />
                                            </div>
                                            */}
                                        </>
                                }
                                <div className="col-md-3 col-xs-12 p-2" >
                                {searchingInProgress ? 
                                    <CircularProgress/> 
                                    :
                                    <button className="btn btn-success" type="submit">Search</button>
                                }
                                </div>
                            </div>
                        </Form>
                    )
                }
            }
        </Formik>
    )
}

export default CollectionFilter