import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import ProfileImageInlineAws from '../../../components/InlineEdit/ProfileImageInlineAws';
import withAlert from '../../../hoc/withAlert';
import useMasterList from '../../../hooks/useMasterList';
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';
import TeacherInformation from '../components/TeacherInformation';
import teacherServices from '../teacherServices';
import TeacherAppPassword from '../components/TeacherAppPassword';
import TearcherLeaveBalance from '../components/TearcherLeaveBalance';

function TeacherDetailsPage({AppAlert}) {
    let { id } = useParams();

    {/*  Master Details Start */}

    const [masters,getMasterLabel] = useMasterList(["branch", "city","category","job-contract-type", "area", "gender","department","designation","salutation","marital-status","teacher-group","subject", "state","blood-group","religion","caste","nationality","qualification","relation-type"])
    
    useEffect(()=>{
        getTeacherDetails();
    },[])
    {/*  Master Details Start */}



    const [teacherDetails,setTeacherDetails]    = useState(null);
    const [loading, setLoadingStatus]           = useState(false);

    const getTeacherDetails = () =>{
        setLoadingStatus(true);
        teacherServices.getById(id).then(res=>{
            setTeacherDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    
    

    return (
        <AppLayout>
            <div className="container-fluid mt-3">
                {teacherDetails && 
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <ProfileImageInlineAws 
                                    editId={teacherDetails.id} 
                                    label={"Photo"} 
                                    name="photo" 
                                    value={teacherDetails.photo}
                                    fileId={teacherDetails.id}
                                    updateInline={teacherServices.updateInline}  
                                    module="teacher"
                                />
                            </div>
                            <TeacherAppPassword teacherId={teacherDetails.id} teacherPassword={teacherDetails.password} teacherUID={teacherDetails.teacherUID} />
                            <TearcherLeaveBalance teacherDetails={teacherDetails} />
                        </div>
                        <div className="col-md-8">
                            
                            <TeacherInformation teacherDetails={teacherDetails} masters={masters} getMasterLabel={getMasterLabel} AppAlert={AppAlert} refreshData={getTeacherDetails} />
                        </div>
                    </div>
                }
                
            </div>
        </AppLayout>
    )
}

export default withAlert(TeacherDetailsPage)