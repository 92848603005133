import { Edit } from '@mui/icons-material'
import React,{useState,useEffect} from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import receiptService from '../receiptServices';


function EditReceipt({masters,receiptDetails,onSuccess, AppAlert}) {

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        paymentType:receiptDetails.paymentType,
        transectionNo:receiptDetails.transectionNo
    };



    const validationRule = Yup.object({
        //holidayDate: Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:receiptDetails.id,
            paymentType:receiptDetails.paymentType,
            transectionNo:receiptDetails.transectionNo
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        setSavingStatus(true)
        receiptService.updateReceipt(values).then(res =>{
            AppAlert(res.msg);
            onSuccess();
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setDialogStatus(false);
            setSavingStatus(false);
        })
        
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    return (
        <>
            <button className="btn btn-warning me-2" onClick={updateData} ><Edit/> Edit Receipt!</button>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Edit Receipt"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            <div className="col-md-12">
                                                <div className="row">

                                                    {masters && 
                                                        <div className="col-md-6 col-xs-12" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Payment Type" name="paymentType"  
                                                                options={masters['payment-type']} multiple={false} 
                                                            />
                                                        </div>
                                                    }
                                                    <div className="col-md-6 col-xs-6" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Transection #" name="transectionNo" 
                                                            className=""
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}

export default EditReceipt