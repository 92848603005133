import moment from 'moment/moment'
import React from 'react'

function ReportCardFooter({reportCardDetails}) {
  return (
    <>
        <div className='row'>
            <div className='col-md-8'>
                <span className='fw-bold'>Remarks : </span>{reportCardDetails.teacherRemark}
            </div>
            <div className='col-md-4'>
                <span className='fw-bold'>Attendance : </span> {reportCardDetails.studentAttendance}
            </div>
        </div>
        <div className='row pt-3'>
            <div className='col-md-12'>
               <span className='fw-bold'>Date : </span> <span>October 7, 2023</span>  { /*  moment().format("LL") */}
            </div>
        </div>
        <div className='row pt-5'>
            <div className='col-md-12'>
                <div className='d-flex justify-content-around fw-bold'>
                    <p>Class Teacher</p>
                    <p>Examination Incharge</p>
                    <p>Principal</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default ReportCardFooter