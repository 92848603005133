import React, { useCallback, useEffect, useRef, useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';
import teacherServices from '../teacherServices';


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import moment from 'moment';
import { FileDownload } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import useMasterList from '../../../hooks/useMasterList';



const CellLink = (props) =>{
    return (
        <>
            {props && <Link to={`/teacher/details/${props.data.id}`}>{props.data.id }</Link> }
        </>
    )
}


function TeacherListPage() {

    const [teacherList,setTeacherList] = useState([]);
    const gridRef = useRef();

    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
    })

    const [columnDefs,setColumnDefs] = useState([]);


    const setupColDef = () =>{
        setColumnDefs([
            {field:"id", cellRenderer: CellLink},
            {field:"firstName"},
            {field:"lastName"},
            {field:"branch", valueGetter: params => getMasterLabel("branch",params.data.branch)},
            {field:"city"},
            {field:"state",valueGetter: params => getMasterLabel("state",params.data.state)},
            {field:"pincode"},
            {field:"dob", valueGetter: params => moment(params.data.dob).format("DD-MMM-YYYY")},
            {field:"doj", valueGetter: params => moment(params.data.doj).format("DD-MMM-YYYY")},
            {field:"bloodGroup",valueGetter: params => getMasterLabel("blood-group",params.data.bloodGroup)},
            {field:"caste",valueGetter: params => getMasterLabel("caste",params.data.caste)},
            {field:"religion",valueGetter: params => getMasterLabel("religion",params.data.religion)},
            {field:"nationality",valueGetter: params => getMasterLabel("nationality",params.data.nationality)},

        ]);
    }

    const onGridReady =(params)=>{
        getTeacherList()
    }



    const getTeacherList = () =>{
        teacherServices.get().then(res=>{
            setTeacherList(res.data);
            setupColDef()
        }).catch(error=>{
            setTeacherList([]);
        })
    }


    const [masters,getMasterLabel] = useMasterList(["branch","gender","department","designation","salutation","marital-status","teacher-group","subject", "state","blood-group","religion","caste","nationality","qualification"])


    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    /* Get Master Details Start */

    return (
        <AppLayout>
            <div className="container-fluid mt-3">
                {masters && 
                    <div className="row">
                        <div className="col-12 mb-2">
                            <button type="button" onClick={onBtnExport} className="btn btn-primary float-end"> <FileDownload/> Export</button>
                        </div>
                        
                            <div className="ag-theme-balham" style={{height: 550}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={teacherList}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    onGridReady={onGridReady}
                                />
                                
                            </div>
                        
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default TeacherListPage