import moment from 'moment';
import React, { useEffect, useState } from 'react'
import ConfirmDialog from '../../../components/ConfirmDialog';
import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout'
import HolidayCalendarForm from '../components/HolidayCalendarForm';
import holidayCalendarServices from '../holidayCalendarServices';

function HolidayCalendarPage({AppAlert}) {
    
    const [holidayList,setHolidayList]      = useState(null);
    const [loading,setLoadingStatus]        = useState(false);

    const getHolidayList = () => {
        setLoadingStatus(true);
        holidayCalendarServices.getList().then(res=>{
            setHolidayList(res.data);
        }).catch(error=>{
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getHolidayList()
    },[])


    const remove = (id) =>{
        holidayCalendarServices.remove(id).then(res=>{
            AppAlert("Removed Successfully","error")
            getHolidayList();
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }
    
    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-12">
                        <span className="float-end"><HolidayCalendarForm AppAlert={AppAlert} refreshList={getHolidayList} /></span>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-12">
                        {holidayList && holidayList.length > 0 &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Holiday Date</th>
                                        <th>Occasion</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {holidayList.map(holiday=>
                                        <tr>
                                            <td>{moment(holiday.holidayDate).format("LL")}</td>
                                            <td>{holiday.occasion}</td>
                                            <td>
                                                <HolidayCalendarForm AppAlert={AppAlert} refreshList={getHolidayList} data={holiday} />
                                                <ConfirmDialog id={holiday.id} confirm={remove} >Are you Sure You want to remove this holiday</ConfirmDialog>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                        {!holidayList || holidayList.length == 0 &&
                            <h4 >No holiday declared click on "Add New" button to declare new holiday</h4>
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
        
    )
}

export default withAlert(HolidayCalendarPage)