import React, { useState } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, IconButton } from '@mui/material'


export default function ExpandableCard({title,children,defaultOpen=true}) {
    const [expandStatus,setExpandStatus] = useState(defaultOpen);

    return (
        <>
            <div className="card mt-2">
                <div className="card-header" onClick={()=>setExpandStatus(state => !state)}>
                    <span className="float-start"> {title} </span>
                    <span className="float-end">                         
                            <IconButton className="p-0">
                                {expandStatus ? <ExpandLess />  : <ExpandMore /> }
                            </IconButton>
                    </span>
                </div>
                <Collapse in={expandStatus} timeout="auto" unmountOnExit >
                        {children}
                </Collapse>
            </div>
        </>
    
  )
}
