import React from 'react'
import ReportCardFooter from '../components/ReportCardFooter'
import StudentReportGradeSection from '../components/StudentReportGradeSection'
import ReportCardHeaderTypeTwo from './ReportCardHeaderTypeTwo'
import MarksDetailsTypeTwo from './MarksDetailsTypeTwo'
import StudentReportAdditionalSubject from './StudentReportAdditionalSubject'
import StudentReportCoScholasticArea from './StudentReportCoScholasticArea'
import ReportCardFooterTypeTwo from './ReportCardFooterTypeTwo'
import TeacherReamrkTypeTwo from './TeacherReamrkTypeTwo'

function StudentReportTypeTwoPrintComponent({studentDetails,reportCardDetails,masters,getMasterLabel,gradeList,schoolDetails}) {
  return (
        
            <>
                { studentDetails && schoolDetails &&
                    <ReportCardHeaderTypeTwo
                        studentDeatils={studentDetails}
                        schoolDetails={schoolDetails}
                        getMasterLabel={getMasterLabel}
                    />
                }
                
                {reportCardDetails &&            
                    <MarksDetailsTypeTwo
                        masters={masters}
                        getMasterLabel={getMasterLabel}
                        reportCardDetails={reportCardDetails}
                        gradeList={gradeList}
                    />    
                }

                {reportCardDetails &&
                    <StudentReportAdditionalSubject
                        masters={masters}
                        getMasterLabel={getMasterLabel}
                        reportCardDetails={reportCardDetails}
                        gradeList={gradeList}
                    />
                }

                {reportCardDetails &&
                    <TeacherReamrkTypeTwo
                        masters={masters}
                        getMasterLabel={getMasterLabel}
                        reportCardDetails={reportCardDetails}
                        gradeList={gradeList}
                    />
                }

                {reportCardDetails &&
                    <StudentReportCoScholasticArea
                        masters={masters}
                        getMasterLabel={getMasterLabel}
                        reportCardDetails={reportCardDetails}
                        gradeList={gradeList}
                    />
                }

                {reportCardDetails &&
                    <ReportCardFooterTypeTwo
                        masters={masters}
                        getMasterLabel={getMasterLabel}
                        reportCardDetails={reportCardDetails}
                        gradeList={gradeList}
                    />
                }

                <div className='pagebreak'></div>

            </>
        
       
  )
}

export default StudentReportTypeTwoPrintComponent