import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import systemMasterServices from '../../../master/systemMasterServices';

import QRCode from "react-qr-code";
import idCardServices from '../idCardServices';
import useMasterList from '../../../hooks/useMasterList';
import { Phone } from '@mui/icons-material';
import moment from 'moment';


function IDCardComponent({studentList}) {

    const [schoolDetails,setSchoolDetails] = useState(null)
    const getSchoolDetails = () =>{
        idCardServices.getScgoolDetails(1).then(res=>{
            setSchoolDetails(res.data);
        })
    }

    const [masters,getMasterLabel] = useMasterList(["branch","gender","class","section", "house","city", "state","blood-group","religion","caste","nationality","occupation","qualification"]);

   
    useEffect(()=>{
        getSchoolDetails();
    },[])

 
   
   const getQrDetais = (studentDetails) =>{
    
        let data = {
            studentId: studentDetails.id,
            admissionNo: studentDetails.admissionNo,
            studentName: `${studentDetails.firstName} ${studentDetails.lastName}`,
            branch : studentDetails.branch,
            class: studentDetails.class,
            section : studentDetails.section,
            rollNo : studentDetails.rollNo,
        }
        return JSON.stringify(data);

   }
    
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getPageMargins = () => {
        return `@page { 
                    size:2.125in 3.370in; 
                }
                @media print {
                    html, body {
                      margin: 0 !important;
                      padding: 0 !important;
                      overflow: hidden;
                    }
                  }
                `;
    }

    return (
        
        <div className="row">
            <div className="col-md-12">
                <span className="float-end">
                    <button type="button" className="btn btn-primary" onClick={handlePrint} >Print</button>
                </span>
            </div>
            <div className="d-flex justify-content-center" style={{fontSize:12}} >
                <div ref={componentRef} >
                    <style>{getPageMargins()}</style>
                    {studentList.map(student=>
                        
                        <>
                            {masters &&  <>
                            <div style={{minWidth:"2.125in", maxWidth:"2.125in",minHeight:"3.370in", maxHeight:"3.370in" }} className="border">
                                <div className="row">
                                    <div className="col-md-12 mt-2 mb-1 text-center">
                                        {schoolDetails && 
                                            <h6>
                                                {schoolDetails.title}, {getMasterLabel("branch", student.branch) }
                                            </h6>
                                        }
                                    </div>
                                    <div className="col-12 mb-2 text-center" >
                                    <img style={{height:"1.2in",height:"1.2in"}} className="rounded-circle"  
                                            src={   student.photo ===""?
                                                    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                                    :
                                                    student.photo
                                                } />
                                    </div>
                                    
                                    
                                    <div className="col-12 ms-3">
                                        <span className="fw-bold">Name : </span>{student.firstName} {student.lastName}
                                    </div>

                                    <div className="col-12  ms-3">
                                        <span className="fw-bold">F. Name : </span>{student.fatherName}
                                    </div>

                                    <div className="col-12  ms-3">
                                        <span className="fw-bold">B. Group : </span>{getMasterLabel("blood-group", student.bloodGroup)}
                                    </div>

                                    <div className="col-12 ms-3">
                                        <span className="fw-bold">Class : </span>{getMasterLabel("class", student.class) } - {getMasterLabel("section", student.section) }
                                    </div>

                                    <div className="col-12 ms-3">
                                        <span className="fw-bold">Roll No : </span>{student.rollNo}
                                    </div>

                                </div>
                            </div>

                            <div style={{minWidth:"2.125in", maxWidth:"2.125in",minHeight:"3.370in", maxHeight:"3.370in" }} className="border">
                                <div className="row p-3">
                                    <div className="col-12 mt-3 text-center">
                                        <QRCode value={getQrDetais(student)} size="1.6in" />
                                    </div>
                                    <div className="col-12 mt-2 text-center">
                                        <span className="fw-bold">{student.firstName} {student.lastName}</span>
                                    </div>
                                    <div className="col-12 text-center">
                                        {student.addressLine1} {student.addressLine2 ==  "" ? "" : ", "+student.addressLine2}
                                    </div>
                                    <div className="col-12 text-center">
                                        {getMasterLabel("city",student.city)}, {getMasterLabel("state",student.state)}, {student.pincode}
                                    </div>
                                    <div className="col-12 text-center">
                                        <span className="fw-bold">A No : </span>{student.admissionNo} / {moment(student.admissionYear).format("YY")}
                                    </div>
                                    <div className="col-12 text-center">
                                        <span className="fw-bold"><Phone/> : </span>{student.fatherPhone}
                                    </div>
                                    
                                </div>
                                
                                
                                
                            </div>
                                
                            </>}
                        </>
                        
                    )}
                    
                </div>
            </div>
        </div>
    )
}

export default IDCardComponent