import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import ExpandableCard from '../../../components/ExpandableCard'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices'
import attendanceServices from '../../Attendance/attendanceServices'
import studentServices from '../studentServices'

function StudentAttendanceReportPage({AppAlert}) {
    


    {/*  Master Details Start */}
    const [masters,setMasters] = useState(null);
    const getMasterList = () => {
        systemMasterServices.get([
            "month","attendance-status"
        ]).then(res=>{
            setMasters(res);
        })
    }
    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }
    useEffect(()=>{
        getMasterList();
    },[])




    let { studentId } = useParams();

    const [studentAttendanceList,setStudentAttendanceList] = useState([]);
    
    const [loading,setLoading] = useState(false);

    
    const getStudentAttendanceReport = () =>{
        setLoading(true);
        studentServices.getStudentAttendanceReport(studentId).then(res=>{
            setStudentAttendanceList(res.data.reverse());
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoading(false);
        })
    }

    const [studentMonthWiseReport,setStudentMonthWiseReport] = useState(null);
    const setupMonthWiseReportObj = () =>{
        let monthWiseReport = {};
        studentAttendanceList.forEach(attendance => {
            monthWiseReport[attendance.attendanceMonth] = {};
        });
        studentAttendanceList.forEach(attendance => {
            monthWiseReport[attendance.attendanceMonth][attendance.attendanceDate] = {attendanceStatus: attendance.attendanceStatus ,statusTitle : attendance.statusTitle};
        });
        console.log(monthWiseReport);
        setStudentMonthWiseReport(monthWiseReport);
    }


    const [studentAttendanceMonthList,setStudentAttendanceMonthList] = useState(null);
    const setupAttendanceMonth = () =>{
        let monthList = [];
        studentAttendanceList.forEach(attendance => {
            monthList.push(attendance.attendanceMonth);
        });
        
        monthList  = [... new Set(monthList)];

        setStudentAttendanceMonthList(monthList);

    }

    useEffect(()=>{
        setupAttendanceMonth()
    },[studentAttendanceList])

    useEffect(()=>{
        getStudentAttendanceReport();
    },[studentId])

    
    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-12">
                        {studentAttendanceMonthList && studentAttendanceMonthList.map((month,index)=>
                        
                            <ExpandableCard title={getMasterLabel('month',month)} defaultOpen={index === 0} >
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Attendance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentAttendanceList.map(attendance=>
                                            <>
                                                {attendance.attendanceMonth === month &&
                                                    <tr>
                                                        <th>{attendance.attendanceDate}</th>
                                                        <td>{attendance.statusTitle}</td>
                                                    </tr>
                                                    
                                                }
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </ExpandableCard>

                        )}
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(StudentAttendanceReportPage)