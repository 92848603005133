import React, { useEffect, useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import teacherLeaveRequestServices from '../teacherLeaveRequestServices';
import useMasterList from '../../../hooks/useMasterList';
import moment from 'moment';
import withAlert from '../../../hoc/withAlert';

function TeacherLeaveReaquestPage({AppAlert}) {

    const [teacherLeaveReaquestList,setTeacherLeaveReaquestList] = useState(null);
    const [masters,getMasterLabel] = useMasterList(["staff-leave-type"]);

    const getTeacherLeaveReaquestList = () =>{
        teacherLeaveRequestServices.getList().then(res=>{
            setTeacherLeaveReaquestList(res.data);
        });
    }

    const updateStatus = (id,requestStatus)=>{
        let payload = {
            id:id,
            requestStatus:requestStatus,
        }
        teacherLeaveRequestServices.updateStatus(payload).then(res=>{
            AppAlert("Success")
        }).catch(error=>{
            AppAlert(error.msg,"error")
        }).finally(()=>{
            getTeacherLeaveReaquestList();
        })
    }

    useEffect(()=>{
        getTeacherLeaveReaquestList();
    },[])

    return (
        <AppLayout>
            <div className="container-fluid">
            <div className="row mt-3">
                <div className="col-md-12">
                    <h3>Leave Request</h3>
                </div>
            </div>
                <div className="row">
                    <div className="col-md-12 mt-2">
                        <div className="card">
                            <div className="card-header bg-warning">
                                <h4>Pending</h4>
                            </div>
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Teacher</th>
                                            <th>Leave Day</th>
                                            <th>Request Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {teacherLeaveReaquestList && teacherLeaveReaquestList.pending.map(leaveRequest=>
                                            <tr key={leaveRequest.id}>
                                                <td>
                                                    <div><strong>{leaveRequest.firstName} {leaveRequest.larstName}</strong></div>
                                                    <div>{leaveRequest.teacherUID}</div>
                                                </td>
                                                <td>
                                                    {leaveRequest.startDate == leaveRequest.endDate ?
                                                        <div>For : <strong>{moment(leaveRequest.startDate).format("LL")} </strong></div>
                                                        :
                                                        <div>From : <strong> {moment(leaveRequest.startDate).format("LL")} to {moment(leaveRequest.endDate).format("LL")}</strong></div>
                                                    
                                                    }
                                                    
                                                    <div>{getMasterLabel("staff-leave-type",leaveRequest.leaveType)}</div>
                                                </td>
                                                <td>{ moment(leaveRequest.created_at).format("LL")} </td>
                                                <td><button type="button" className="btn btn-danger"  onClick={()=>updateStatus(leaveRequest.id,3)}>Disapprove</button></td>
                                                <td><button type="button" className="btn btn-success" onClick={()=>updateStatus(leaveRequest.id,2)}>Approve</button></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-2">
                        <div className="card">
                            <div className="card-header bg-success text-white">
                                <h4>Approved</h4>
                            </div>
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Teacher</th>
                                            <th>Leave Day</th>
                                            <th>Request Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {teacherLeaveReaquestList && teacherLeaveReaquestList.approved.map(leaveRequest=>
                                            <tr key={leaveRequest.id}>
                                                <td>
                                                    <div><strong>{leaveRequest.firstName} {leaveRequest.larstName}</strong></div>
                                                    <div>{leaveRequest.teacherUID}</div>
                                                </td>
                                                <td>
                                                    {leaveRequest.startDate == leaveRequest.endDate ?
                                                        <div>For : <strong>{moment(leaveRequest.startDate).format("LL")} </strong></div>
                                                        :
                                                        <div>From : <strong> {moment(leaveRequest.startDate).format("LL")} to {moment(leaveRequest.endDate).format("LL")}</strong></div>
                                                    
                                                    }
                                                    
                                                    <div>{getMasterLabel("staff-leave-type",leaveRequest.leaveType)}</div>
                                                </td>
                                                <td>{ moment(leaveRequest.created_at).format("LL")} </td>
                                                <td><button type="button" className="btn btn-danger"  onClick={()=>updateStatus(leaveRequest.id,3)}>Disapprove</button></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-2">
                        <div className="card">
                            <div className="card-header bg-danger text-white">
                                <h4>Rejected</h4>
                            </div>
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Teacher</th>
                                            <th>Leave Day</th>
                                            <th>Request Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {teacherLeaveReaquestList && teacherLeaveReaquestList.rejected.map(leaveRequest=>
                                            <tr key={leaveRequest.id}>
                                                <td>
                                                    <div><strong>{leaveRequest.firstName} {leaveRequest.larstName}</strong></div>
                                                    <div>{leaveRequest.teacherUID}</div>
                                                </td>
                                                <td>
                                                    {leaveRequest.startDate == leaveRequest.endDate ?
                                                        <div>For : <strong>{moment(leaveRequest.startDate).format("LL")} </strong></div>
                                                        :
                                                        <div>From : <strong> {moment(leaveRequest.startDate).format("LL")} to {moment(leaveRequest.endDate).format("LL")}</strong></div>
                                                    
                                                    }
                                                    
                                                    <div>{getMasterLabel("staff-leave-type",leaveRequest.leaveType)}</div>
                                                </td>
                                                <td>{ moment(leaveRequest.created_at).format("LL")} </td>
                                                <td><button type="button" className="btn btn-success"  onClick={()=>updateStatus(leaveRequest.id,2)}>Approve</button></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(TeacherLeaveReaquestPage);