import React, { useEffect, useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import withAlert from '../../../hoc/withAlert'
import ExamReportCardForm from '../components/ExamReportCardForm'
import useMasterList from '../../../hooks/useMasterList'

import ExamReportCardClassForm from '../components/ExamReportCardClassForm'
import examReportCardServices from '../examReportCardServices'
import ExamReportCardExamListForm from '../components/ExamReportCardExamListForm'
import examReportCardExamListServices from '../examReportCardExamListServices'
import ExamReportCardExamList from '../components/ExamReportCardExamList'
import ExamReportCardAssessmentList from '../components/ExamReportCardAssessmentList'
import ExamReportCardJuniorAssessmentList from '../components/ExamReportCardJuniorAssessmentList'

function ManageExamReportCardPage({AppAlert}) {

    const [masters,getMasterLabel]				= useMasterList(['exam-type','month','branch','class','section']);

    const [examReportList,setExamReportList]    = useState(null);
    const getList = () =>{
        examReportCardServices.getList().then(res=>{
            setExamReportList(res.data);
        })
    }

    useEffect(()=>{
        getList()
    },[])

    return (
        <AppLayout>
            <div className='container-fluid mt-2'>
                <div className='row'>
                    <div className='col-md-9'>
                        <h3>Manage Report Analytics</h3>
                    </div>
                    <div className='col-md-3'>
                        <span className="float-end"><ExamReportCardForm AppAlert={AppAlert} refreshList={getList} masters={masters} /></span>
                    </div>
                </div>
                <div className='row'>
                    {examReportList && examReportList.map(examReport => 
                        <div className='col-md-4' key={examReport.id}>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className="d-flex justify-content-between">
                                        {examReport.examLabel} { getMasterLabel("branch",examReport.branch) }
                                        <ExamReportCardForm AppAlert={AppAlert} refreshList={getList} masters={masters} data={examReport} />
                                        
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <ExamReportCardClassForm AppAlert={AppAlert} examReport={examReport} refreshList={getList} />
                                </div>
                                <div className='card-body'>
                                    <h4>Exams</h4>
                                    <ExamReportCardExamList examReport={examReport} AppAlert={AppAlert} masters={masters} getMasterLabel={getMasterLabel} />
                                </div>
                                <div className='card-body'>
                                    <h4>Assessment</h4>
                                    <ExamReportCardAssessmentList examReport={examReport} AppAlert={AppAlert} refreshList={getList} />
                                </div>

                                <div className='card-body'>
                                    <h4>Junior Assessment</h4>
                                    <ExamReportCardJuniorAssessmentList examReport={examReport} AppAlert={AppAlert} refreshList={getList} />
                                </div>
                            </div>
                        </div>
                    )}
                    
                </div>
            </div>    

        </AppLayout>
        
    )
}

export default withAlert(ManageExamReportCardPage)







{/*


<div className='row p-2'>
                    <div className='col-md-12 border'>
                        
                            <h4>Title</h4>
                            <h4>Exam List </h4>
                            <h5>Exam 1</h5>
                            <ul>
                                <li>Exam Type</li>
                                <li>Exam Number</li>
                                <li>% Ratito</li>
                            </ul>
                            

                            <h4>Attributes</h4>
                            <ul>
                                <li>Additional Subjects</li>
                                <li>Remarks</li>
                                <li>CO-SCHOLASTIC AREAS</li>
                            </ul>

                            <h4>Attendance</h4>


                    </div>
                    
                </div>

*/}