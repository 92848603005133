import moment from 'moment/moment'
import React from 'react'

function TeacherReamrkTypeTwo({reportCardDetails}) {
  return (
    <>
        <div className='row'>
            <div className='col-md-12'>
                <table className='table table-bordered' style={{borderColor:"#000"}}>
                    <thead>
                        <tr>
                            <td><strong className='text-danger'>Class Teacher Remarks : </strong>{reportCardDetails.teacherRemark}</td>
                        </tr>
                    </thead>
                </table>
                
            </div>
        </div>
    </>
  )
}

export default TeacherReamrkTypeTwo