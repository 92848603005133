import React, { useState,useEffect } from 'react'
import ConfirmDialog from '../../../components/ConfirmDialog'
import AwsDocumentInline from '../../../components/InlineEdit/AwsDocumentInline'
import AwsImageInline from '../../../components/InlineEdit/AwsImageInline'
import withAlert from '../../../hoc/withAlert'
import useMasterList from '../../../hooks/useMasterList'
import AppLayout from '../../../layout/components/AppLayout'
import classTimetableServices from '../classTimetableServices'
import ClassTimetableFilterComponent from '../components/ClassTimetableFilterComponent'
import ClassTimetableFormComponent from '../components/ClassTimetableFormComponent'


function ClassTimeablePage({AppAlert}) {

    const [masters,getMasterLabel] = useMasterList(["branch","class","section"]);
    const [searchingInProgress, setSearchingStatus] = useState(false)

    const [filterValues,setFilterValues] = useState(null);
    const [classTimetableList,setClassTimetableList] = useState(null);
    const [timetableList,setTimetableList] = useState(null);


    const getTimetableList = () =>{
        setSearchingStatus(true)
            classTimetableServices.getList().then(res=>{
                setTimetableList(res.data);
            }).catch(e=>{
                AppAlert(e.msg,"error")
            }).finally(()=>{
                setSearchingStatus(false);
        })
    }

    const getClassTimetable = () => {
        if(filterValues){
            setSearchingStatus(true)
            classTimetableServices.filter(filterValues).then(res=>{
                setClassTimetableList(res.data);
                getTimetableList();
            }).catch(e=>{
                AppAlert(e.msg,"error")
            }).finally(()=>{
                setSearchingStatus(false);
            })
        }
    }

    useEffect(()=>{
        getClassTimetable();
        getTimetableList();
    },[filterValues])

    const remove = (id) =>{
        classTimetableServices.remove(id).then(res=>{
            AppAlert("Removed Successfully","error")
            getClassTimetable()
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-4">
                    <div className="col-md-12">
                        <h3 className="ms-2"> Manage Class Time Table</h3>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">
                                Class Timetable
                            </div>
                            <div className="card-body">
                                {masters &&  <ClassTimetableFilterComponent masters={masters} onSearch={setFilterValues} searchingInProgress={searchingInProgress} /> }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">

                        <div className="row">
                            <div className="col-md-12">
                                
                                    {masters && classTimetableList && 
                                        <div className ="card">
                                            <div className="card-header bg-dark text-white">
                                                <span className="float-start">
                                                    <div><strong>Branch : </strong>{getMasterLabel("branch",filterValues.branch)} </div>
                                                    <div><strong>Class : </strong>{getMasterLabel("class",filterValues.class)}</div>
                                                </span>
                                                <span className="float-end">
                                                    {masters && filterValues && <ClassTimetableFormComponent masters={masters} filterValues={filterValues} onSuccess={getClassTimetable} AppAlert={AppAlert} /> }
                                                </span>
                                            </div>
                                            <div className="card-body">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Class</th>
                                                            <th>Section</th>
                                                            <th>Timetable</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {classTimetableList.map(classTimetable=>
                                                            <tr key={classTimetable.id}>
                                                                <td>{getMasterLabel("class",classTimetable.class)}</td>
                                                                <td>{getMasterLabel("section",classTimetable.section)}</td>
                                                                <td>
                                                                    <AwsImageInline
                                                                        label="Class Time table"
                                                                        name="timetableUrl"
                                                                        value={classTimetable.timetableUrl}
                                                                        editId={classTimetable.id}
                                                                        updateInline={classTimetableServices.updateInline}
                                                                        AppAlert={AppAlert}
                                                                        module="class-timetable"
                                                                        fileId={classTimetable.class+"_"+getMasterLabel("class",classTimetable.class)+"_"+getMasterLabel("section",classTimetable.section)}
                                                                        onSuccess={getClassTimetable}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {masters && filterValues && 
                                                                        <ClassTimetableFormComponent 
                                                                            masters={masters} 
                                                                            filterValues={filterValues} 
                                                                            onSuccess={getClassTimetable} 
                                                                            AppAlert={AppAlert}
                                                                            data={classTimetable}
                                                                        />
                                                                    }
                                                                    <ConfirmDialog id={classTimetable.id} confirm={remove} >Are you Sure You want to remove this Syllabus</ConfirmDialog>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card mt-2" >
                            <div className="card-header bg-primary text-white">
                                All Class Time Table List
                            </div>
                            <div className="card-body">
                                    {masters && timetableList && 
                                        
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Class</th>
                                                            <th>Section</th>
                                                            <th>Timetable</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {timetableList.map(classTimetable=>
                                                            <tr key={classTimetable.id}>
                                                                <td>{getMasterLabel("class",classTimetable.class)}</td>
                                                                <td>{getMasterLabel("section",classTimetable.section)}</td>
                                                                <td>
                                                                    <img style={{width:150,height:150}} src={classTimetable.timetableUrl} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            
                                    }
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(ClassTimeablePage)