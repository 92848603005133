import React, { useEffect, useState } from 'react'
import useMasterList from '../../../hooks/useMasterList'
import AppLayout from '../../../layout/components/AppLayout';
import { Autocomplete,TextField } from '@mui/material';
import { Add } from '@mui/icons-material';
import subjectCombinationServices from '../subjectComninationServices';
import withAlert from '../../../hoc/withAlert';
import ConfirmDialog from '../../../components/ConfirmDialog';

function SubjectCombinationPage({AppAlert}) {
    
    const [masters,getMasterLabel]  = useMasterList(['subject','subject-group']);
    const [subjectGroup,setSubjectGroup] = useState(null);
    const [subject,setSubject] = useState(null);

    const [subjectList,setSubjectList] = useState(null);

    const addSubjectToGroup = () =>{
        let payload = {
            subjectGroupId : subjectGroup.value,
            subjectId : subject.value
        }
        subjectCombinationServices.save(payload).then(res=>{
            AppAlert(res.msg)
        }).catch(error=>{
            AppAlert(error.msg,"error")
        }).finally(()=>{
            getSubjectList();
        })
    }

    const getSubjectList = () =>{
        if(subjectGroup){
            subjectCombinationServices.getList(subjectGroup.value).then(res=>{
                setSubjectList(res.data);
            })
        }
    }
    
    useEffect(()=>{
        getSubjectList();
    },[subjectGroup])

    const remove = (id) =>{
        subjectCombinationServices.remove(id).then(res=>{
            AppAlert("Removed Successfully","error")
            getSubjectList();
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    return (
        <AppLayout>
            <div className='container-fluid mt-4'>
                {masters && 
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='card'>
                            <Autocomplete
                                options={masters['subject-group']}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} label="Select Subject Group" />}
                                onChange={(event, newValue) => {
                                    setSubjectGroup(newValue);
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-md-8'>
                        {subjectGroup &&
                            <div className='card'>
                                <div className='card-header bg-dark text-white'>{subjectGroup.label}</div>
                                <div className='card-body'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Subject</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subjectList && subjectList.map((subject,i)=>
                                                <tr>
                                                    <td>{i+1}</td>
                                                    <td>
                                                        <div className='d-flex justify-content-between'>
                                                            <span className='fw-bold'>{getMasterLabel("subject",subject.subjectId)}</span>
                                                            <ConfirmDialog id={subject.id} confirm={remove} >Are you Sure You want to remove this subject</ConfirmDialog>
                                                        </div>
                                                        
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <div className='d-flex justify-content-between'>
                                                        <div style={{width:"60%"}}>
                                                        <Autocomplete
                                                            options={masters['subject']}
                                                            getOptionLabel={(option) => option.label}
                                                            renderInput={(params) => <TextField  {...params} label="Select Subject" />}
                                                            onChange={(event, newValue) => {
                                                                setSubject(newValue);
                                                            }}
                                                        />
                                                        </div>
                                                        <button type='button' className='btn btn-primary' onClick={addSubjectToGroup} ><Add/></button>
                                                    </div>
                                                    
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        
                    </div>
                </div>
                }
            </div>
        </AppLayout>
        
    )
}

export default withAlert(SubjectCombinationPage)