import { DirectionsBus } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';
import StudentAddSingleFeestructure from '../components/StudentAddSingleFeestructure';
import StudentAssignConcession from '../components/StudentAssignConcession';
import StudentFeeStructureComponent from '../components/StudentFeesStructureComponent';
import StudentInformation from '../components/StudentInformation';
import StudentInformationBasic from '../components/StudentInformationBasic';
import studentFeesStructureService from '../studentFeesStructureServices';
import studentServices from '../studentServices';

function StudentFeesStructurePage({AppAlert}) {
    let { id } = useParams();


    /* Master Details Start */
    const [masters,setMasters] = useState(null);
    const getMasterList = () => {
        systemMasterServices.get([
            "branch","gender","class","section", "state","blood-group","religion","caste","nationality","occupation","qualification", "fees-head","fees-term","month","payment-status"
        ]).then(res=>{
            setMasters(res);
        })
    }
    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }
    useEffect(()=>{
        getMasterList();
    },[])
    /* Master Details End */

    /* Student Details Start */
    const [studentDetails,setStudentDetails] = useState(null);
    const getStudentDetails =  () =>{
        if(masters != null){
            studentServices.getById(id).then(res=>{
                setStudentDetails(res.data);
            })
        }
    }

    useEffect(()=>{
        getStudentDetails();
    },[masters])

    /* Student Details End */

    /* Student Fees Structure Start */

    const [studentFeesStructure,setStudentFeesStructure] = useState(null);
    const [paidCount,setPaidCount]  = useState(null);
    const getStudentFeesStructure =  () =>{
        if(masters != null){
            studentFeesStructureService.get(id).then(res=>{
                setStudentFeesStructure(res.data);
                setPaidCount(res.paidCount);
            })
        }
    }

    useEffect(()=>{
        getStudentFeesStructure();
    },[studentDetails])

    /* Student Fees Structure Details End */


    

    return (
        <AppLayout>
            <div className="container-fluid mt-2 p-2">
                <div className="card">
                    <div className="card-header bg-primary text-white">Fees Structure</div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <span className="float-end">{studentDetails && <StudentAddSingleFeestructure studentDetails={studentDetails} AppAlert={AppAlert} masters={masters} refreshList={getStudentFeesStructure} />}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div>
                                    { studentDetails && <StudentInformationBasic  studentDetails={studentDetails} getMasterLabel={getMasterLabel} />}
                                    { /* studentDetails &&  <StudentInformation studentDetails={studentDetails} getMasterLabel={getMasterLabel} /> */ }
                                </div>
                                
                                {studentDetails && studentFeesStructure &&  studentFeesStructure.length > 0 &&
                                <div className="list-group mt-4">
                                    <StudentAssignConcession studentId={studentDetails.id} refreshList={getStudentFeesStructure} />
                                    <Link to={"/student/assign/transport/"+studentDetails.id} className="list-group-item list-group-item-action bg-primary text-white" >
                                        <DirectionsBus/>Transport  {studentDetails.transportId && <> {studentDetails.stoppage}</> }
                                    </Link>
                                    {paidCount !== null && paidCount == 0 &&
                                        <Link to={"/student/remove/fees-structure/"+studentDetails.id} className="list-group-item list-group-item-action  text-danger" >  Remove Fee Structure</Link>
                                    }
                                </div>
                                }
                            </div>
                            <div className="col-md-8 ">
                                {studentFeesStructure && <StudentFeeStructureComponent studentFeesStructure={studentFeesStructure} getMasterLabel={getMasterLabel} refreshList={getStudentFeesStructure} /> }
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <span className="float-end">
                            {studentFeesStructure &&  studentFeesStructure.length > 0 ?
                                <>
                                    {studentDetails && <Link to={"/invoice/generate?admissionNo="+studentDetails.admissionNo} className="btn btn-success"> Receive Payment </Link> }
                                </>
                                :
                                <>
                                    <h4>No fee structure assigned
                                        {studentDetails && <Link to={"/student/assign/fees-structure/"+studentDetails.id} className="btn btn-danger ms-2"> Assign Fee Structure </Link> }
                                    </h4>
                                </>
                            }
                            
                        </span>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(StudentFeesStructurePage)