
import { Boy, Girl } from '@mui/icons-material'
import moment from 'moment'
import React from 'react'
import StudentEditFormComponent from './StudentEditFormComponent'



const LabelValue = ({label,value}) =>{
    return (
        <div className="row">
            <div className="col-md-5">
                <strong>{label}</strong>
            </div>
            <div className="col-md-7">
                {value}
            </div>
        </div>
    )
}

const StudentInformationBasic = ({studentDetails,getMasterLabel,masters,AppAlert,refreshData}) =>{
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h4>{studentDetails.firstName} {studentDetails.lastName} {studentDetails.gender === 1? <Boy/> : <Girl/> }</h4>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <LabelValue label="Branch" value={getMasterLabel("branch",studentDetails.branch)} />
                    <LabelValue label="Class" value={getMasterLabel("class",studentDetails.class)} />
                    <LabelValue label="Section" value={getMasterLabel("section",studentDetails.section)} />
                    <LabelValue label="Admission No" value={studentDetails.admissionNo +   "/" + moment(studentDetails.admissionYear).format("YY")} />
                </div>

                <div className="col-md-12">
                    
                    <LabelValue label="Roll No" value={studentDetails.rollNo} />
                    <LabelValue label="UID" value={studentDetails.uid} />
                    <LabelValue label="Admission Date" value={moment(studentDetails.admissionDate).format("ll")} />
                    <LabelValue label="Birth Date" value={moment(studentDetails.dob).format("ll")} />
                    <LabelValue label="Father. Name" value={studentDetails.fatherName} />
                    <LabelValue label="Mother. Name" value={studentDetails.motherName} />

                </div>
            </div>
            


        </>
    )
}

export default StudentInformationBasic