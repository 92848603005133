import React, { useEffect, useState, useRef, useCallback } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import StudentBatchClassSectionFilter from '../../Filters/components/StudentBatchClassSectionFilter'
import useMasterList from '../../../hooks/useMasterList'


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';
import { Link } from 'react-router-dom';


const CellLink = (props) =>{
    return (
        <>
            {props && <Link to={`/student/details/${props.data.id}`}>{props.data.admissionNo }/{ moment(props.data.admissionYear).format("YY")}</Link> }
        </>
    )
}


function ClassWiseStudentList() {

    const [studentList,setStudentList] = useState();
    const gridRef = useRef();
    const [masters,getMasterLabel] = useMasterList(["area", "category", "special-category", "medium","branch","gender","class","section", "house","city", "state","blood-group","religion","caste","nationality","occupation","qualification"],"all");

    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
        cellStyle: { 'textTransform': 'uppercase' },
    });


    const [columnDefs,setColumnDefs] = useState([]);


    const setupColDef = () =>{
        setColumnDefs([
            {headerName: "Sr No",valueGetter: "node.rowIndex + 1"},
            {field:"firstName"},
            {field:"lastName"},
            {field:"Gender", valueGetter: params => getMasterLabel("gender",params.data.gender)},
            {field:"admissionNo",  valueGetter: params => params.data.admissionNo + "/" + params.data.admissionYear, cellRenderer: CellLink,},
            {field:"uid"},
            {field:"familyId"},
            {field:"regNo"},
            {field:"branch", valueGetter: params => getMasterLabel("branch",params.data.branch)},
            {field:"medium",valueGetter: params => getMasterLabel("medium",params.data.medium)},
            {field:"class",valueGetter: params => getMasterLabel("class",params.data.class)},
            {field:"section",valueGetter: params => getMasterLabel("section",params.data.section)},
            {field:"house",valueGetter: params => getMasterLabel("house",params.data.house)},
            {field:"rollNo"},
            {field:"fatherName"},
            {field:"fatherPhone"},
            {field:"fatherOccupation", valueGetter: params => getMasterLabel("occupation",params.data.fatherOccupation)},
            {field:"motherName"},
            {field:"motherPhone"},
            {field:"motherOccupation", valueGetter: params => getMasterLabel("occupation",params.data.motherOccupation)},
            {field:"totalSiblings"},
            {field:"Address", valueGetter: params => params.data.addressLine1+","+params.data.addressLine2},
            {field:"city", valueGetter: params => getMasterLabel("city",params.data.city)},
            {field:"state",valueGetter: params => getMasterLabel("state",params.data.state)},
            {field:"pincode"},
            {field:"area",valueGetter: params => getMasterLabel("area",params.data.area)},
            {field:"dob", valueGetter: params => moment(params.data.dob).format("DD-MMM-YYYY")},
            {field:"admissionDate", valueGetter: params => moment(params.data.admissionDate).format("DD-MMM-YYYY")},
            {field:"bloodGroup",valueGetter: params => getMasterLabel("blood-group",params.data.bloodGroup)},
            {field:"caste",valueGetter: params => getMasterLabel("caste",params.data.caste)},
            {field:"religion",valueGetter: params => getMasterLabel("religion",params.data.religion)},
            {field:"category",valueGetter: params => getMasterLabel("category",params.data.category)},
            {field:"specialCategory",valueGetter: params => getMasterLabel("special-category",params.data.specialCategory)},
            {field:"nationality",valueGetter: params => getMasterLabel("nationality",params.data.nationality)},
            {field:"height"},
            {field:"weight"},

        ]);
    }

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);


    useEffect(()=>{
        setupColDef();
    },[studentList])


    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-9">
                        <StudentBatchClassSectionFilter setStudentList={setStudentList} masters={masters} />
                    </div>
                    <div className='col-md-3'>
                        <button type='button' className='btn btn-primary' onClick={onBtnExport} >Export</button>
                    </div>
                </div>

                <div className ="row">
                    {masters && 
                            <>
                                <div className="col-md-12">
                                <div className="ag-theme-alpine" style={{height: 550, width:"auto"}}>
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={studentList}
                                            columnDefs={columnDefs}
                                            defaultColDef={defaultColDef}
                                            animateRows={true}
                                            >
                                        </AgGridReact>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </AppLayout>
    )
}

export default ClassWiseStudentList