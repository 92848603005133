import React,{useState,useEffect,useRef} from 'react'
import withAlert from '../../../hoc/withAlert';
import useMasterList from '../../../hooks/useMasterList';
import useSessionList from '../../../hooks/useSessionList';
import AppLayout from '../../../layout/components/AppLayout'
import feesSettingServices from '../../settings/feesSettingServices';


import { useReactToPrint } from 'react-to-print';
import CommonLoadingSkeleton from '../../../components/CommonLoadingSkeleton';


const FeeStructureDetails = ({sessionYear,feesClassGroup,getMasterLabel}) =>{
    
    const [feeStructureList,setFeeStructureList] = useState(null);
    const [searchInProgress,setSearchStatus] = useState(false);

    const getFeeStructureList = () =>{
        setSearchStatus(true);
        feesSettingServices.filter(
            {
                sessionYear:sessionYear.value,
                feesClassGroup:feesClassGroup.value
            }
        ).then(res =>{
            setFeeStructureList(res.data);
        }).catch(error=>{
            //AppAlert(error.msg,"error")
        }).finally(()=>{
            setSearchStatus(false);
        })
    }

    useEffect(()=>{
        getFeeStructureList();
    },[])
    

    return (
        
        <>
            {searchInProgress && <div className="col-md-3"><CommonLoadingSkeleton /></div>}
            { feeStructureList && feeStructureList.length > 0 && 
                <div className="col-md-3">
                <table className="table table-bordered border-dark">
                    <thead>
                        <tr>
                            <th colSpan="2" className="text-center"> {feesClassGroup.label} /{sessionYear.label} </th> 
                        </tr>
                    </thead>
                    <tbody>
                        {feeStructureList.map(feeStructure=>
                            <tr>
                                <td>
                                    <strong>{getMasterLabel("fees-head",feeStructure.feesHead)} <br/></strong>
                                    <small>{getMasterLabel("fees-term",feeStructure.feesTerm)} <strong> {getMasterLabel("month",feeStructure.month)} </strong></small> 
                                </td>
                                <td>{feeStructure.amount}</td>
                            </tr>
                        )}
                        
                    </tbody>
                    
                </table>
                </div>
            }
        </>
        
    )
}



function FeeStructureListPage({AppAlert}) {

    
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    

    const [sessionYearList] = useSessionList();
    const [masters,getMasterLabel] = useMasterList(["fees-class-group","fees-head", "fees-term", "month"]);





    
    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-12">
                        <button type="button" className="btn btn-primary float-end" onClick={handlePrint} >Print </button>
                    </div>
                </div>
                <div className="row mt-1 p-2 " ref={componentRef}>
                    {masters && sessionYearList && sessionYearList.map(sessionYear=>
                        <table className="table">
                            <thead>
                                <tr>
                                    <h4>Session Year :  {sessionYear.label}</h4>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="row">
                                        {masters['fees-class-group'].map(feesClassGroup=>
                                                
                                                <FeeStructureDetails sessionYear={sessionYear} feesClassGroup={feesClassGroup} getMasterLabel={getMasterLabel} />
                                            
                                        )}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot style={{pageBreakAfter:""}}></tfoot>
                        </table>
                        
                        
                    )}
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(FeeStructureListPage)



{/*

    <div className="mt-2 border border-dark" style={{pageBreakAfter:"always"}} key={sessionYear.value}>
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="text-center mt-2">
                                        <h4>Session Year :  {sessionYear.label}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {masters['fees-class-group'].map(feesClassGroup=>
                                    
                                        <FeeStructureDetails sessionYear={sessionYear} feesClassGroup={feesClassGroup} getMasterLabel={getMasterLabel} />
                                    
                                )}
                            </div>
                        </div>
*/}