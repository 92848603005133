import React, { useEffect,useState } from 'react'
import studentReportCardService from '../studentReportCardServices';

function StudentReportMarksDetailsComponent({masters,getMasterLabel,reportCardDetails,gradeList}) {
  

    const [assessment,setAssessment] = useState(null);
    const [additionalSubjectList,setAdditionalSubjectList] = useState(null);
    const prepareData = () =>{
        if(reportCardDetails && reportCardDetails.assessment){
        let assessmentJson = JSON.parse(reportCardDetails.assessment);
        setAssessment(assessmentJson);
        }

        if(reportCardDetails && reportCardDetails.additionalSubjectList){
        let additionalSubjectListJson = JSON.parse(reportCardDetails.additionalSubjectList);
        setAdditionalSubjectList(additionalSubjectListJson);
        }
    }

    useEffect(()=>{
        prepareData();
        getReportResult()
    },[reportCardDetails])


    const [resultList,setResultList]    = useState(null);

  
    const getReportResult = () =>{    
        //console.log(reportCardDetails);
        if(reportCardDetails){
            studentReportCardService.getReportMarksDetails(reportCardDetails.id).then(res=>{
                console.log("report result",res)
                
                

                //resultList[0].examResult.map((subjectResult,i) =>
                
                /*
                if(res.resultList && res.resultList[0].examResult.length > 0 ){
                    
                }
                */
                //for(let i =0;i<res.resultList[0].length)
                
                setResultList(res.resultList);
                
            })
        }
    }
  

    const getPercentageMarks = (obtained,max) =>{
        if(max == 0 ){
            return 0;
        }
        return (obtained/max) * 100;
    }

    

    
    const getGrade = (total)=>{
        try{
            if(total == 0 ){
                total = 1;
            }
            let key = Math.ceil(total / 5);
            return gradeList[key-1].grade;
        }catch{
            console.log(total);
            return "--";
        }
        
    }

    const calculateTotal = (subject_i) =>{
        let ratioList =  [];
        
        for(let i=0;i<resultList.length;i++){
            ratioList.push(resultList[i].examGroup.ratio)
        }

        let ratioMarksList = [];
        let obtainedRatioMarksList = [];
        let total = 0;

        for(let i=0;i<resultList.length;i++){
            let obtainedMarks = resultList[i].examResult[subject_i]?.obtainedMarks;
            let maxMarks =  resultList[i].examResult[subject_i]?.maxMarks;
            let marksPercent =  getPercentageMarks(obtainedMarks,maxMarks);

            //console.log("marksPercent",marksPercent);

            ratioMarksList.push(marksPercent)
            
            let ratioMarks = marksPercent * (ratioList[i] /100)
            obtainedRatioMarksList.push(ratioMarks);
            total+= ratioMarks;
            
        } 

        

        let data = {
            totalMarks: Math.ceil(total),
            grade:getGrade(total),
            ratioMarksList:ratioMarksList,
        }

        return data;//ratioMarksList;

    }

    


    

    const [totalList, setTotalList] = useState([]);
    const doTotal = () =>{
        let total_list = [];
        if(resultList && resultList[0].examResult){
            for(let i = 0; i<resultList[0].examResult.length;i++){
                total_list.push(calculateTotal(i));
            }
        }
        setTotalList(total_list)
    }
    useEffect(()=>{
        doTotal();
    },[resultList])




    const [grandTotal, setGrandTotal] = useState(null);
    const doGrandTotal = () => {
        if(totalList){
            let grand_total = 0;
            for(let i = 0; i < totalList.length;i++){
                grand_total = grand_total+totalList[i].totalMarks;
            }
            let percentage = ((grand_total /(totalList.length*100) ) * 100).toFixed(2);
            let data = {
                obtainedMarks : grand_total,
                maxMarks : totalList.length*100,
                percentage :percentage,
                //grade:getGrade(percentage),
            };
            setGrandTotal(data)
        }
    }

    useEffect(()=>{
        doGrandTotal();
    },[totalList])


    

    return (
      <div className='row'>
        <div className='col-md-12'>
            {resultList && 
            
                <table className='table table-bordered' style={{borderColor:"#000"}}>
                    <thead>
                        <tr >
                            <th className="text-center align-middle" rowSpan={2}>Subjects</th>
                            {resultList.map((result,i)=>
                                
                                <th 
                                    className="text-center"
                                    colSpan={i === resultList.length-1 ? 3 : 1}
                                    rowSpan={i === resultList.length-1 ? 1 : 2}
                                    key={i}
                                >
                                    {getMasterLabel("exam-type",result.examGroup.examType)}
                                    {i < resultList.length-1 &&
                                        <><br/>({result.examGroup.maxMarks})</>
                                    }
                                    
                                </th>
                            )}
                            
                            <th>Grand Total</th>
                            <th rowSpan={2} className="text-center align-middle">Grade</th>
                        </tr>
                        <tr>
                            
                            {resultList.map((result,i)=>
                                <>
                                {i === resultList.length-1 &&
                                    <>
                                        <th>Theory</th>
                                        <th>Internal</th>
                                        <th>Total <br/>({result.examGroup.maxMarks})</th>
                                    </>
                                }
                                </>
                            )}
                            
                            <th>
                                <div className='d-flex justify-content-between'>
                                {resultList.map((result,i) =>
                                        <>
                                        <div className=''>
                                            <p style={{marginBottom:0}}>{getMasterLabel("exam-type",result.examGroup.examType)}</p>
                                            <p style={{marginBottom:0}}>{result.examGroup.ratio}%</p>
                                        </div>
                                        {i < resultList.length-1 && resultList.length > 1 &&  "+"}
                                        </>
                                        

                                )}
                                </div>
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {
                            resultList[0].examResult.map((subjectResult,i) =>
                                <tr key={subjectResult.subjectId}>
                                    <th>{getMasterLabel("subject",subjectResult.subjectId)}</th>
                                    
                                    {resultList.map((result,j)=>
                                        <>
                                            {j === resultList.length-1 ?
                                                <>
                                                    <td className='text-center'>
                                                        {/* result.examResult[i]?.obtainedTheoryMarks */}
                                                        {result.examResult[i] ? result.examResult[i].obtainedTheoryMarks : "--" }
                                                    </td>
                                                    <td className='text-center'>
                                                        { /* result.examResult[i]?.obtainedInternalMarks */}
                                                        {result.examResult[i] ? result.examResult[i].obtainedInternalMarks : "--" }
                                                    </td>
                                                    <td className='text-center'>
                                                        {result.examResult[i] ? result.examResult[i].obtainedMarks : "--" }
                                                    </td>
                                                </>
                                                :
                                                <td
                                                    className='text-center'
                                                    key={j}>
                                                    {result.examResult[i] ? result.examResult[i].obtainedMarks : "--" }
                                                    {/*result.examResult[i].obtainedMarks}/ {result.examResult[i].maxMarks */}
                                                    
                                                    { /* getPercentageMarks(result.examResult[i].obtainedMarks, result.examResult[i].maxMarks) */}
                                                </td>
                                            }
                                        </>
                                        
                                    )}
                                    <td className="text-center">
                                        { /* JSON.stringify(calculateTotal(i)) */} 

                                        { /* totalList && JSON.stringify(totalList[i]) */}

                                        { /* JSON.stringify(totalList[i]?.ratioMarksList) */}
                                        <br/>
                                        { totalList  && !isNaN(totalList[i]?.totalMarks) ? totalList[i]?.totalMarks : "--"  }

                                    </td>
                                    <td className='text-center'>
                                    { totalList && totalList[i]?.grade }
                                    </td>
                                </tr>

                            )
                        }
                    </tbody>
                </table>
            }
        </div>
        
        {grandTotal &&
            <>
                <div className='col-md-6'>
                    <table className='table table-bordered' style={{borderColor:"#000"}}>
                        <tbody>
                            <tr>
                                <th>Overall Marks</th>
                                <th>{grandTotal.obtainedMarks} / {grandTotal.maxMarks}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='col-md-6'>
                    <table className='table table-bordered' style={{borderColor:"#000"}}>
                        <tbody>
                            <tr>
                                <th>Overall Percentage </th>
                                <th>{isNaN(grandTotal.percentage) ? "--" : grandTotal.percentage} </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>

        }
      </div>
  )
}


{/*
<>
      <div>{JSON.stringify(studentDetails)}</div>
      <div>{JSON.stringify(additionalSubjectList)}</div>
      <div>{JSON.stringify(assessment)}</div>
      
      </>
*/}
export default StudentReportMarksDetailsComponent