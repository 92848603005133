import React, { useState } from 'react'

import TextInline from '../../../components/InlineEdit/TextInline'
import studentFeesStructureService from '../studentFeesStructureServices'





const StudentFeeStructureComponent = ({studentFeesStructure,getMasterLabel}) => {

    
    

    return (
        <>
            <div className="row">
                <div className="col-md-12 table-responsive"  style={{maxHeight:350, overflowY:"scroll"}}>
                    {studentFeesStructure && 
                        <table className="table table-striped">                
                            <tbody>
                            {
                                studentFeesStructure.map(feesStructure=>
                                    
                                    <tr key={feesStructure.id}>
                                        <td>{getMasterLabel("fees-head",feesStructure.feesHead)}</td>
                                        <td>{getMasterLabel("fees-term",feesStructure.feesTerm)}</td>
                                        <td>{getMasterLabel("month",feesStructure.paymentMonth)}</td>
                                        <td>
                                            {feesStructure.paymentStatus === 1 ? 
                                            <TextInline name="feesAmount" editId={feesStructure.id} value={feesStructure.feesAmount} updateInline={studentFeesStructureService.updateInline} />
                                            : 
                                            feesStructure.paymentStatus <= 4 ?

                                                <span className="text-success">{feesStructure.feesAmount} {getMasterLabel("payment-status",feesStructure.paymentStatus)}</span>
                                                :
                                                <small className="text-muted" ><del>
                                                    <span className="text-danger">{feesStructure.feesAmount} {getMasterLabel("payment-status",feesStructure.paymentStatus)}</span>
                                                    </del>
                                                </small>
                                            
                                            }

                                            {feesStructure.feesAmount !== feesStructure.orignalAmount &&
                                                <span>
                                                    <small className="text-muted" ><del>{feesStructure.orignalAmount}</del></small>
                                                    <small className="ms-2 text-primary">({feesStructure.concessionTitle} {feesStructure.concession} % )</small>
                                                </span>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    }
                </div>
            </div>

            
                

            

        </>
    )
}

export default StudentFeeStructureComponent