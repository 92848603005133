import React, { useEffect, useState } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';


import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { CircularProgress } from '@mui/material';
import eventServices from '../eventServices';
import moment from 'moment';
import EventFormComponent from '../components/EventFormComponent';


function EventListPage({AppAlert}) {
    
    /* Start Masters Setting  */
    const [masters,setMasters] = useState(null)
    const [searchingInProgress, setSearchingStatus] = useState(false);

    const getMasterList = () => {
        systemMasterServices.getWithAllOptions([
            "branch","class","section","subject"
        ]).then(res=>{
            setMasters(res);
        })
    }
    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }
    useEffect(()=>{
        getMasterList();
    },[])
    /* End Masters Setting  */


    /* Search Form Work  */
    const [eventList,setEventList]    = useState(null);
    const initialValues = {
        branch:"",
        class:"",
        section:"",
        eventStartDate:moment().format("YYYY-MM-DD"),
        eventEndDate:moment().format("YYYY-MM-DD"),
        
    }

    const onSubmit = (values, onSubmitProps) => {
        setSearchingStatus(true)
        eventServices.filter(values).then(res =>{
            setEventList(res.data);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSearchingStatus(false);
        })
    }

    const validationRule = Yup.object({
        //branch: Yup.string().required("Required"),
        //class: Yup.string().required("Required"),
        //section: Yup.string().required("Required"),
        //subject: Yup.string().required("Required"),
    });

    /* Search Form Work  */



    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-md-6">
                        <h3>Event List </h3>
                    </div>
                    <div className="col-md-6">
                    <span className="float-end">{masters &&  <EventFormComponent masters={masters} AppAlert={AppAlert} refreshList={onSubmit} /> }</span>
                    </div>
                    <div className="col-md-12">
                    <Formik
                            initialValues={initialValues}
                            validationSchema={validationRule}
                            onSubmit={onSubmit}
                            enableReinitialize validateOnMount
                        >
                            {
                                (formik) => {
                                    //console.log(formik.values);
                                    return (
                                        <Form>
                                            <div className="row p-2">
                                                {masters && 
                                                    <>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Branch" name="branch"  
                                                                options={masters['branch']} multiple={false} 
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Class" name="class"  
                                                                options={masters['class']} multiple={false} 
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Section" name="section"  
                                                                options={masters['section']} multiple={false} 
                                                            />
                                                        </div>

                                                        <div className="col-md-6 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="date" 
                                                                label="Event Start Date" name="eventStartDate" 
                                                            />
                                                        </div>

                                                        <div className="col-md-6 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="date" 
                                                                label="Event End Date" name="eventEndDate" 
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-xs-12 p-2" >
                                                    <span className="float-end">
                                                        {searchingInProgress ? 
                                                            <CircularProgress/> 
                                                            :
                                                            <button className="btn btn-success" type="submit">Search</button>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </Form>
                                    )
                                }
                            }
                        </Formik>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {eventList && eventList.length > 0 ? 
                            eventList.map(eventDetails=>
                            <div className="row mt-2" key={eventDetails.id}>
                                <div className="co-md-12">
                                    <div className="card">
                                        <div className="card-header bg-primary ">
                                            <strong className="text-white">{eventDetails.title}</strong>
                                            <span className="float-end">
                                                {masters &&  <EventFormComponent masters={masters} AppAlert={AppAlert} refreshList={onSubmit} data={eventDetails} /> }
                                            </span>
                                            <span className="float-end">
                                                {eventDetails.publish === 0 ? 
                                                    <span className="badge bg-warning text-dark">Drafted</span>
                                                    :
                                                    <span className="badge bg-success text-white">Published</span>
                                                    }
                                            </span>
                                        </div>
                                        <div className="card-body mb-0 pb-0">
                                            <p>{eventDetails.details}</p>
                                        </div>
                                        <div className="card-footer">
                                            Branch : <strong>{ getMasterLabel("branch",eventDetails.branch)}</strong>|
                                            Class : <strong>{ getMasterLabel("class",eventDetails.class)} - { getMasterLabel("section",eventDetails.section)} </strong> | 
                                            Event Start Date : <strong>{ moment(eventDetails.eventStartDate).format("LL")} </strong> 
                                            Event End Date : <strong>{ moment(eventDetails.eventEndDate).format("LL")} </strong>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                            :
                            <div className="row">
                                <div className="co-md-12 ">
                                    {Array.isArray(eventList) && <h3>No Record Found</h3> }
                                </div>
                            </div>
                            
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(EventListPage)