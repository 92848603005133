import api from "../../api/api"


const get = (id) => {
    return new Promise((resolve, reject)=>{
          api.get("transport-fees/"+id).then( (res)=>{
            if(res.data){
                resolve(res.data);
            } else {
                reject({
                    message: "Somthing Went Wrong"
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getByTransportId = (id) => {
    return new Promise((resolve, reject)=>{
          api.get("transport-fees/transport/"+id).then( (res)=>{
            if(res.data){
                resolve(res.data);
            } else {
                reject({
                    message: "Somthing Went Wrong"
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getList = () => {
    return new Promise((resolve, reject)=>{
          api.get("transport-fees").then( (res)=>{
            if(res.data){
                resolve(res.data);
            } else {
                reject({
                    message: "Somthing Went Wrong"
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getTransportDD = () => {
    return new Promise((resolve, reject)=>{
          api.get("transport-fees/dd").then( (res)=>{
            if(res.data){
                resolve(res.data);
            } else {
                reject({
                    message: "Somthing Went Wrong"
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}



const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/transport-fees",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const assign = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/transport-fees/assign",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const unassign = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/transport-fees/unassign",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const transportFeesStructureServices = {
    get,
    getByTransportId,
    getList,
    getTransportDD,
    save,
    assign,
    unassign,
 }
 
 export default transportFeesStructureServices;