import React, { useEffect, useRef, useState } from 'react'

import AppLayout from '../../../layout/components/AppLayout'
import useMasterList from '../../../hooks/useMasterList'
import BranchClassFilter from '../components/BranchClassFilter';
import reportServices from '../reportServices';
import { Link } from 'react-router-dom';


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';



function TermwiseFeeCollectionGrid() {
    const [masters] = useMasterList(['branch','class'],"all");
    const [searchingInProgress, setSearchingStatus] = useState(false);

    const [studentReport,setStudentReport] = useState(null);
    const [paymentMonths,setPaymentMonths] = useState(null);
    const getList = (values) =>{
        console.log("values",values);
        setSearchingStatus(true);
        reportServices.termWiseCollectionReport(values).then(res=>{
            setStudentReport(res.report);
            setPaymentMonths(res.paymentMonths);
        }).finally(()=>
            setSearchingStatus(false)
        )
    }

    const doTotal = (column) =>{
        let total = 0;
        //total = total + studentReport.map(student=>student[column]);
        total = studentReport.reduce((total,num)=>{
            return total+num[column];
        },0)

        return  total;
    }


    const gridRef = useRef()
    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
        cellStyle: { 'textTransform': 'uppercase' },
    })

    const [columnDefs,setColumnDef] = useState([]);

    const prepairColumnDef = () => {
        if(paymentMonths){
            let colDef = [
                {field:"admissionNo",headerName:"Admission No"},
                {field:"studentName",header: "Student Name"},
                {field:"fatherName",header: "Father Name"},
                {field:"class",header: "Class" , valueGetter: params => params.data.classTitle+"-"+params.data.sectionTitle },
            ];

            let x = [];

            for(let i=0;i<paymentMonths.length;i++){
                x =  [...x,{field: paymentMonths[i].monthTitle+"_Orignal"},]
                x =  [...x,{field: paymentMonths[i].monthTitle+"_Concession"},]
                x =  [...x,{field: paymentMonths[i].monthTitle+"_Payable"},]
                x =  [...x,{field: paymentMonths[i].monthTitle+"_PaidAmount"},]
                x =  [...x,{field: paymentMonths[i].monthTitle+"_Dues"},]
            }
            x =  [...x,{field: "Total"+"_Orignal"},]
            x =  [...x,{field: "Total"+"_Concession"},]
            x =  [...x,{field: "Total"+"_Payable"},]
            x =  [...x,{field: "Total"+"_PaidAmount"},]
            x =  [...x,{field: "Total"+"_Dues"},]

            setColumnDef([...colDef,...x]);


        }
    }

    useEffect(()=>{
        prepairColumnDef();
    },[paymentMonths])
    
    

    
    


    const onBtnExport = () => {
        gridRef.current.api.exportDataAsCsv();
    }

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-9">
                        <BranchClassFilter masters={masters} searchingInProgress={searchingInProgress} filterData={getList} />
                    </div>
                    <div className="col-md-3">
                        {studentReport && <button type="button" className="btn btn-warning float-end mt-3" onClick={onBtnExport} >Export</button>}
                    </div>
                </div>
                {paymentMonths && studentReport &&


                    <div className="row pt-2">
                        <div className="col-md-12">
                            <div className="ag-theme-alpine" style={{height: 550, width:"auto"}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={studentReport}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    >
                                </AgGridReact>
                            </div>
                        </div>
                    </div>

                }
            </div>
        </AppLayout>
    )  
}

export default TermwiseFeeCollectionGrid