import React, { useEffect, useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import moment from 'moment/moment'
import TextField from '@mui/material/TextField';
import reportServices from '../reportServices';

function StaffDailyAttendanceReport() {
    
    const [selectedDate,setSetectedDate] = useState(moment().format("YYYY-MM-DD"))
    const [attendenceList, setAttendancList] = useState(null);

    const getAttendancList = () =>{
        reportServices.staffAttendanceReport(selectedDate).then(res=>{
            setAttendancList(res.data);
        }).catch(error=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        getAttendancList();
    },[selectedDate])



    return (
        <AppLayout>
            <div className='container-fluid mt-3'>
                <div className='row'>
                    <div className='col-md-6'>
                        <TextField
                            label="Date"
                            type="date"
                            value={selectedDate}
                            onChange={(e) => {
                                setSetectedDate(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Punch In </th>
                                    <th>Punch Out</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attendenceList && attendenceList.length> 0 && attendenceList.map(attendence=>
                                    <tr>
                                        <th>{attendence.firstName} {attendence.lastName}</th>
                                        <td>{ attendence.punchIn === null ? "--" : moment(attendence.punchIn).format("h:mm:ss A")}</td>
                                        <td>{ attendence.punchOut === null ? "--" : moment(attendence.punchOut).format("h:mm:ss A")}</td>
                                    </tr>
                                )}

                                {attendenceList && attendenceList.length == 0 &&
                                    <tr>
                                        <th colSpan={3} className='text-center'>No Record Found</th>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default StaffDailyAttendanceReport