import { ArrowBack, Forward } from '@mui/icons-material';
import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router'
import ExpandableCard from '../../../components/ExpandableCard';
import withAlert from '../../../hoc/withAlert';
import useMasterList from '../../../hooks/useMasterList';
import AppLayout from '../../../layout/components/AppLayout';
import transportFeesStructureServices from '../../Transport/transportFeesStructureServices';
import transportServices from '../../Transport/transportServices';
import studentServices from '../studentServices';

function StudentAssignTransportPage({AppAlert}) {
    let { id } = useParams();
    const navigate = useNavigate();

    const [masters, getMasterLabel] = useMasterList(["branch","class","section", "month","fees-head","fees-term", "fees-type","gender", "vehicle-type" ]);
    

    /* Get Student Details Start */
    const [studentDetails,setStudentDetails] = useState(null);
    const getStudentDetails =  () =>{
        if(masters != null){
            studentServices.getById(id).then(res=>{
                setStudentDetails(res.data);
            })
        }
    }
    useEffect(()=>{
        getStudentDetails();
    },[masters])
    /* Get Student Details End */



    /* Get Transport List Start */
    const [transportList,setTransportList] = useState(null);
    const getTransportList =  () =>{
        if(masters != null){
            transportServices.getList().then(res=>{
                setTransportList(res.data);
            })
        }
    }
    useEffect(()=>{
        getTransportList();
    },[masters])
    /* Get Transport List End */


    const [selectedVehicle,setSelectedVehicle] = useState(null);
    const [transportFeesStructureList,setTransportFeesStructureList] = useState(null);
    const getTransportFeesStructureList =  () =>{
        setTransportFeesStructureList(null);
        if(selectedVehicle != null){
            transportFeesStructureServices.getByTransportId(selectedVehicle.id).then(res=>{
                setTransportFeesStructureList(res.data);
            })
        }
    }
    useEffect(()=>{
        getTransportFeesStructureList();
    },[selectedVehicle])
    



    const [stoppageList,setStoppageList] = useState(null)
    useEffect(()=>{ 
        if(transportFeesStructureList!==null){
            let sl = Array.from(new Set(transportFeesStructureList.map(transportFeesStructure=>transportFeesStructure.stoppage))); //Create Unique Stopage Array
            console.log("storageist is ",sl);
            let stopListObjectArray = sl.map(stoppage=> {return ( {stoppageName:stoppage}) }) //Setup object array with key value;
            stopListObjectArray.forEach((stoppage,i)=>{  // Setup Detailed Fee Structure
                stopListObjectArray[i]['feestructureList'] = transportFeesStructureList.filter(transportFeesStructure=>transportFeesStructure.stoppage === stoppage.stoppageName)
            })
            console.log(stopListObjectArray);
            
            setStoppageList(stopListObjectArray);
            
        }
    },[transportFeesStructureList])



    const assignTransport = (stoppage) => {
        const payload = {
            transportId:selectedVehicle.id,
            stoppage:stoppage,
            studentId:studentDetails.id,
            studentClass: studentDetails.class,
            studentBranch: studentDetails.branch,
        }

        transportFeesStructureServices.assign(payload).then(res=>{
            AppAlert("Transport Fees Assigned")
        }).catch(()=>{
            AppAlert("Something went Wrong","error")
        }).finally(()=>{
            setTimeout(()=>{
                navigate("/student/fees-structure/"+studentDetails.id);
            },2000)
        })
    }


    const unAassignTransport = (stoppage) => {
        const payload = {
            studentId:studentDetails.id,
        }

        transportFeesStructureServices.unassign(payload).then(res=>{
            AppAlert("Transport Details Updated")
        }).catch((error)=>{
            AppAlert(error.msg,"error")
        }).finally(()=>{
            getStudentDetails();
        })
    }
    

    

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-3 text-danger">
                    <div className="col-md-6">
                        <h3>Assign Transport</h3>
                    </div>
                    <div className="col-md-6">
                        <button type="button" className="btn btn-danger btn-lg float-end"
                            onClick={()=>{navigate("/student/fees-structure/"+studentDetails.id)}}>
                            Skip <Forward/>
                        </button>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-md-12">
                        { studentDetails && 
                            <div className="border shadow p-2"> 
                                <div className="row">
                                    <div className="col-md-4">
                                        <span className="">
                                            <strong>{studentDetails.firstName} {studentDetails.lastName}</strong>
                                        </span>
                                        <span className="float-end">
                                            <strong>Admission No : </strong><span>{studentDetails.admissionNo}</span>
                                        </span>
                                    </div>
                                    <div className="col-md-4">
                                        <strong>Class : </strong> 
                                        <span>{getMasterLabel("class",studentDetails.class)}</span>
                                        <span>-{getMasterLabel("section",studentDetails.section)}</span>
                                        <span>({getMasterLabel("branch",studentDetails.branch)})</span>
                                    </div>
                                    <div className="col-md-4">
                                        <strong>Admission Year : </strong>
                                        <span>{studentDetails.admissionYear}</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* 
                    <div className="col-md-8">
                        <div className="border shadow p-2">
                            <FeeStructureFilterComponent masters={masters} setFiltersValues={setFilters} sessionYearList={sessionYearList} />
                        </div>
                    </div>
                    */}
                </div>

                {selectedVehicle === null &&
                    <div className="row">

                        {studentDetails && studentDetails.transportId !== null && transportList &&
                            <div className="col-md-6 offset-3">
                                <div className="card">
                                    <div className="card-body">
                                        <h4>Transport Already Assigned</h4>
                                        <h4>Stoppage : {studentDetails.stoppage}</h4>
                                    </div>
                                    <div className="card-footer text-center">
                                        <button type="buttton" onClick={unAassignTransport} className="btn btn-danger">Change / Remove</button>
                                    </div>
                                </div>
                            </div>
                        }

                        {studentDetails &&  studentDetails.transportId == null && transportList &&
                        <div className="col-md-12 text-center">
                            <h3>Select Route</h3>
                        </div>
                        }

                        {studentDetails && studentDetails.transportId == null && transportList && transportList.map(res=>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header">
                                        {res.vehicleRoute}
                                        <strong className="float-end">
                                            {getMasterLabel("vehicle-type",res.vehicleType)}
                                        </strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h6>Route</h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6>: {res.vehicleRoute} ({getMasterLabel("vehicle-type",res.vehicleType)}) </h6> 
                                            </div>

                                            <div className="col-md-6">
                                                <h6>Vehicle Number</h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6>: {res.vehicleNumber}</h6>
                                            </div>

                                            <button type="button" onClick={()=>setSelectedVehicle(res)} className="btn btn-dark">Continue <Forward /> </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }

                {selectedVehicle && 
                    <div className="row">
                        <div className="col-md-12">
                            <span className="float-start">
                                <h3>Select Stoppage</h3>
                            </span>
                            <button type="button" className="btn btn-warning btn-lg float-end" onClick={()=>setSelectedVehicle(null)} >
                            {selectedVehicle.vehicleRoute} ({getMasterLabel("vehicle-type",selectedVehicle.vehicleType)}) 
                             | {selectedVehicle.vehicleNumber}
                             <span className="ms-5"> <ArrowBack /> Change</span>
                            </button>
                        </div>

                        <div className="col-md-12">
                            {stoppageList && stoppageList.map(stoppage=>
                                <ExpandableCard  title={stoppage.stoppageName} defaultOpen={false} >
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Stopage Name</th>
                                            <th>Pickup Time</th>
                                            <th>Drop  Time</th>
                                            <th>Fees Term</th>
                                            <th>Fees Month</th>
                                            <th>Fees</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {stoppage.feestructureList.map(stopageDetails=>
                                            <tr key={studentDetails.id} >
                                                <td>{stopageDetails.stoppage}</td>
                                                <td>{stopageDetails.pickupTime}</td>
                                                <td>{stopageDetails.dropTime}</td>
                                                <td>{getMasterLabel("fees-term",stopageDetails.feesTerm)}</td>
                                                <td>{getMasterLabel("month",stopageDetails.feesMonth)}</td>
                                                <td>{stopageDetails.amount}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>


                                <button type="button" className="btn btn-primary btn-lg float-end" onClick={()=>assignTransport(stoppage.stoppageName)} >Select {stoppage.stoppageName}</button>

                                </ExpandableCard>
                                
                            )}
                            
                            {/*
                            

                            */}
                        </div>

                    </div>


                }

            </div>
        </AppLayout>
    )
}

export default withAlert(StudentAssignTransportPage)