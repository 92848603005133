import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout';
import IDCardComponent from '../components/IDCardComponent';
import idCardServices from '../idCardServices';

function StudentIdCard({AppAlert}) {

    let { studentId } = useParams();


    const [studentList, setStudentList] = useState(null)

    const getStudentDetails = () =>{
        idCardServices.getById(studentId).then(res=>{
            setStudentList(res.data)
            if(res.data.length > 0){
                setStudentList(res.data)
            } else {
                AppAlert("No Record found","error");
            }
        }).catch(error=>{
            AppAlert(error.msg);
        })
    }


    useEffect(()=>{
        getStudentDetails();
    },[])

    return (
        <AppLayout>
            <div className="container-fluid mt-4">
                {studentList && <IDCardComponent studentList={studentList} /> }
            </div>
        </AppLayout>
    )
}

export default withAlert(StudentIdCard)