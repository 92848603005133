import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';
import moment from 'moment';
import examGroupServices from '../examGroupServices';

const ExamGroupForm = ({data, AppAlert,refreshList,masters}) => {
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        branch: "",
        class: "",
        section:"",
        examType : "",
        examNumber : "",
        examMonth : "",
        resultType: "",
        resultStatus: "0",
        maxMarks: "",
    };

    const validationRule = Yup.object({
        branch: Yup.string().required("Required"),
        class: Yup.string().required("Required"),
        section: Yup.string().required("Required"),
        examType : Yup.string().required("Required"),
        examNumber : Yup.string().required("Required"),
        examMonth : Yup.string().required("Required"),
        resultType: Yup.string().required("Required"),
        resultStatus: Yup.string().required("Required"),
        maxMarks: Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            branch:data.branch,
            class:data.class,
            section:data.section,
            examType : data.examType,
            examNumber : data.examNumber,
            examMonth : data.examMonth,
            resultType: data.resultType,
            resultStatus: data.resultStatus,
            maxMarks: data.maxMarks,
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        examGroupServices.save(values).then(res =>{
            AppAlert(res.msg);
            refreshList();
            setDialogStatus(false);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    return (
        <>
            {!data &&
                <Tooltip title="Add New">
                    <button type="button" className="btn btn-primary" onClick={addNew} ><Add/> Add New</button>
                </Tooltip>
            }
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Exam Group"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            <div className="col-md-12">
                                                <div className="row">

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Branch" name="branch"  
                                                                options={masters['branch']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Class" name="class"  
                                                                options={masters['class']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Section" name="section"  
                                                                options={masters['section']} multiple={false} 
                                                            />
                                                        </div>
                                                    }
                                                    
                                                    <div className='col-md-12'><h3>Exam Details</h3></div>
                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Exam Type" name="examType"  
                                                                options={masters['exam-type']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="number" 
                                                            label="Exam Number" name="examNumber" 
                                                        />
                                                    </div>

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Exam Month" name="examMonth"  
                                                                options={masters['month']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-6 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Result Type" name="resultType"  
                                                                options={[{value:0,label:"Marks"},{value:1,label:"Grade"}, {value:2,label:"Both (Marks / Grade)"}]} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-6 col-xs-12 p-2 mb-4" >
                                                        <FormikControl control="input" formik={formik} type="number" 
                                                            label="Max Marks" name="maxMarks" 
                                                        />
                                                    </div>

                                                    <hr/>
                                                    <h3>Result Status</h3>
                                                    {masters && 
                                                        <div className="col-md-12 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Result Status" name="resultStatus"  
                                                                options={[{value:0,label:"Not Publish"},{value:1,label:"Published"}]} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    
    )
}

export default ExamGroupForm