import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import withAlert from '../../../hoc/withAlert';
import useMasterList from '../../../hooks/useMasterList';
import AppLayout from '../../../layout/components/AppLayout';
import TransportFeesStructureComponent from '../components/TransportFeesStructureComponent';
import transportFeesStructureServices from '../transportFeesStructureServices';
import transportServices from '../transportServices';


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Link } from 'react-router-dom';


const CellAction = (props) =>{ 
    return (
        <>
            {props.data &&
                <TransportFeesStructureComponent masters={props.masters} AppAlert={props.AppAlert} refreshList={props.getTransportFeesStructureList} data={props.data} />
            }
        </>
    )
}


function TransportDetailsPage({AppAlert}) {
    const {id} = useParams();
    const [masters,getMasterLabel] = useMasterList(["vehicle-type","fees-term", "month"]);


    


    const [transportDetails, setTransportDetails]   = useState(null);
    const getTransportDetails = () =>{
        transportServices.get(id).then(res=>{
            setTransportDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg);
        })
    }

    useEffect(()=>{
        getTransportDetails();
    },[])


    const [transportFeesStructureList, setTransportFeesStructureList]   = useState(null);
    const getTransportFeesStructureList = () =>{
        transportFeesStructureServices.getByTransportId(id).then(res=>{
            setTransportFeesStructureList(res.data);
        }).catch(error=>{
            AppAlert(error.msg);
        })
    }

    useEffect(()=>{
        getTransportFeesStructureList();
    },[])



    const [defaultColDef] = useState({
        floatingFilter: true,
        width:150,
        filter:true,
        sortable: true,
    })

    const columnDefs = [
        {field:"id", width:80, },
        {field:"pickupTime", width:150, },
        {field:"dropTime", width:150, },
        {field:"stoppage", width:120, },
        {field:"Fees Term", valueGetter: params => getMasterLabel("fees-term",params.data.feesTerm)},
        {field:"Payment Month", valueGetter: params => getMasterLabel("month",params.data.feesMonth)},
        {field:"amount", width:120, },
        {field:"id", headerName: 'Action', cellRenderer: CellAction, width:150,cellRendererParams: {masters, AppAlert, getTransportFeesStructureList},},
        
    ]

    return (
        <AppLayout>
            {transportDetails &&
                <div className="container-fluid">
                    <div className="row mt-3">
                        <div className="col-md-4">
                            <strong>Vehicle Number : </strong> {transportDetails.vehicleNumber} <strong> {getMasterLabel("vehicle-type",transportDetails.vehicleType)}</strong>
                        </div>
                        <div className="col-md-4">
                            <strong>Route : </strong> {transportDetails.vehicleRoute}
                        </div>

                        <div className="col-md-4">
                            <strong>Driver Name : </strong> {transportDetails.driverName}
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <h5 className="float-start">Manage Stopage And Fees</h5>
                            <span className="float-end">
                                <TransportFeesStructureComponent AppAlert={AppAlert} masters={masters} refreshList={getTransportFeesStructureList} transportId={id} />
                            </span>
                        </div>
                    </div>


                    <div className="row mt-3">
                    <div className="col-md-12">
                        {transportFeesStructureList && 
                            <div className="ag-theme-alpine" style={{height: 400, width:"auto"}}>
                                <AgGridReact
                                    rowData={transportFeesStructureList}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    >
                                </AgGridReact>
                            </div>
                        }
                    </div>
                </div>
                </div>
            }
        </AppLayout>
    )
}

export default withAlert(TransportDetailsPage);