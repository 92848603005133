import React, { useEffect, useState } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout';
import MessageNavigation from '../components/MessageNavigation';
import MessageTemplateFromComponent from '../components/MessageTemplateFromComponent';
import messageTemplateServices from '../messageTemplateServices';


function MessageTemplatePage({AppAlert}) {


    const [messageTemplateList,setMessageTemplateList] = useState([]);
    const getMessageTemplateList = () =>{
        messageTemplateServices.get().then(res=>{
            setMessageTemplateList(res.data);
        })
    }

    useEffect(()=>{
        getMessageTemplateList();
    },[])

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-md-12">    
                        <span className="float-start"><h4>Messages Templates</h4></span>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <MessageNavigation AppAlert={AppAlert} refreshList={getMessageTemplateList}/>
                    </div>
                    <div className='col-md-8'>
                        <div className='row'>
                                <div className='col-md-4'>
                                    <div className='card p-5'>
                                    <MessageTemplateFromComponent AppAlert={AppAlert} refreshList={getMessageTemplateList}/>
                                    </div>
                                </div>
                            {messageTemplateList.map(messageTemplate=>
                                <div className='col-md-4' key={messageTemplate.id}>
                                    <div className='card'> 
                                        <div className='card-body'>
                                            <div className='fw-bold'>{messageTemplate.subject}</div>
                                            <div className=''>{messageTemplate.message}</div>
                                            <span className='float-end'>
                                                <MessageTemplateFromComponent AppAlert={AppAlert} data={messageTemplate} refreshList={getMessageTemplateList} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(MessageTemplatePage);