import { Cancel, CheckCircle, Pending } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React,{useRef, useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import useMasterList from '../../../hooks/useMasterList';
import AppLayout from '../../../layout/components/AppLayout'
import BranchClassFilter from '../components/BranchClassFilter';
import reportServices from '../reportServices';
import StudentMessageComponent from '../../Communication/components/StudentMessageComponent';

import withAlert from '../../../hoc/withAlert';
import moment from 'moment';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function DueReportGrid({AppAlert}) {
    const [masters] = useMasterList(['branch','class',"feeshead"],"all");
    const [searchingInProgress, setSearchingStatus] = useState(false);

    const [studentReport,setStudentReport] = useState(null);
    const [paymentMonths,setPaymentMonths] = useState(null);
    const getList = (values) =>{
        console.log("values",values);
        setSearchingStatus(true);
        reportServices.dueReport(values).then(res=>{
            setStudentReport(res.report);
            setPaymentMonths(res.paymentMonths);
        }).finally(()=>
            setSearchingStatus(false)
        )
    }


    const gridRef = useRef()
    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:150,
        filter:true,
        sortable: true,
        cellStyle: { 'textTransform': 'uppercase' },
    })
    

    const [columnDefs,setColumnDef] = useState([]);

    const prepairColumnDef = () => {
        if(paymentMonths){
            let colDef = [
                
                {field:"studentName",header: "Student Name"},
                {field:"fatherName",header: "Father Name"},
                {field:"fatherPhone",header: "Father Phone"},
                {field:"admissionNo",headerName:"Admission No", valueGetter: params => params.data.admissionNo+"/"+moment(params.data.admissionYear).format("YY")},
                {field:"class",header: "Class" , valueGetter: params => params.data.classTitle+"-"+params.data.sectionTitle },
            ];

            let x = [];

            for(let i=0;i<paymentMonths.length;i++){
                x =  [...x,{field: paymentMonths[i].monthTitle+"_Payable", width:150}]
                x =  [...x,
                        {
                            headerName: paymentMonths[i].monthTitle+"_Pending", width:150 , valueGetter: params =>
                                {
                                    if(params.data["Total_PaidAmount"] >= params.data["Payable_Till_"+paymentMonths[i].monthTitle]){
                                        return 0;
                                    } else {
                                        let pending =  params.data["Payable_Till_"+paymentMonths[i].monthTitle]- params.data["Total_PaidAmount"];
                                        return pending;
                                    }   
                                } 
                        }
                    ]
                
            }
            
            x =  [...x,{field: "Total_PaidAmount", width:150},] 
            setColumnDef([...colDef,...x]);


        }
    }

    useEffect(()=>{
        prepairColumnDef();
    },[paymentMonths])


    const onBtnExport = () => {
        gridRef.current.api.exportDataAsCsv();
    }


    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-8">
                        <BranchClassFilter masters={masters} searchingInProgress={searchingInProgress} filterData={getList} />
                    </div>
                    <div className="col-md-4">
                        {studentReport && <button type="button" className="btn btn-warning float-end mt-3" onClick={onBtnExport} >Export</button>}
                    </div>
                </div>

                {paymentMonths && studentReport &&


                    <div className="row pt-2">
                        <div className="col-md-12">
                            <div className="ag-theme-alpine" style={{height: 550, width:"auto"}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={studentReport}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    >
                                </AgGridReact>
                            </div>
                        </div>
                    </div>

                }






                
            </div>
        </AppLayout>
    )
}

export default withAlert(DueReportGrid)