import React,{useState,useEffect} from 'react'

function StudentReportGradeSection({masters,getMasterLabel,reportCardDetails,gradeList}) {

    const [studentAssessmentList,setStudentAssessmentList] = useState(null);
    const [selectedAssessmentList,setSelecetdAssessmentList] = useState(null);
    const getAssessmentList = () => {
        setStudentAssessmentList( JSON.parse(reportCardDetails.assessment));
        console.log("assessmentlist",reportCardDetails);
        setSelecetdAssessmentList(reportCardDetails.assessmentList?.split(","))
    }
    const [additionalSubjectList,setAdditionalSubjectList] = useState([]);
    const prepareAdditionalSubjectList = () =>{
        setAdditionalSubjectList( JSON.parse(reportCardDetails.additionalSubjectList));
    }

    useEffect(()=>{
        getAssessmentList();
        prepareAdditionalSubjectList();
    },[reportCardDetails])

    const redableMaster = (masterName) =>{
        let m = masterName.replace("report-","");
        return m.toUpperCase();
    }

    const isObjectEmpty = (objectName) => {
        return Object.keys(objectName).length === 0
    }

    return (
            <div className='row'>
                <div className='col-md-6'>
                    
                    <table className='table table-bordered' style={{borderColor:"#000"}}>
                        <thead>
                            <tr>
                                <th className='text-center' colSpan={2}>CO-SCHOLASTIC</th>
                            </tr>
                            <tr>
                                <th>Activity</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {additionalSubjectList && additionalSubjectList.map(additionalSubject => 
                                <tr>
                                    <th className='align-middle'>{getMasterLabel("subject",additionalSubject.subjectId)}</th>
                                    {additionalSubject.isGrade &&
                                        <th className='text-center align-middle'>
                                            {additionalSubject.grades.internal == ""  &&
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div>{additionalSubject.grades.theory}</div>
                                                    </div>
                                                </div>
                                            }
                                            {additionalSubject.grades.internal != "" &&
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div>Theory</div>
                                                        <div>{additionalSubject.grades.theory}</div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div>Internal</div>
                                                        <div>{additionalSubject.grades.internal}</div>
                                                    </div>
                                                </div>
                                            }
                                        </th>
                                    }
                                    {!additionalSubject.isGrade &&
                                        <th className='text-center align-middle'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div>Theory</div>
                                                    <div>{additionalSubject.marks.obtainedTheory} / {additionalSubject.marks.maxTheory}</div>
                                                </div>
                                                <div className='col-6'>
                                                    <div>Internal</div>
                                                    <div>{additionalSubject.marks.obtainedInternal} / {additionalSubject.marks.maxInternal}</div>
                                                </div>
                                            </div>
                                            
                                        </th>
                                    }
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {selectedAssessmentList && selectedAssessmentList.map(selectedAssessment => 
                <div className='col-6' key={selectedAssessment}>
                    <table className='table table-bordered' style={{borderColor:"#000"}}>
                        <thead>
                            <tr>
                                <th className='text-center' colSpan={2}>{redableMaster(selectedAssessment)}</th>
                                
                            </tr>
                        </thead>
                        <thead>
                            <tr>
                                <th>Activity</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>                            
                            {
                                !isObjectEmpty(studentAssessmentList) 
                                && studentAssessmentList[selectedAssessment]?.length > 0 
                                && studentAssessmentList[selectedAssessment].map(assessment=>
                                    <tr>
                                        <td>{getMasterLabel(selectedAssessment, assessment.assestmentId)}</td>
                                        <td>{getMasterLabel("assessment-grade", assessment.assessmentGrade)}</td>
                                    </tr>
                                )

                            
                            
                            }
                        </tbody>
                    </table>
                </div>
            )}

            </div>
    )
}

export default StudentReportGradeSection