import moment from 'moment';
import React, { useEffect, useState,useRef } from 'react'
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';


import withAlert from '../../../hoc/withAlert'
import useMasterList from '../../../hooks/useMasterList';
import AppLayout from '../../../layout/components/AppLayout'
import userServices from '../../../users/userServices';
import CollectionFilter from '../components/CollectionFilter';
import receiptService from '../receiptServices'

function FeesCollectionReportPage({AppAlert}) {

    
    const [masters] = useMasterList(["class","section","payment-type"],"all");
    const [userDD,setUserDD] = useState();
    const getUserDD = () => {
        userServices.getUserDD().then(res=>{
            setUserDD(state=>[{value:0,label:"For All"},...res.data]);
        })
    }
    useEffect(()=>{
        getUserDD();
    },[]);

    const [filters,setFilters] = useState(null);
    const [collectionReport, setCollectionReport] = useState([]);

    const [paymentTypeTotal,setPaymentTypeTotal] = useState([]);

    useEffect(()=>{
        if(masters){
            setPaymentTypeTotal(masters['payment-type'].reverse());
        }
    },[masters])

    const doTotal = (paymentType) =>{
        let t = {
            payableAmount:0,
            paidAmount:0,
            dues:0,
        }

        if(paymentType === 0){
            collectionReport.forEach(receipt=>{
                t.payableAmount+=receipt.payableAmount;
                t.paidAmount+=receipt.paidAmount;
                t.dues+=receipt.dues;
            })
        }

        collectionReport.forEach(receipt=>{
            if(receipt.paymentType == paymentType ){
                t.payableAmount+=receipt.payableAmount;
                t.paidAmount+=receipt.paidAmount;
                t.dues+=receipt.dues;
            }
        })
        return t;
    }

    useEffect(()=>{
        if(collectionReport && paymentTypeTotal){
            
            let ptt = paymentTypeTotal.map(paymentType=>{
                return {...paymentType, ...doTotal(paymentType.value) }
            });
            setPaymentTypeTotal(ptt);
        }
    },[collectionReport])


    /* Print Work Start */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    /* Print Work End */

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-9">
                        <CollectionFilter userDD={userDD} masters={masters} setFilters={setFilters} setCollectionReport={setCollectionReport} />
                    </div>
                    <div className="col-md-3">
                        <Link to="/invoice/collection/list" className="btn btn-warning mt-3 float-end">Click Here to Export</Link>
                    </div>
                </div>

                <div className="row pt-2">
                    <div className="col-md-12">
                        {collectionReport && collectionReport.length > 0 && <button type="button" className="btn btn-primary float-end" onClick={handlePrint}>Print</button>}
                    </div>
                    <div className="col-md-12 p-2" ref={componentRef}>
                        {collectionReport && collectionReport.length > 0 &&
                            <table className="table table-striped table-sm table-bordered" style={{fontSize:12}}>
                                <thead>
                                    <tr>
                                        <th>Sr #</th>
                                        <th>Receipt #</th>
                                        <th>Received By</th>
                                        <th>Admission No</th>
                                        <th>Student Name</th>
                                        <th>Class</th>
                                        <th>Date</th>
                                        <th>Transection #</th>
                                        <th>Payment Type</th>
                                        <th>Payable Amount</th>
                                        <th>Received Amount</th>
                                        <th>Dues</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { collectionReport.map((receipt,i)=>
                                        <tr key={receipt.id}>
                                            <td>{i+1}</td>
                                            <td><Link to={"/invoice/receipt/"+receipt.id} style={{textDecoration:"none", fontWeight:"bold", color:"#000" }} > {receipt.id}</Link></td>
                                            <td>{receipt.receiverFirstName} {receipt.receiverLastName}</td>
                                            <td><Link to={"/student/details/"+receipt.studentId} style={{textDecoration:"none", fontWeight:"bold", color:"#000" }} > {receipt.admissionNo}</Link> </td>
                                            <td>{receipt.studentFirstName} {receipt.studentLastName}</td>
                                            <td>{receipt.classTitle}-{receipt.sectionTitle}</td>
                                            <td>{moment(receipt.paymentDate).format("ll")}</td>
                                            <td>{receipt.transectionNo}</td>
                                            <td>{receipt.paymentTypeTitle}</td>
                                            <td>{receipt.payableAmount}</td>
                                            <td>{receipt.paidAmount}</td>
                                            <td>{receipt.dues}</td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    {paymentTypeTotal && paymentTypeTotal.map(paymentType =>
                                        <>
                                            {paymentType.paidAmount > 0 &&
                                                <tr className={paymentType.value > 0 ? "":"table-dark"}  key={paymentType.value}>
                                                    <td colSpan="9" > <span className="fw-bold float-end"> {paymentType.value > 0 ? "Total " +paymentType.label : "Net Total"}  </span> </td>
                                                    <td>{paymentType.payableAmount}</td>
                                                    <td>{paymentType.paidAmount}</td>
                                                    <td>{paymentType.dues}</td>
                                                </tr>
                                            }
                                        </>
                                    )}
                                </tfoot>
                            </table>
                        }
                        
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(FeesCollectionReportPage)
/* field:"paymentDate" */