import { ArrowRight } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'

function StudentNavigationComponent({studentId}) {

    return (
        <div className="list-group">
            <Link to={"/student/fees-structure/"+studentId} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                Fee Structures
                <span className=""><ArrowRight/></span>
            </Link>
            <Link to={"/id-card/student/"+studentId} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                ID Card
                <span className=""><ArrowRight/></span>
            </Link>
            <Link to={"/invoice/receipt/student/"+studentId} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                Paid Fee Receipt
                <span className=""><ArrowRight/></span>
            </Link>

            <Link to={"/student/attendance-report/"+studentId} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                Attendance Report
                <span className=""><ArrowRight/></span>
            </Link>

            
            <Link to={"/student/report-card/"+studentId} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                Report Card Type One
                <span className=""><ArrowRight/></span>
            </Link>
            <Link to={"/student/report-card/type-two/"+studentId} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                Report Card Type Two
                <span className=""><ArrowRight/></span>
            </Link>
                
                
            

            
        </div>
    )
}

export default StudentNavigationComponent