import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';

import holidayCalendarServices from '../holidayCalendarServices';
import moment from 'moment';

const HolidayCalendarForm = ({data, AppAlert,refreshList}) => {
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        holidayDate : moment().format("YYYY-MM-DD"),
        occasion : "",
    };



    const validationRule = Yup.object({
        holidayDate: Yup.string().required("Required"),
        occasion : Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            holidayDate : data.holidayDate,
            occasion : data.occasion,
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        holidayCalendarServices.save(values).then(res =>{
            AppAlert(res.msg);
            refreshList();
            setDialogStatus(false);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    return (
        <>
            {!data &&
                <Tooltip title="Add New">
                    <button type="button" className="btn btn-primary" onClick={addNew} ><Add/> Add New</button>
                </Tooltip>
            }
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Holiday List"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            <div className="col-md-12">
                                                <div className="row">

                                                    <FormikControl control="input" formik={formik} type="date" 
                                                        label="Holiday Date" name="holidayDate" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />


                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Occasion" name="occasion" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    
    )
}

export default HolidayCalendarForm