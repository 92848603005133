import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';
import messageTemplateServices from '../messageTemplateServices';

function MessageTemplateFromComponent({data, AppAlert,refreshList, masters}) {

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);



    /* Form Work  */
    const initialValues = {
        subject:"",
        message:"",
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        messageTemplateServices.save(values).then(res =>{
            AppAlert(res.msg);
            handleClose();
            refreshList();
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        subject: Yup.string().required("Required"),
        message: Yup.string().required("Required"),
    });

    /* Form Work  */


    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            subject: data.subject,
            message: data.message,
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }


    const handleClose  = () =>{
        setDialogStatus(false);
    }



    return (
        <>
            {!data &&
                <Tooltip title="Add New">
                    <IconButton onClick={addNew} style={{padding:"0px"}}>
                        <Add sx={{ fontSize: 40 }} /> Add New
                    </IconButton>
                </Tooltip>
            }
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Template Message"}</DialogTitle>
                                    <DialogContent>
                                    <div className="row p-2">
                                                 
                                                    <>
                                                        <div className="col-md-12 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Subject" name="subject" 
                                                            />
                                                        </div>
                                                        <div className="col-md-12 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text"
                                                                multiline rows={4}
                                                                label="Message" name="message" 
                                                            />
                                                        </div>
                                                    </>
                                                
                                            </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-warning" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}

export default MessageTemplateFromComponent