import React, { useState,useEffect } from 'react'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { CircularProgress } from '@mui/material';

function ClassTimetableFilterComponent({masters,onSearch,searchingInProgress}) {

    const initialValues = {
        branch:1,
        class:"",
    }

    const onSubmit = (values, onSubmitProps) => {
       onSearch(values);
    }

    const validationRule = Yup.object({
        branch:Yup.string().required("Required"),
        class:Yup.string().required("Required"),
    });

  return (
    <Formik
            initialValues={initialValues}
            validationSchema={validationRule}
            onSubmit={onSubmit}
            enableReinitialize validateOnMount
        >
            {
                (formik) => {
                    //console.log(formik.values);
                    return (
                        <Form>
                            <div className="row p-2">
                                {masters && 
                                        <>


                                            <div className="col-12 p-2" >
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Branch" name="branch"  
                                                    options={masters['branch']} multiple={false} 
                                                />
                                            </div>

                                            <div className="col-12 p-2" >
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Class" name="class"  
                                                    options={masters['class']} multiple={false} 
                                                />
                                            </div>

                                            {/*
                                            <div className="col-12 p-2" >
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Subject" name="subject"  
                                                    options={masters['subject']} multiple={false} 
                                                />
                                            </div>
                                            */}

                                        </>
                                }
                                <div className="col-12 p-2" >
                                {searchingInProgress ? 
                                    <CircularProgress />
                                    :
                                    <button className="btn btn-success float-end" type="submit">Search</button>
                                }
                                </div>
                            </div>
                        </Form>
                    )
                }
            }
        </Formik>
  )
}

export default ClassTimetableFilterComponent