import api from "../../api/api";

const getAchievementByStudentId = (studentId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/student/achievement/"+studentId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const addAchievement = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/student/achievement/add",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const remove = (id) => {
    return new Promise((resolve, reject)=>{
        api.delete("/student/achievement/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const studentAchievementServices = {
    getAchievementByStudentId,
    addAchievement,
    remove
}

export default studentAchievementServices;