import React, { useEffect, useRef, useState } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import feesSettingServices from '../feesSettingServices';
import systemMasterServices from '../../../master/systemMasterServices';
import FeesSettingForm from '../components/FeesSettingForm';
import useMasterList from '../../../hooks/useMasterList';
import useSessionList from '../../../hooks/useSessionList';


const CellAction = (props) =>{ 
    return (
        <>
            {props.data &&
                <FeesSettingForm masters={props.masters} AppAlert={props.AppAlert} refreshList={props.getFeesList} data={props.data} />
            }
        </>
    )
}


const FeesSettingPage = ({AppAlert}) => {

    const gridRef = useRef();

    const [feesList,setFeesList] = useState(null);
    const [defaultColDef] = useState({
        floatingFilter: true,
        width:150,
        filter:true,
        sortable: true,
    })

    const [columnDefs,setColumnDefs] = useState()


    const onGridReady =(params)=>{
        
        getFeesList();
    }

    const getFeesList = () =>{
        feesSettingServices.getList().then((res)=>{
            setColumnDefs([
                {field:"branch", valueGetter: params => getMasterLabel("branch",params.data.branch)},
                {field:"sessionYear"},
                {field:"feesClassGroup",valueGetter: params => getMasterLabel("fees-class-group",params.data.feesClassGroup)},
                {field:"month",valueGetter: params => getMasterLabel("month",params.data.month)},
                {field:"feesHead",valueGetter: params => getMasterLabel("fees-head",params.data.feesHead) },
                {field:"feesTerm", valueGetter: params => getMasterLabel("fees-term",params.data.feesTerm) },
                {field:"amount", },
                //{field:"id", headerName: 'Action', cellRenderer: CellAction, width:150,cellRendererParams: {masters, AppAlert, getFeesList},},
            ]);
            setFeesList(res.data);
        }).catch(error=>{
        })
    }

    const [masters,getMasterLabel] = useMasterList(["branch","fees-class-group","fees-term","fees-head","month"]);

    
    const exportData = () => {
        gridRef.current.api.exportDataAsCsv();
    }
    

    return (
        <AppLayout>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-12" >
                        <button type="button" className="btn btn-primary float-end" onClick={exportData}  >Export</button>
                        {/*<span className="float-end">{masters && <FeesSettingForm masters={masters} AppAlert={AppAlert} refreshList={getFeesList} /> }</span> */}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        { masters &&
                            <div className="ag-theme-alpine" style={{height: 500, width:"auto"}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={feesList}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    onGridReady={(params)=>{ onGridReady(params)}}
                                    >
                                </AgGridReact>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(FeesSettingPage);