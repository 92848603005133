import api from "../../api/api";

const getById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/attendance/student/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/attendance/student",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const update = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/attendance/student/update",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateInline = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("/attendance/student",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const  serialize = (obj) =>{
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}


const filterStudentList = (filters) =>{
    return new Promise((resolve, reject)=>{
        api.get("/attendance-filter/student?"+serialize(filters)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const studentBatchAttendanceList = (batchId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/attendance-filter/student/batch/"+batchId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getAttendanceBatchList = (selectedDate) =>{
    return new Promise((resolve, reject)=>{
        api.get("/attendance/report/batch-list?date="+selectedDate).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const attendanceServices = {
    getById,
    updateInline,
    save,
    update,


    filterStudentList,
    studentBatchAttendanceList,
    getAttendanceBatchList,
}
export default attendanceServices;