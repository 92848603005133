import { CheckBox, CheckBoxOutlineBlankOutlined } from '@mui/icons-material'

import React, { useState } from 'react'

import TextInline from '../../../components/InlineEdit/TextInline'
import studentFeesStructureService from '../../student/studentFeesStructureServices'






const StudentPendingList = ({studentFeesStructure,getMasterLabel,selectFees}) => {

    
    

    return (
        <>
            <div className="row">
                <div className="">Pending List </div>
                <div className="col-md-12 table-responsive" >
                    {studentFeesStructure && 
                        <table className="table table-sm table-striped table-hover" style={{cursor:"pointer"}}>                
                            <tbody>
                            {
                                studentFeesStructure.map(feesStructure=>
                                    feesStructure.paymentStatus <= 4 &&
                                    <tr onClick={()=>selectFees(feesStructure.id)} key={feesStructure.id}>
                                        <td>
                                            {feesStructure.paymentStatus === 1 &&
                                            <>
                                            {feesStructure.selected ?  <CheckBox className="text-success"/> : <CheckBoxOutlineBlankOutlined/> }
                                            </>
                                            }
                                        </td>
                                        <td>{getMasterLabel("fees-head",feesStructure.feesHead)}</td>
                                        <td>{getMasterLabel("month",feesStructure.paymentMonth)}</td>
                                        <td>{feesStructure.paymentStatus === 1 ? 
                                            //<TextInline name="feesAmount" editId={feesStructure.id} value={feesStructure.feesAmount} updateInline={studentFeesStructureService.updateInline} />
                                            <span >{feesStructure.feesAmount}</span>
                                            : 
                                            <span className="text-success">{feesStructure.feesAmount} {getMasterLabel("payment-status",feesStructure.paymentStatus)}</span>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    }
                </div>
            </div>
        </>
    )
}

export default StudentPendingList