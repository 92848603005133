import moment from 'moment'
import React, { useEffect, useState } from 'react'
import schoolServices from "../../settings/schoolServices"

function ReportCardHeaderTypeTwo( {studentDeatils,getMasterLabel,schoolDetails}) {
   
    
    
  return (
    <>
        <div className='row'>
            <div className='col-md-12'>
                {schoolDetails &&  schoolDetails.latterHeader !==null ?
                    <img className="v2-image" src={schoolDetails.letterHeader}  />
                    :
                    <div className='d-flex justify-content-around'>
                        <img style={{height:50}} src={schoolDetails.logo}  />
                        <h3>{schoolDetails.title}</h3>
                    </div>
                }
                <hr/>
            </div>
        </div>
        <div className='row mb-2'>
            <div className='col-md-12 text-center'>
                <h4 className='text-danger'>Student's Profile</h4>
            </div>
            <div className='col-md-5'>
                <div className="row">
                    <div className="col-6 fw-bold">Name</div>
                    <div className="col-6">{studentDeatils.firstName} {studentDeatils.lastName}</div>
                </div>

                

                <div className="row">
                    <div className="col-6 fw-bold">Father Name</div>
                    <div className="col-6">{studentDeatils.fatherName}</div>
                </div>
                <div className="row">
                    <div className="col-6 fw-bold">Mother Name</div>
                    <div className="col-6">{studentDeatils.motherName}</div>
                </div>

                

                
            </div>

            <div className='col-md-2'>
                
            </div>

            <div className='col-md-5'>
                <div className="row">
                    <div className="col-6 fw-bold">Class</div>
                    <div className="col-6">{getMasterLabel("class",studentDeatils.class)}-{getMasterLabel("section",studentDeatils.section)}</div>
                </div>

                <div className="row">
                    <div className="col-6 fw-bold">Date of Birth</div>
                    <div className="col-6">{ moment(studentDeatils.dob).format("LL")}</div>
                </div>

                <div className="row">
                    <div className="col-6 fw-bold">Admission No</div>
                    <div className="col-6">{studentDeatils.admissionNo} / { moment(studentDeatils.admissionYear).format("YY")}</div>
                </div>
                
            </div>
        </div>
    </>
  )
}

export default ReportCardHeaderTypeTwo