
import React,{useEffect, useState} from 'react'
import { Dialog, DialogTitle, DialogActions, DialogContent,  IconButton, LinearProgress, Menu, MenuItem, Divider} from "@mui/material";
import { Email, Send } from '@mui/icons-material';
import messageServices from '../messageServices';

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import moment from 'moment';



function StudentMessageComponent({studentId,AppAlert}) {

    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);
    const [formValues, setFormValues] = useState(null);

    /* Form Work  */
    const initialValues = {
        subject:"",
        message:"",
    }

    const validationRule = Yup.object({
        subject: Yup.string().required("Required"),
    });


    const handleClose = () =>{
        setDialogStatus(false)
    }

    const onSubmit = (values, onSubmitProps) => {
        values['to'] = [studentId];
        setSavingStatus(true)
        messageServices.send(values).then(res =>{
            AppAlert(res.msg);
            getStudentMessageList();
            handleClose();
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const [studentMessageList,setStudentMessageList] = useState([]);
    const getStudentMessageList = () =>{
        messageServices.getByStudentId(studentId).then(res=>{
            setStudentMessageList(res.data);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            //setSearchingStatus(false);
        })
        
    }

    const [templateList,setTemplateList] = useState([]);
    const getTemplatList = () =>{
        messageServices.getTemplateList().then(res=>{
            setTemplateList(res.data);
        })
    }

    

    
    

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenuOpen = (event) => {
        getTemplatList();
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (template) =>{
        console.log("template",template);
        if(template !== null ){
            let autoFill = {
                subject: template.subject,
                message: template.message,
            }
            setFormValues(autoFill);
        } else {
            setFormValues(null);
        }
        setDialogStatus(true)
        handleMenuClose();
    }


    

    

    return (
        <>
            <IconButton style={{padding:0}}>
                <Email onClick={handleMenuOpen} color="primary" />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={()=>handleMenuClick(null)}>Compose</MenuItem>
                <Divider />
                {templateList.map(template=>
                    <MenuItem onClick={()=>handleMenuClick(template)}>{template.subject}</MenuItem>
                )}
            </Menu>


            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
            >


                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Message"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <>
                                                        <div className="col-md-12 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Subject" name="subject" 
                                                            />
                                                        </div>
                                                        <div className="col-md-12 col-xs-12 p-2" >
                                                            <FormikControl control="input" formik={formik} type="text"
                                                                multiline rows={4}
                                                                label="Message" name="message" 
                                                            />
                                                        </div>
                                                    </>
                                                </div>
                                        
                                                <div className="row">
                                                { /*  JSON.stringify(formik.values) */}
                                                </div>
                                                {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                            </div>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit" > Send <Send/></button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            
                                            </>
                                        }
                                    </DialogActions>
                                    <DialogContent>
                                        <div className='row'>
                                            
                                            {studentMessageList.length == 0 ?
                                                <div className='col-md-12 text-center'>
                                                    <button type='button' onClick={getStudentMessageList} className='btn btn-primary'>Show Sent Messages</button>
                                                </div>
                                                :
                                                <div className='col-md-12'>
                                                    <table className='table'>
                                                        <tbody>
                                                            {studentMessageList.map(message=>
                                                                <tr>
                                                                    <td>
                                                                        <div className='fw-bold'>{message.message_data.subject}</div>
                                                                        <div>{message.message_data.message}</div>
                                                                    </td>
                                                                    <td>
                                                                        {moment(message.created_at).fromNow()}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                    </DialogContent>
                                </Form>
                            )
                        }
                    }
                </Formik>

                
            </Dialog>
        </>
    )
}

export default StudentMessageComponent