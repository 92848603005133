import { Cancel, CheckCircle, Pending, Print } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React,{useState,useRef} from 'react'
import { Link } from 'react-router-dom';
import useMasterList from '../../../hooks/useMasterList';
import AppLayout from '../../../layout/components/AppLayout'
import BranchClassFilter from '../components/BranchClassFilter';
import reportServices from '../reportServices';
import StudentMessageComponent from '../../Communication/components/StudentMessageComponent';

import withAlert from '../../../hoc/withAlert';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

function DueReport({AppAlert}) {
    const [masters] = useMasterList(['branch','class',"feeshead"],"all");
    const [searchingInProgress, setSearchingStatus] = useState(false);
    

    const [studentReport,setStudentReport] = useState(null);
    const [paymentMonths,setPaymentMonths] = useState(null);
    const getList = (values) =>{
        console.log("values",values);
        setSearchingStatus(true);
        reportServices.dueReport(values).then(res=>{
            setStudentReport(res.report);
            setPaymentMonths(res.paymentMonths);
        }).finally(()=>
            setSearchingStatus(false)
        )
    }

    const doPendingTotal = (month,) =>{
        let total = 0;
        for(let i=0; i<studentReport.length;i++){
            if(studentReport[i]["Total"+"_PaidAmount"] < studentReport[i]["Payable_Till_"+month.monthTitle]){
                total = total + parseInt(studentReport[i]["Payable_Till_"+month.monthTitle]) - parseInt(studentReport[i]["Total"+"_PaidAmount"])
            }      
        }
        return total;
    }
    const doTotal = () =>{
        let total = 0;
        for(let i=0; i<studentReport.length;i++){
            total = total + parseInt(studentReport[i]["Total"+"_PaidAmount"]);
        }
        return total;
    }


    /* Print Work Start */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    /* Print Work End */
    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-8">
                        <BranchClassFilter masters={masters} searchingInProgress={searchingInProgress} filterData={getList} />
                    </div>
                    <div className="col-md-4">
                        <Link to="/report/due-report-grid" className='btn btn-warning mt-3' >Export</Link>
                        {studentReport && studentReport.length > 0 && 
                            <button onClick={handlePrint} className="btn btn-primary mt-3 float-end" ><Print/> Print</button>
                        }
                    </div>

                </div>
                <div className="row-mt-3">
                    <div className="col-md-12">
                        {studentReport && studentReport.length > 0 && 
                            <table className="table table-striped table-sm table-bordered border-dark" ref={componentRef} style={{fontSize:10}}>
                                <thead>
                                    <tr>
                                        <th className="border-dark" >#</th>
                                        <th className="border-dark table-info" >Student Name</th>
                                        <th className="border-dark table-info" >Father Name</th>
                                        <th className="border-dark table-info">Admission No</th>
                                        <th className="border-dark table-info">Class</th>
                                        
                                        {paymentMonths.map(month=>
                                            <>
                                                <th colSpan="2" className={month.month % 2 == 1 ? "table-warning text-center border-dark":"table-primary text-center border-dark" }>{month.monthTitle}</th>
                                            </>
                                        )}
                                        <th colSpan="5" className="text-center bg-dark text-white">Collected</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {studentReport.map((student,i)=>
                                        <tr key={student.id}>
                                            <td>{i+1}</td>
                                            <td className="table-info border-dark"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "80px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                <div className='d-flex justify-content-between'>
                                                    <StudentMessageComponent studentId={student.id} AppAlert={AppAlert} />
                                                    {student.studentName}
                                                </div>
                                            </td>
                                            <td className="table-info border-dark"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "80px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {student.fatherName}
                                                <br/>
                                                <strong>{student.fatherPhone}</strong>
                                            </td>
                                            <td className="table-info border-dark">
                                                <Link className="text-dark border-dark" target="_blank" to={"/student/details/"+student.id}>{student.admissionNo}/ {moment(student.admissionYear).format("YY")}</Link>
                                            </td>
                                            <td className="table-info border-dark">
                                                {student.classTitle}-{student.sectionTitle}
                                            </td>
                                            {paymentMonths.map(month=>
                                            <>
                                                <td className={month.month % 2 == 1 ? "table-warning border-dark":"table-primary border-dark" }>
                                                    <Link className="text-dark" target="_blank" to={"/student/fees-structure/"+student.id}>
                                                        {/*student[month.monthTitle+"_Score"]} / {student[month.monthTitle+"_Total"]*/} 
                                                        {student[month.monthTitle+"_Payable"]} 
                                                    </Link>
                                                </td>

                                                {/*
                                                <td>
                                                    {student[month.monthTitle+"_Score"] === 0 && <Cancel style={{ color: "#900" }} /> }
                                                    {student[month.monthTitle+"_Score"] > 0 && student[month.monthTitle+"_Score"] < student[month.monthTitle+"_Total"] && <Pending style={{color:"#ffa500"}} /> }
                                                    {student[month.monthTitle+"_Score"] === student[month.monthTitle+"_Total"] && <CheckCircle style={{ color: "#090" }} /> }
                                                </td>
                                                */}
                                                
                                                <td>
                                                    { /* student["Payable_Till_"+month.monthTitle] */}
                                                    {student["Total"+"_PaidAmount"] >= student["Payable_Till_"+month.monthTitle] && <CheckCircle style={{ color: "#090" }} />}
                                                    {student["Total"+"_PaidAmount"] < student["Payable_Till_"+month.monthTitle] &&
                                                        <span className="text-danger">
                                                            { student["Payable_Till_"+month.monthTitle] - student["Total"+"_PaidAmount"] }
                                                        </span>
                                                    }
                                                </td>
                                            </>
                                            )}
                                            <td className="table-success border-dark"><Link className="text-dark" target="_blank" to={"/invoice/receipt/student/"+student.id}>{student["Total"+"_PaidAmount"]}</Link></td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={5}>Total</td>
                                        {paymentMonths.map(month=>
                                            <>
                                                <th>
                                                    {month.monthTitle} Pending
                                                </th>
                                                <th>
                                                    {doPendingTotal(month)}
                                                </th>
                                            </>
                                        )}
                                        <th>{doTotal()}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(DueReport)