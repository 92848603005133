import React, {useEffect, useState } from 'react'

import withAlert from '../../../hoc/withAlert';
import systemMasterServices from '../../../master/systemMasterServices';
import teacherServices from '../teacherServices';





import { Chip, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress} from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { AddCircle} from '@mui/icons-material';



function TeacherAssignedClassList({teacherId,AppAlert}) {

    /* Get Master Details Start */
    const [masters,setMasters] = useState(null);
    const getMasterList = () => {
        systemMasterServices.get([
            "branch","class","section","subject"
        ]).then(res=>{
            let mdata = res
            mdata["subject"] = [{value:0,label:"Class Teacher"},...mdata["subject"]]
            setMasters(mdata);
        })
    }
    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }

    useEffect(()=>{
        getMasterList();
    },[])
    /* Get Master Details End */


    /* Get Asigned class List Start */
    const [assignedClassList,setAssignedClassList ] = useState([]);
    const getAssignedClassList = () =>{
        teacherServices.getAssignedClassList(teacherId).then(res=>{
            setAssignedClassList(res.data)
        }).catch((error)=>{
            AppAlert("Somethig went wrong whill loading assigned class list ","error")
        })
    }
    useEffect(()=>{
        getAssignedClassList();
    },[teacherId])

    /* Get Asigned class List End */


    /* Form Work  */
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        teacherId:teacherId,
        branch:"",
        class:"",
        section:"",
        subject:"",
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        teacherServices.assignClass(values).then(res =>{
            AppAlert(res.msg);
            handleClose();
            getAssignedClassList();
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
        
    }

    const validationRule = Yup.object({
        branch: Yup.string().required("Required"),
        class: Yup.string().required("Required"),
        section: Yup.string().required("Required"),        
    });

    /* Form Work  */
    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    const unAssign = (id) =>{
        teacherServices.removeAssignedClass(id).then(res=>{
            AppAlert("Removed");
            getAssignedClassList();
        }).catch(error=>{
            AppAlert("Something wend wrong","remove")
        })
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <strong className="float-start mt-2">Assigned Class : </strong>
                    <span className="float-start">
                        {assignedClassList.length > 0 && masters && assignedClassList.map(assignedClass=>
                            <Chip 
                                key={assignedClass.id} 
                                onDelete={()=>unAssign(assignedClass.id)} 
                                label={getMasterLabel("class",assignedClass.class)+" - "+getMasterLabel("section",assignedClass.section)+" - "+getMasterLabel("subject",assignedClass.subject)} 
                                color="primary"
                                className="m-1"
                            />
                        )}
                        
                        <button type="button" className="btn btn-outline-dark btn-sm m-1 rounded-pill" onClick={addNew}><AddCircle /> Add New</button>
                    </span>
                </div>
            </div>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Assign Class"}</DialogTitle>
                                    <DialogContent>
                                    <div className="row p-2">
                                                {masters && 
                                                    <>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Branch" name="branch"  
                                                                options={masters['branch']} multiple={false} 
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Class" name="class"  
                                                                options={masters['class']} multiple={false} 
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Section" name="section"  
                                                                options={masters['section']} multiple={false} 
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-xs-12 p-2" >
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Subject" name="subject"  
                                                                options={masters['subject']} multiple={false} 
                                                            />
                                                        </div>

                                                    </>
                                                }
                                            </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-warning" type="submit">Add</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}

export default withAlert(TeacherAssignedClassList);