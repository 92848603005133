import React, {useEffect, useState} from 'react'
import { ArrowBackIos, ArrowRight,Circle,CropDin,PanoramaFishEye,PhoneAndroid,Warning } from '@mui/icons-material'
import { Dialog, DialogTitle, DialogActions, DialogContent,  IconButton, Tooltip, LinearProgress } from "@mui/material";
import useMasterList from '../../../hooks/useMasterList';
import moment from 'moment';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Accessibility } from '@mui/icons-material';
import studentAchievementServices from '../studentAchievementServices';

function StudentAchievement({studentDetails,AppAlert}) {
    const [dialogStatus, setDialogStatus] = useState(false);
    const [dialogFormStatus, setDialogFormStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);
    const [masters,getMasterLabel] = useMasterList(['achievement']);
    
    const initialValues = {
        achievementId : "",
        rank : "",
        studentId:studentDetails.id,
        achievementDate : moment().format("YYYY-MM-DD"),
        remark : "",
    };

    const validationRule = Yup.object({
        achievementId  : Yup.string().required("Required"),
        achievementDate: Yup.string().required("Required"),
        remark: Yup.string().required("Required"),
    });

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true);
        studentAchievementServices.addAchievement(values).then(res=>{
            AppAlert("Success");
            getAchievementList();
            setDialogStatus(true);
            setDialogFormStatus(false);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false)
        })
    }

    const handleClose = () =>{
        setDialogStatus(false)
    }

    const openAddAchievementForm = () =>{
        setDialogStatus(false);
        setDialogFormStatus(true);
    }
    const handleFormClose = () =>{
        setDialogFormStatus(false)
    }

    const [achievementList,setAchievementList] = useState(null);
    const getAchievementList = () =>{
        studentAchievementServices.getAchievementByStudentId(studentDetails.id).then(res=>{
            setAchievementList(res.data);
        }) 
    }
    useEffect(()=>{
        getAchievementList();
    },[studentDetails])
    return (
        <>
            <div className="list-group">
                <button type="button" className="list-group-item list-group-item-action d-flex justify-content-between align-items-center" onClick={()=>{setDialogStatus(true)}}>
                    Achievements
                    <span className=""><ArrowRight/></span>
                </button>
            </div>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent> 
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header ">
                                    <Accessibility/> Achievements
                                    <button type="button" className='btn btn-outline-primary float-end' onClick={openAddAchievementForm}>Add</button>
                                </div>
                                <div className="card-body bg-light">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <td>Achivement</td>
                                                        <td>Achivement Date </td>
                                                        <td>Rank</td>
                                                        <td>Remark</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {achievementList && achievementList.map(achievement=>
                                                        <tr>
                                                            <td>{getMasterLabel("achievement", achievement.achievementId)}</td>
                                                            <td>{ moment( achievement.achievementDate ).format("LL")}</td>
                                                            <td>{achievement.rank}</td>
                                                            <td>{achievement.remark}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" onClick={()=>{handleClose()} } className="btn btn-danger">Close</button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={dialogFormStatus}
                keepMounted
                onClose={handleFormClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Add Acheivement"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    

                                                    {masters && 
                                                        <div className="col-md-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Achievement" name="achievementId"  
                                                                options={masters['achievement']} multiple={false} 
                                                            />
                                                        </div>
                                                    }
                                                    

                                                    <div className="col-md-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="number" 
                                                            label="Rank" name="rank" 
                                                        />
                                                    </div>

                                                    <div className="col-md-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="date" 
                                                            label="Achivement Date" name="achievementDate" 
                                                        />
                                                    </div>

                                                    <div className="col-md-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Remark" name="remark" 
                                                        />
                                                    </div>
                                                    

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleFormClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>

                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )
}

export default StudentAchievement