import React,{useEffect, useState} from 'react'
import { ArrowRight } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent} from "@mui/material";
import teacherServices from '../teacherServices';
import useMasterList from '../../../hooks/useMasterList';
import moment from 'moment';


function TearcherLeaveBalance({teacherDetails}) {

    const [dialogStatus, setDialogStatus] = useState(false);
    const [leaveBalance,setLeaveBalance] = useState(false);
    const [masters,getMasterLabel] = useMasterList(["staff-leave-type"]);
    
    const getLeaveBalance = () =>{
        teacherServices.getTeacherLeaveBalance(teacherDetails.id).then(res=>{
            setLeaveBalance(res.data);
        })
    }
    useEffect(()=>{
        getLeaveBalance();
    },[])


    const handleClose = () =>{
        setDialogStatus(false)
    }

    return (
        <>

            <div className="list-group">
                <button type="button" className="list-group-item list-group-item-action d-flex justify-content-between align-items-center" onClick={()=>{setDialogStatus(true)}}>
                    Leave Balance
                    <span className=""><ArrowRight/></span>
                </button>
            </div>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
            <DialogContent> 
                <div className="row">
                    {leaveBalance &&
                        <div className="col-12">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='bg-warning p-3 rounded'>
                                        <h3>Pending : {leaveBalance.pending.length}</h3>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='bg-success text-white p-3 rounded'>
                                        <h3>Approved : {leaveBalance.approved.length}</h3>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='bg-danger text-white p-3 rounded'>
                                        <h3>Rejected : {leaveBalance.rejected.length}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='card mt-2'>
                                        <div className='card-header bg-warning'>Pending</div>
                                        <div className='card-body'>
                                            {leaveBalance.pending.map(leaveRequest =>
                                                <div className='row' key={leaveRequest.id}>
                                                        <div className='col-md-6'>
                                                            {leaveRequest.startDate == leaveRequest.endDate ?
                                                                <div>For : <strong>{moment(leaveRequest.startDate).format("LL")} </strong></div>
                                                                :
                                                                <div>From : <strong> {moment(leaveRequest.startDate).format("LL")} to {moment(leaveRequest.endDate).format("LL")}</strong></div>
                                                            
                                                            }
                                                            
                                                            <div>{getMasterLabel("staff-leave-type",leaveRequest.leaveType)}</div>
                                                        </div>
                                                        <div className='col-md-6'>{ moment(leaveRequest.created_at).format("LL")} </div>
                                                        <hr/>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='card mt-2'>
                                        <div className='card-header bg-success text-white'>Approved</div>
                                        <div className='card-body'>
                                            {leaveBalance.approved.map(leaveRequest =>
                                                <div className='row' key={leaveRequest.id}>
                                                        <div className='col-md-6'>
                                                            {leaveRequest.startDate == leaveRequest.endDate ?
                                                                <div>For : <strong>{moment(leaveRequest.startDate).format("LL")} </strong></div>
                                                                :
                                                                <div>From : <strong> {moment(leaveRequest.startDate).format("LL")} to {moment(leaveRequest.endDate).format("LL")}</strong></div>
                                                            
                                                            }
                                                            
                                                            <div>{getMasterLabel("staff-leave-type",leaveRequest.leaveType)}</div>
                                                        </div>
                                                        <div className='col-md-6'>{ moment(leaveRequest.created_at).format("LL")} </div>
                                                        <hr/>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='card mt-2'>
                                        <div className='card-header bg-danger text-white'>Rejected</div>
                                        <div className='card-body'>
                                            {leaveBalance.approved.map(leaveRequest =>
                                                <div className='row' key={leaveRequest.id}>
                                                        <div className='col-md-6'>
                                                            {leaveRequest.startDate == leaveRequest.endDate ?
                                                                <div>For : <strong>{moment(leaveRequest.startDate).format("LL")} </strong></div>
                                                                :
                                                                <div>From : <strong> {moment(leaveRequest.startDate).format("LL")} to {moment(leaveRequest.endDate).format("LL")}</strong></div>
                                                            
                                                            }
                                                            
                                                            <div>{getMasterLabel("staff-leave-type",leaveRequest.leaveType)}</div>
                                                        </div>
                                                        <div className='col-md-6'>{ moment(leaveRequest.created_at).format("LL")} </div>
                                                        <hr/>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                
                                    
                                

                                
                            </div>
                        </div>
                    }
                </div>
            </DialogContent>
                <DialogActions>
                    <button type="button" onClick={()=>{handleClose()} } className="btn btn-danger">Close</button>
                </DialogActions>
            </Dialog>
        </>
        
    )
}

export default TearcherLeaveBalance