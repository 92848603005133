import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import studentSubjectServices from '../studentSubjectServices';
import useMasterList from '../../../hooks/useMasterList'
import Chip from '@mui/material/Chip';

function StudentSubjects({studentId,AppAlert}) {
    
    const [dialogStatus, setDialogStatus] = useState(false)
    const [savingInProgress, setSavingStatus] = useState(false);
    const [masters,getMasterLabel] = useMasterList(['subject']);

    const [combinationList,setCombinationList] = useState(null)
    const getCombinationList = () =>{
        studentSubjectServices.getCombinationList().then(res=>{
            setCombinationList(res.data);
        })
    }

    const showDialog = () =>{
        getCombinationList();
        setDialogStatus(true);
    }
    const handleClose = () =>{
        setDialogStatus(false);
    }

    const assign = (subjectGroupId) =>{
        let payload={
            studentId:studentId,
            subjectGroupId:subjectGroupId
        }
        studentSubjectServices.assignSubjects(payload).then(res=>{
            getStudentSubjectList();
        }).catch(error=>{
            console.log(error);
        }).finally(()=>{
            setDialogStatus(false)
        })
    }


    const [studentSubjectList,setStudentSubjectList] = useState(null);

    const getStudentSubjectList = () =>{
        studentSubjectServices.getSubjectByStudentId(studentId).then(res=>{
            setStudentSubjectList(res.data);
        })
    }

    useEffect(()=>{
        getStudentSubjectList();
    },[])


    const removeSubject = (id) =>{
        studentSubjectServices.remove(id).then(res=>{
            AppAlert("Removed Successfully","error")
            getStudentSubjectList();
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    return (
        <>
            {masters && studentSubjectList &&  studentSubjectList.map(studentSubject=>
                <Chip
                    key={studentSubject.id}
                    label={getMasterLabel("subject",studentSubject.subjectId)}
                    variant="outlined"
                    onDelete={()=>removeSubject(studentSubject.id)}
                />
            )}
            {studentSubjectList && studentSubjectList.length === 0 &&
                <button type='button' className='btn btn-primary' onClick={showDialog} >Assign Subjects</button>
            }
            
            
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Assign Subject"}</DialogTitle>
                <DialogContent>
                    <div className='row'>
                        
                            {combinationList && combinationList.map(combination=>
                                <div className='col-md-4' key={combination.id}>
                                    <div className='card'>
                                        <div className='card-header'>{combination.title}</div>
                                        <div className='card-body'>
                                            {combination.subjectList.map($subject=>
                                                <h5>{getMasterLabel("subject",$subject.subjectId)}</h5>
                                            )}
                                        </div>
                                        <div className='card-footer'>
                                            <button type='button' className='btn btn-primary' onClick={()=>{assign(combination.id)}}>Select</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default StudentSubjects