import { TextField } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AppLayout from '../../../layout/components/AppLayout';
import systemMasterServices from '../../../master/systemMasterServices';
import attendanceServices from '../attendanceServices';

function AttendanceReportClassWise() {
    /* Start Masters Setting  */
    const [masters,setMasters] = useState(null);
    const getMasterList = () => {
        systemMasterServices.get([
            "branch","class","section"
        ]).then(res=>{
            setMasters(res);
        })
    }
    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }
    useEffect(()=>{
        getMasterList();
    },[])
    /* End Masters Setting  */

    const [attendanceBatchList,setAttendanceBatchList] = useState(null);
    const [selectedDate,setSelectedDate] = useState(moment().format("YYYY-MM-DD"));

    const getAttendanceBatchList = () =>{
        attendanceServices.getAttendanceBatchList(selectedDate).then(res=>{
            console.log("res",res.data);
            setAttendanceBatchList(res.data);
        })
    }

    useEffect(()=>{
        getAttendanceBatchList();
    },[selectedDate])



 

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-4">
                    <div className="col-md-12">
                        <TextField label="Select Date"  type="date" defaultValue={selectedDate} value={selectedDate} onChange={e=> setSelectedDate(e.target.value)} />
                    </div>
                </div>
                {attendanceBatchList && masters && 
                    <table className="table">
                    <thead>
                        <tr>
                            <td>Branch</td>
                            <td>Class</td>
                            <td>Section</td>
                            <td>Marked By</td>
                            <td>Date Time</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                    {attendanceBatchList.map(batch=>
                        <tr key={batch.id}>
                            <td>{getMasterLabel("branch",batch.branch)}</td>
                            <td>{getMasterLabel("class",batch.class)}</td>
                            <td>{getMasterLabel("section",batch.section)}</td>
                            <td>{batch.techerFirstName} {batch.techerLastName}</td>
                            <td>{moment(batch.attendanceDate).format("LLLL")}</td>
                            <td><Link to={"/attendance/batch/"+batch.id} >View</Link></td>
                        </tr>
                    )}
                    </tbody>
                </table>
                }
            </div>
        </AppLayout>
    )
}
export default AttendanceReportClassWise