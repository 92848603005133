import api from "../../api/api";


const getList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/exam/report-card-exam-list").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getByExamReportId = (examReportId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/exam/report-card-exam-list/"+examReportId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/exam/report-card-exam-list",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const examReportCardExamListServices = {
    getList,
    getByExamReportId,
    save,
}
export default examReportCardExamListServices;