import React from 'react';
import moment from 'moment';


function ReceiptDetailsComponent( {receiptDetails,feesDetails,studentDetails,schoolDetails,getMasterLabel,doTotal,title}) {



    return (
                        <div className="col-md-12" style={{fontSize:10}} >
                            <div className="row">
                                <div className="col-12">
                                    
                                    <div className="d-flex justify-content-between">
                                        <h6><img alt={schoolDetails.title} src={schoolDetails.logo} style={{maxHeight:40}} />  <span className="fw-bold">{schoolDetails.title}</span></h6>
                                        <div className="text-center">
                                            <span style={{fontSize:16}} className="fw-bold">Fees Receipt</span><br/>
                                            <span style={{fontSize:12}}>Session : {schoolDetails.session}</span>
                                        </div>
                                        <div className="text-end">
                                            <span style={{fontSize:12}}>({title})</span><br/>
                                            <span style={{fontSize:12, fontWeight:"bold"}}>{getMasterLabel("payment-type",receiptDetails.paymentType)}</span>
                                            {receiptDetails.transectionNo !=="" && receiptDetails.transectionNo !== null && 
                                                <span style={{fontSize:12}}> ({receiptDetails.transectionNo})</span> 
                                            }
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="row">
                                
                                <div className="col-12">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className="fw-bold">Receipt # : </span>{receiptDetails.id}
                                        </div>
                                        <div>
                                            <span className="fw-bold">Receipt Date # : </span>{ moment(receiptDetails.paymentDate).format("DD-MMM-YYYY")}
                                        </div>
                                    </div>
                                
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className="fw-bold">Student Name  : </span>{studentDetails.firstName} {studentDetails.lastName} 
                                        </div>

                                        <div>
                                            <span className="fw-bold">Admission # : </span>{studentDetails.admissionNo} / { moment(studentDetails.admissionYear).format("YY")}
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className="fw-bold">Father Name : </span>{studentDetails.fatherName} 
                                        </div>
                                        <div>
                                            <span className="fw-bold ms-2">Class : </span>{getMasterLabel("class",studentDetails.class)} / {getMasterLabel("section",studentDetails.section)}
                                        </div>
                                    </div>


                                </div>


                            </div>
                            

                            
                            <div className="row">
                                <div className="col-12 ">
                                    <table className="table table-sm table table-bordered border-dark">
                                        <thead>
                                            <tr>
                                                <th>Sl No</th>
                                                <th>Perticulars</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {feesDetails.map((fees,i)=>
                                                <tr key={fees.id}>
                                                    <td>{i+1}</td>
                                                    <td>{getMasterLabel('fees-head',fees.feesHead)} 
                                                        {   
                                                            <span className='fw-bold'>
                                                                <span> {getMasterLabel('month',fees.paymentMonth)} </span>
                                                                {fees.feesTerm === 3 && <span> to  {getMasterLabel('month',fees.paymentMonth+2)} </span> }
                                                            </span>
                                                        }
                                                    </td>
                                                    <td>{fees.feesAmount}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="2">Total</td>
                                                <td>{doTotal()}</td>
                                            </tr>

                                            <tr>
                                                <td colSpan="2">Late Fees</td>
                                                <td>{receiptDetails.lateFees}</td>
                                            </tr>

                                            <tr>
                                                <td colSpan="2">Concession</td>
                                                <td>{receiptDetails.concession}</td>
                                            </tr>
                                            
                                            <tr>
                                                <td colSpan="2">Previous Dues</td>
                                                <td>{receiptDetails.previousDues}</td>
                                            </tr>

                                            
                                            <tr>
                                                <td colSpan="2">Payable Amount</td>
                                                <td>{receiptDetails.payableAmount}</td>
                                            </tr>
                                            <tr>
                                                <th colSpan="2">Paid Amount</th>
                                                <th>{receiptDetails.paidAmount}</th>
                                            </tr>

                                            <tr>
                                                <td colSpan="2">Dues</td>
                                                <td>{receiptDetails.dues}</td>
                                            </tr>

                                            <tr>
                                                <td colSpan="2">Received By  <strong>{receiptDetails.firstName} {receiptDetails.lastName}</strong></td>
                                                <td>
                                                    <div className="mt-5 me-2 float-end">
                                                        Signature
                                                    </div>
                                                    
                                                </td>
                                            </tr>

                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
    )
}

export default ReceiptDetailsComponent