import React, { useEffect, useState } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import systemMasterServices from '../../../master/systemMasterServices';


import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';
import teacherServices from '../teacherServices';
import { LinearProgress } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router';
import useMasterList from '../../../hooks/useMasterList';

function TeacherFormPage({AppAlert}) {

    const navigate = useNavigate()

    
    const [masters,getMasterLabel] = useMasterList(["branch", "city","category","job-contract-type", "area", "gender","department","designation","salutation","marital-status","teacher-group","subject", "state","blood-group","religion","caste","nationality","qualification","relation-type"])

    
    //  Join Form
    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {

        category:"",
        jobContractType:"",
        pan:"",
        bankAccountNo:"",
        bankName:"",
        bankIFSC:"",
        bankBranch:"",

        teacherUID:"",
        contactPerson:"",
        contactRelation:"",
        contactPhone:"",

    

        branch:"",
        doj:moment().format("YYYY-MM-DD"),
        salutation:"",
        gender:"",
        uid:"",
        firstName:"",
        lastName:"",
        photo:"",
        department:"",
        designation:"",
        maritalStatus:"",
        dob:"2000-01-01",
        addressLine1:"",
        addressLine2:"",
        city:"",
        state:"",
        pincode:"",
        bloodGroup:"",
        religion:"",
        caste:"",
        nationality:"",
        qualification:"",
        teacherGroup:"",
        primarySubject1:"",
        primarySubject2:"",
        primarySubject3:"",


        phone:"",
        email:"",
        secondaryPhone:"",
        secondaryEmail:"",
        

        
    };

    const validationRule = Yup.object({
        branch:Yup.string().required("Required"),
        salutation:Yup.string().required("Required"),
        doj:Yup.string().required("Required"),
        gender:Yup.string().required("Required"),
        //uid:Yup.string().required("Required"),
        firstName:Yup.string().required("Required"),
        //lastName:Yup.string().required("Required"),
        //photo:Yup.string().required("Required"),
        department:Yup.string().required("Required"),
        designation:Yup.string().required("Required"),
        maritalStatus:Yup.string().required("Required"),
        dob:Yup.string().required("Required"),
        addressLine1:Yup.string().required("Required"),
        city:Yup.string().required("Required"),
        state:Yup.string().required("Required"),
        pincode:Yup.string().required("Required"),
        bloodGroup:Yup.string().required("Required"),
        religion:Yup.string().required("Required"),
        caste:Yup.string().required("Required"),
        nationality:Yup.string().required("Required"),
        qualification:Yup.string().required("Required"),
        teacherGroup:Yup.string().required("Required"),
        primarySubject1:Yup.string().required("Required"),
        primarySubject2:Yup.string().required("Required"),
        primarySubject3:Yup.string().required("Required"),

        phone:Yup.string().required("Required"),
        //email:Yup.string().required("Required"),
        //secondaryPhone:Yup.string().required("Required"),
        //secondaryEmail:Yup.string().required("Required"),

        teacherUID:Yup.string().required("Required"),
        //contactPerson:Yup.string().required("Required"),
        //contactRelation:Yup.string().required("Required"),
        //contactPhone:Yup.string().required("Required"),
        
    });

    const addNew = ()=>{
        setFormValues(initialValues);
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        teacherServices.save(values).then(res =>{
            AppAlert("Teacher Joining Done");
            setTimeout(()=>{
                navigate("/teacher/details/"+res.id);
            },1000)
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    return (
        <AppLayout>
            <div className="card m-2">
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            console.log(formik);
                            return (
                                <Form>
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-9"><h4> New Teacher Form </h4> </div>
                                            <div className="col-md-3">
                                                {!savingInProgress ? <button className="btn btn-success float-end" type="submit">Save</button> : <LinearProgress />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                {//JSON.stringify(formik.values)
                                                }
                                                </div>
                                                <div className="row">


                                                    

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Branch" name="branch"  
                                                                options={masters['branch']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Teacher Id" name="teacherUID" 
                                                        />
                                                    </div>

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Salutation" name="salutation"  
                                                                options={masters['salutation']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Gender" name="gender"  
                                                                options={masters['gender']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="date" 
                                                            label="Date of Joiing" name="doj" 
                                                        />
                                                    </div>


                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="UID" name="uid" 
                                                        />
                                                    </div>

                                                    <h6>Teacher Details</h6>

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="First Name" name="firstName" 
                                                        />
                                                    </div>

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Last Name" name="lastName" 
                                                        />
                                                    </div>

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Marital Status" name="maritalStatus"  
                                                                options={masters['marital-status']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="date" 
                                                            label="Date of Birth" name="dob" 
                                                        />
                                                    </div>

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Department" name="department"  
                                                                options={masters['department']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Designation" name="designation"  
                                                                options={masters['designation']} multiple={false} 
                                                            />
                                                        </div>
                                                    }


                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Category" name="category"  
                                                                options={masters['category']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Contract Type" name="jobContractType"  
                                                                options={masters['job-contract-type']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <h6>Personal Details</h6>

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Blood Group" name="bloodGroup"  
                                                                options={masters['blood-group']} multiple={false} 
                                                            />
                                                        </div>
                                                    }
                                                    
                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Religion" name="religion"  
                                                                options={masters['religion']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Caste" name="caste"  
                                                                options={masters['caste']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Nationality" name="nationality"  
                                                                options={masters['nationality']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    
                                                    <h6>Teachers Skill</h6>
                                                    
                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Qualification" name="qualification"  
                                                                options={masters['qualification']} multiple={false} 
                                                            />
                                                        </div>
                                                    }
                                                    
                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Teacher Group" name="teacherGroup"  
                                                                options={masters['teacher-group']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Primary Subject 1" name="primarySubject1"  
                                                                options={masters['subject']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Primary Subject 2" name="primarySubject2"  
                                                                options={masters['subject']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-3 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Primary Subject 3" name="primarySubject3"  
                                                                options={masters['subject']} multiple={false} 
                                                            />
                                                        </div>
                                                    }
                                                    
                                                    
                                                    <h6>Bank Details</h6>
                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="PAN" name="pan" 
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Bank Account No" name="bankAccountNo" 
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Bank Name" name="bankName" 
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Bank IFSC" name="bankIFSC" 
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Bank Branch" name="bankBranch" 
                                                        />
                                                    </div>
                                                    
                                                    <h6>Contact Details</h6>
                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Phone" name="phone" 
                                                        />
                                                    </div>

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Email" name="email" 
                                                        />
                                                    </div>

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Secondary Phone" name="secondaryPhone" 
                                                        />
                                                    </div>

                                                    <div className="col-md-3 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Secondary Email" name="secondaryEmail" 
                                                        />
                                                    </div>

                                                    <h6>Father / Spouse </h6>
                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Contact Person" name="contactPerson" 
                                                        />
                                                    </div>
                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="Relation" name="contactRelation"  
                                                                options={masters['relation-type']} multiple={false} 
                                                            />
                                                        </div>
                                                    }
                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Contact Phone" name="contactPhone" 
                                                        />
                                                    </div>

                                                    <h6>Address Details</h6>

                                                    <div className="col-md-6 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Address Line 1" name="addressLine1" 
                                                        />
                                                    </div>

                                                    <div className="col-md-6 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Address Line 2" name="addressLine2" 
                                                        />
                                                    </div>


                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="City" name="city"  
                                                                options={masters['city']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    {masters && 
                                                        <div className="col-md-4 col-xs-12 p-2" >    
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="State" name="state"  
                                                                options={masters['state']} multiple={false} 
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-4 col-xs-12 p-2" >
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Pincode" name="pincode" 
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                        {!savingInProgress && <div className="row"><button className="btn btn-success" type="submit">Save</button></div> }
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </AppLayout>
    )
}

export default withAlert(TeacherFormPage)